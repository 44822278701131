import React, {useEffect,useState}  from "react";
import {
    // Card,
    // CardHeader,
    // CardBody,
    // CardFooter,
    // Heading,
    // Paragraph,
    Button,
    List,
  } from "grommet";
// import ReactDOM from 'react-dom'
// import { Link } from "react-router-dom";
// import Rencontre from "../rencontre";
// import shuffle from '../../jsTools/toolsArray';
import Tour from "./tour";
import Container from 'react-bootstrap/Container';
import C from "../../conf/tournoi.json";


export default function Tournante({equipes, listRand, gs, ts}){
    const [rencontres, setRencontres] = useState([]);
    const [tl_state, setTl] = useState([])
    const [tempNobody, setTempNobody] = useState(null)
    // const [listRand, setlistRand] = useState([])
    // const [nbTour, setNbTour] = useState(3)

    var tl = [...tl_state] //tournante listes
    const nobody = C.nobody
    var dicoPivot = {}


    const initTournante = ()=>{
      // let tl = []
      let nono = false
      console.log("INI%T")
      console.log(listRand)
      console.log(listRand.slice(0, Math.trunc(listRand.length/2)))
      tl.push(listRand.slice(0, Math.trunc(listRand.length/2)))
      tl.push(listRand.slice(Math.trunc(listRand.length/2)))
      if(tl[1].length>tl[0].length){
        tl[0].push(nobody)
        nono = true
      }
      setTl(tl)
      // console.log(tl_state)
      setRencontres([])
      tournante_ajouterTour(nono)
    }
    const getTempNobody = (r1,r2) =>{
      if (r1===tempNobody)return r1
      else if (r2===tempNobody)return r2
      else return false
    }
    const getNotNobody = (r1,r2) =>{
      if (r1===nobody)return r2
      else if (r2===nobody)return r1
      else return false
    }
    const tournante_ajouterTour = (firstno=false)=>{
        console.log("#########ajout tour#########")
        //fill r avec nouvelles rencontres
        var r = []
        for(let i=0; i<tl[0].length; i++){
          r.push([tl[0][i],tl[1][i]])
        }

        //on fait tourner les vecteurs des rencontres
        let pivot = tl[0][tl[0].length-1]
        for(let i=tl[0].length-1; i>0; i--){
          tl[0][i] = tl[0][i-1]
        }
        tl[0][1] = tl[1][0]
        for(let i=0; i<tl[1].length-1;i++){
          tl[1][i] = tl[1][i+1]
        }
        tl[1][tl[1].length-1] = pivot

        //si un "nobody" on met le match de celui qui 
        //n'a pas joué au dernier tour en premier
        //et nouvelle team dans le tempnobody
        if(rencontres.length===0 && firstno){
          for(let i=0; i<r.length;i++){
            if(getNotNobody(r[i][0],r[i][1])){
              setTempNobody(getNotNobody(r[i][0],r[i][1]))
            }
          }
        }
        else if (tempNobody!==null){
          let temp = 0
          for(let i=0; i<r.length;i++){
            if(getTempNobody(r[i][0],r[i][1])){
              temp = i
            }
          }
          for(let i=0; i<r.length;i++){
            if(getNotNobody(r[i][0],r[i][1])){
              setTempNobody(getNotNobody(r[i][0],r[i][1]))
            }
          }
          //r.unshift(r.splice(temp, 1)[0])
          [r[0],r[temp]] = [r[temp],r[0]]
        }
        //ajout au state du component
        let tempR = [...rencontres]
        tempR.push(r)
        setRencontres(tempR)
        setTl(tl)
    }
    

    const tournante_ajouterEquipe = (equipe)=>{
        // let tl = [...tl_state]
        if (tl[0].findIndex(x=>x===nobody)){
            tl[0][tl[0].findIndex(x=>x==nobody)] = equipe
        }
        else if (tl[1].findIndex(x=>x===nobody)){
            tl[1][tl[1].findIndex(x=>x===nobody)] = equipe
        }
        else {
            tl[0].push(nobody)
            tl[1].push(equipe)
        }
        setTl(tl)
    }
    
    useEffect(()=>{
      console.log("rencontres")
      console.log(rencontres)
    },[rencontres])

    return (
        <div>
        <p>Tournante</p>
        {/* <i>{JSON.stringify(equipes)}</i> */}
        <Container fluid>
          {rencontres.map((item, index) => (
            <Tour key={"Tour"+index} equipes={equipes} rencontres={item} numeroTour={index+1} gs={gs} ts={ts} />
          ))}
        </Container>
        
        { (rencontres.length===0?
        <Button label="Générer les matchs du 1er tour" onClick={()=>initTournante()} />:
        <Button label="Ajouter un tour" onClick={()=>tournante_ajouterTour()} />)}
        {/* <small>{JSON.stringify(rencontres)}</small> */}
        </div>
    );
  };
