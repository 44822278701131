import React, {useState}  from "react";
// import {
//     // Card,
//     // CardHeader,
//     // CardBody,
//     // CardFooter,
//     // Heading,
//     // Paragraph,
//     Button,
//   } from "grommet";
// // import ReactDOM from 'react-dom'
// import { Link } from "react-router-dom";
// import Rencontre from "../rencontre";
// import shuffle from '../../jsTools/toolsArray';



export default function ElimDirecte({equipes, typeTournoi,}){
    const [rencontres, setRencontres] = useState([]);
    const [listRand, setlistRand] = useState([])
    // const [nbTour, setNbTour] = useState(3)

    var tl = [[],[]] //tournante listes
    const nobody = "Nobody"
    var dicoPivot = {}

    const initTournante = ()=>{
      tl.push(listRand.slice(0, Math.trunc(listRand.length/2)))
      tl.push(listRand.slice(Math.trunc(listRand.length/2)))
      if(tl[1].length>tl[0].length)tl[0].push(nobody)
      setRencontres([])
      tournante_ajouterTour()
    }
    const tournante_ajouterTour = ()=>{
        var r = []
        for(let i=0; i<tl[0].length; i++){
          r.push([tl[0][i],tl[0][i]])
        }
        let pivot = tl[0][tl[0].length-1]
        for(let i=tl[0].length-1; i>0; i--){
          tl[0][i] = tl[0][i-1]
        }
        tl[0][1] = tl[1][0]
        for(let i=0; i<tl[1].length-1;i++){
          tl[1][i] = tl[1][i+1]
        }
        tl[1][tl[1].length-1] = pivot

        let tempR = rencontres
        tempR.push(r)
        setRencontres(tempR)
    }

    const tournante_ajouterEquipe = (equipe)=>{
        if (tl[0].findIndex(x=>x==nobody)){
            tl[0][tl[0].findIndex(x=>x==nobody)] = equipe
        }
        else if (tl[1].findIndex(x=>x==nobody)){
            tl[1][tl[1].findIndex(x=>x==nobody)] = equipe
        }
        else {
            tl[0].push(nobody)
            tl[1].push(equipe)
        }
    }
    
    
    // const size = useContext(ResponsiveContext);
    return (
      <p>Elim directe</p>
      
    );
  };
