import React, { useState, useEffect } from 'react';
import {
    // Card,
    // CardHeader,
    // CardBody,
    // CardFooter,
    // Heading,
    // Paragraph,
    Button,Box, Text, DropButton, TextInput, Grid
  } from "grommet";
// import ReactDOM from 'react-dom'
import { Edit,SubtractCircle } from 'grommet-icons';


export default function Equipe({nom, couleur, score=0}){
    const [points, setPoints] = useState(Number(score));
    const [label, setLabel] = useState(nom);
    const [fond, setFond] = useState(couleur)

    const incremente = () => {
      setPoints(points+1)
    };
    const decremente = () => {
      setPoints(points-1)
    };
    // const changeCouleur = (c) => {
    //   setFond(c)
    // }
    useEffect(() => {
      console.log("envoi des données")
    }, [points]);

    const couleurslabel = [["status-critical","Rouge1"],["#B30000","Rouge2"],["status-warning","OrangeJaune"],["status-ok","Vert1"],["status-disabled","Gris1"],["graph-0","VertFlashy"],["graph-1","VertFoncé"],["graph-2","Violet1"],["graph-3","Bleu1"],["graph-4","Rouge3"],["accent-2","Rose1"],["accent-3","Bleu2"],["accent-4","Jaune2"],["dark-4","Gris2"]]
    var couleurs = couleurslabel.map((c,i)=><Box background={c[0]} className="pastilleCouleur" margin="" pad="small" key={i} eventKey={i} onClick={()=>{setFond(c[0])}}><span >{c[1]}</span></Box>)
    // const size = useContext(ResponsiveContext);
    return (
      
      //
      <Box background={fond} className="pastilleEquipe" direction="row" onClick={()=>{incremente()}}
      // border={{ color: 'brand', size: 'large' }}
      pad="medium" margin="medium">
        {/* <Tag name={nom} value={score}>s</Tag>
        <Tag name={score}></Tag> */}
        <Grid
          rows={["xsmall"]}
          columns={["60%","30%","auto"]}
          gap="1%"
          fill="horizontal"
          areas={[
            { name: 'nom', start: [0, 0], end: [1, 0] },
            { name: 'score', start: [1, 0], end: [2, 0] },
            { name: 'outils', start: [2, 0], end: [3, 0] },
          ]}
        >
          <Text gridarea="nom" wordBreak='break-word' weight="bold" size="xlarge">{label}</Text>
          <Text gridarea="score" size="4xl" weight="bold">{points}</Text>
          <Box gridarea="outils" onClick={(event)=>{event.stopPropagation()}}>
            <DropButton
              dropContent={
                <Box pad="large" background="light-2" onClick={(event)=>{event.stopPropagation();}}>
                  <TextInput placeholder="Nom équipe" value={label} onChange={event => setLabel(event.target.value)}/>
                  <TextInput placeholder="Modification score" value={points} number onChange={event => setPoints(Number(event.target.value))}/>
                  <DropButton
                    label="Modifier couleur"
                    margin={{"vertical":"small"}}
                    color={fond}
                    dropContent={
                      <Box pad="large" background="light-3" >
                        {couleurs}
                      </Box>
                    }
                  />
                </Box>
              }
            >
              <Edit />
            </DropButton>
            <Button secondary size="small"  jusitfy="center" onClick={()=>{decremente()}}><SubtractCircle/></Button>
          </Box>
        </Grid>
      </Box> 
    );
  };

// const CardTemplate = ({ title }) => {
//     const size = useContext(ResponsiveContext);
//     return (
//       <Card>
//         <CardHeader pad="medium">
//           <Heading level={2} margin="none">
//             {title}
//           </Heading>
//         </CardHeader>
//         <CardBody pad="medium">
//           <Paragraph maxLines={size === "small" ? 3 : undefined}>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
//             porttitor non nulla ac vehicula. Aliquam erat volutpat. Mauris auctor
//             faucibus est at mattis. Aliquam a enim ac nisi aliquam consectetur et
//             ac velit. Mauris ut imperdiet libero.
//           </Paragraph>
//         </CardBody>
//         <CardFooter pad="medium" background="background-contrast">
//           Footer
//         </CardFooter>
//       </Card>
//     );
//   };