import React, {useEffect,useState}  from "react";
import { useSelector } from 'react-redux';


import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter,faHandDots,faDoorOpen,faStethoscope,faCamera, faListNumeric } from '@fortawesome/free-solid-svg-icons'

// import { register } from 'swiper/element/bundle';

import { Vortex } from  'react-loader-spinner'

// import ReactDOM from 'react-dom'
// import CarteProTable from "./utilisateur/carteProTable";
// import CarteProTableResp from "./utilisateur/carteProTableResp";
// import FormAnims from "./formulaires/formAnims";
// import FormResp from "./formulaires/formResp";
// import { Button } from "grommet";
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Stack from 'react-bootstrap/Stack';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListGroup from 'react-bootstrap/ListGroup';
// import Card from 'react-bootstrap/Card';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';

import recuperer from "../../jsTools/toolsFetch";
// import ListeElements from "../listeElements";
import ListeEnfantAppel from "./listeEnfantsAppel";

// register();

export default function LAppel({donneesClub, uuidCamping, id_camping, id_presence=""}){
    const [booModal, setBooModal] = useState(false);
    const [showEcran,setShowEcran] = useState(false)
    // const [activeTab, setActiveTab] = useState(0);
    const [alreadyLoad, setAlreadyLoad] = useState(false);
    const [donneesEnfants, setDonneesEnfants] =useState([])
    const [donneesEnfantsP, setDonneesEnfantsP] =useState([])
    // const [enfantsAbsents, setEnfantsAbsents] =useState([])
    const [enfantsPresents, setEnfantsPresents] =useState([])
    const [enfantsPartis, setEnfantsPartis] =useState([])

    // const [enfantsPresents, setEnfantsPresents] = useState([])
    const [etatBouton, setEtatBouton] = useState("")
    // const [recEnfant, setRecEnfant] =useState("")
    const [messageLoad, setMessageLoad] = useState("Chargement des données")
    const [datejour, setDateJour] = useState("")
    const [journeeP, setJourneeP] = useState([{}])
    const [creneau, setCreneau] = useState(0)
    const [upData, setUpData] = useState({
      "addPresence":[],
      "popPresence":[],
      "addSortie":[],
      "popSortie":[],
    })
    const [horsligne, setHorsLigne] = useState(false)
    const [tosynch, setToSynch] = useState(false);

    // const [upDataStock, setUpDataStock] = useState({
    //   "addPresence":[],
    //   "popPresence":[],
    //   "addSortie":[],
    //   "popSortie":[],
    // })
    const [upReady, setUpReady] = useState(true);
    // const [upChangement, setUpChangement] = useState(true);

    const [synchMod, setSynchMod] = useState(false)
    // const [rencontres, setRencontres] = useState([]);
    
    const JETON = useSelector((state) => state.jeton.access)

    useEffect(()=>{
      setDonneesEnfantsP([...donneesEnfants].sort((a,b)=>a.nom.localeCompare(b.nom)))
    },[donneesEnfants])

    useEffect(()=>{
      if(journeeP.length==1){
        console.log("if creneau,")
        console.log(journeeP[creneau]["enfants"])
        // setEnfantsPresents(journeeP[creneau]["enfants"])
        // setEnfantsPartis(journeeP[creneau]["sortie"])
      }
    },[creneau])

    useEffect(()=>{
      console.log(donneesClub)
      
      console.log(uuidCamping)
      let currentDate = new Date()
      let sdate = currentDate.toISOString().split('T')[0];
      setDateJour(sdate)
      // const currentHour = currentDate.getHours();
      // let cv =(currentHour < 13?0:(currentHour < 19?1:2));
      // setCreneau(cv)
      initAppel(sdate)
      //fix du bug swipe sorties
      // visu sorties, puis visu entrees
      // setActiveTab(1);
      // setActiveTab(0)
      // try {
      //   if (localStorage.getItem('horsLigne').length!=0) setHorsLigne(true)
      // } catch (error) {
      //   setHorsLigne(false)
      // }
      
    },[])


    const initAppel = (sdate)=>{
      if(!alreadyLoad){
        console.log("marcopoloeirf")
          console.log(`api/peluche/${uuidCamping}/${donneesClub.uuid}/enfants/?deb=${sdate}&fin=${sdate}`)
        // console.log("load")
        recuperer(
          `api/peluche/${uuidCamping}/${donneesClub.uuid}/enfants/?deb=${sdate}&fin=${sdate}`,
          JETON,
          (rep)=>{
            console.log('first success')
            // console.log("poulette")
            // console.log(rep);
            if(rep.length==0){
              setMessageLoad(`Pas de données pour ce club (${donneesClub.nom})`)
              notif_gen(`Pas  de données pour ce club (${donneesClub.nom})`)
            }
            else{
              setMessageLoad("")
            }
            setDonneesEnfants(rep)
            // setEnfantsAbsents(rep.map(x=>x.uuid))
            console.log("load 2")
            recuperer(
              `api/peluche/${uuidCamping}/${donneesClub.uuid}/presences_m/${id_presence}`,
              JETON,
              (rep)=>{
                console.log("second success")
                if(rep.erreur){
                  console.error(rep)
                  notif_gen("Erreur dans la création de la fiche de présence")
                  notif_gen(rep.erreur)
                }
                else{
                  console.log("rep")
                  console.log(rep)
                  setJourneeP([rep])
                  console.log("suc rep 2,")
                  console.log(rep.enfants)
                  setEnfantsPresents(rep.enfants)
                  setEnfantsPartis(rep.sortie)
                }           
              },
              (err)=>{
                notif_gen(err.message)
                setMessageLoad("Erreur, veuillez réessayer")
                console.error(err)
              }
            )
            // setDonneesEnfantsS(JSON.stringify(rep))
            // notif_norm("")  
          },
          (err)=>{
            notif_gen(err.message)
            setMessageLoad("Erreur, veuillez réessayer")
            console.log(err)

          }
        )
        
        setAlreadyLoad(false)
      }
    }
    // useEffect(()=>{
    //   localStorage.setItem('donneeshorsligne', JSON.stringify([enfantsPresents, enfantsPartis]));
    //   // let currentDate = new Date()
    //   // localStorage.setItem('datedonneeshorsligne',currentDate.toISOString() );
    //   setHorsLigne(true)
    // },[enfantsPresents,enfantsPartis])

    // const recupHorsLigne = ()=>{
    //   let temparr = JSON.parse(localStorage.getItem("donneeshorsligne"))
    //   setEnfantsPresents(temparr[0])
    //   setEnfantsPartis(temparr[1])
    // }
    // useEffect(()=>console.log(booModal),[booModal])
    // useEffect(
    //   ()=>{
    //     console.log("useeffectjourneespcreneau")
    //     console.log(enfantsPresents)
    //     console.log(enfantsSortis)
    //     if(journeeP.length>1){
    //       setEnfantsPresents(journeeP[creneau]["enfants"])//.map(x=>x.uuid))
    //       setEnfantsSortis(journeeP[creneau]["sortie"])//.map(x=>x.uuid))
    //     }
    //   },[journeeP,creneau])
    useEffect(()=>{
      if(enfantsPresents==undefined)console.trace()
      // if(enfantsPresents==undefined)console.trace()
    },[enfantsPresents,enfantsPartis])
    const vd = (clef, valeur)=>{
      setUpData((prevValues) => ({
              ...prevValues,
              [clef]: valeur,
            }));
      }
      // const setStateButton = (clef, valeur)=>{
      //   setEtatBouton((prevValues) => ({
      //           ...prevValues,
      //           [clef]: valeur,
      //         }));
      //   }
    // const equilibrer = ()=>{
    //   console.log("equilibrer")
    //   console.log(enfantsPresents)
    //   console.log(enfantsSortis)
    //   for(let e of enfantsPresents){
    //     swipe_slide("droite",e,"entrees")
    //     swipe_slide("gauche",e,"sorties")
    //   }
    //   for(let e of enfantsSortis){
    //     swipe_slide("droite",e,"entrees")
    //   }
    // }
    const synchronisation = ()=>{
      //si rien, non
      
        // console.log("synchro")
        // console.log(upData.addPresence.length+upData.addSortie.length+upData.popPresence.length+upData.popSortie.length)
        // if(upData.addPresence.length+upData.addSortie.length+upData.popPresence.length+upData.popSortie.length !== 0){
          // console.log("dedans")
          //si pas milieu d'un changmeent, oui
          if(upReady){
            // console.log("in")
            setSynchMod(true)
            let corps = upData
            // vider_stocker()
            // console.log("send sync")
            recuperer(
              `api/poche/${donneesClub.uuid}/${journeeP[creneau].uuid}/emarge/`,
              // `api/poche//emarge/`,
              JETON,
              (rep)=>{
                // console.log(rep);

                console.log("synch,")
                console.log(rep.enfants)
                setEnfantsPresents(rep.enfants)
                setEnfantsPartis(rep.sortie)
                
                // setEnfantsAbsents(donneesEnfants.map(x=>x.uuid).filter(y=>!(rep.sortie.includes(y) || rep.enfants.includes(y))))
                let tempobj = [...journeeP]
                tempobj[creneau] = rep
                setJourneeP(tempobj)
                // epuisement_stock()
                // equilibrer()
                // setHorsLigne(false)
                // localStorage.setItem('donneeshorsligne',"");


                setSynchMod(false)
          
              },
              (err)=>{
                setSynchMod(false)
                notif_gen(err)
                //setMessageLoad("Erreur, veuillez réessayer")
                console.error(err)
                // re_stocker()
              },
              "POST",
              corps
            )
          }
        
      
    }
    
    const trait_arr = (clef, valeur)=>{
      console.log('TRAITARR')
      setUpReady(false)
      
      if(upData[clef].includes(valeur)){ //si deja, rien
        setUpReady(true)
      }
      else{
        //enlever de l'inverse si present
        let clefinv = clef.substring(0,3)=="pop"?"add"+clef.substring(3):"pop"+clef.substring(3)
        let invarr = [...upData[clefinv]]
        
        if(invarr.includes(valeur)){
          invarr.splice(invarr.indexOf(valeur),1)
          vd(clefinv,invarr)
        }
        //ajouter à la clef
        
        vd(clef,[...upData[clef],valeur])
        setUpReady(true)

        //en local
      //   "addPresence":[],
      // "popPresence":[],
      // "addSortie":[],
      // "popSortie":[],
        
      }
      if(clef=="addPresence"){
        if(!enfantsPresents.includes(valeur))setEnfantsPresents([...enfantsPresents,valeur])
      }
      else if(clef=="addSortie"){
        if(!enfantsPartis.includes(valeur))setEnfantsPartis([...enfantsPartis,valeur])
        // if(enfantsPresents.includes(valeur)){
        //   let temparr = [...enfantsPresents]
        //   temparr.splice(temparr.indexOf(valeur),1)
        //   setEnfantsPresents(temparr)
        // }
      }
      else if(clef=="popSortie"){
        if(enfantsPartis.includes(valeur)){
          let temparr = [...enfantsPartis]
          temparr.splice(temparr.indexOf(valeur),1)
          setEnfantsPartis(temparr)
        }
        if(!enfantsPresents.includes(valeur))setEnfantsPresents([...enfantsPresents,valeur])
      }
      else if(clef=="popPresence"){
        if(enfantsPresents.includes(valeur)){
          let temparr = [...enfantsPresents]
          temparr.splice(temparr.indexOf(valeur),1)
          setEnfantsPresents(temparr)
        }
      }
      return true
    }



    const notif_gen = (message)=>toast.error(message,
      {position: "top-right",autoClose: 5000,hideProgressBar: false,
      closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
     })
    const notif_norm = (message)=>{
        toast(message,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
      })}
    
    const estDansString = (s, srec)=>{
      let searchWords = srec.split(/\s+/); // Split search string into individual words
      let regex = new RegExp(`^.*${searchWords.map(word => `(?=.*${word})`).join('')}.*`, 'i');
      return regex.test(s); 
    }
    
    const filtrerString = (s)=>{
      let temp = donneesEnfants.filter(x=>estDansString(JSON.stringify(x),s))
      temp.sort((a,b)=>{
        if(s.toLowerCase().includes(a.prenom.toLowerCase()))return -1
        else if(s.toLowerCase().includes(b.prenom.toLowerCase()))return 1
        if(s.toLowerCase().includes(a.nom.toLowerCase()))return -1
        else if(s.toLowerCase().includes(b.nom.toLowerCase()))return 1
        else return -1
      })
      setDonneesEnfantsP(temp)
    }
    
    const filtrePresents = ()=>{
      return donneesEnfantsP.filter(x=>enfantsPresents.includes(x.uuid))
    }
    const filtreSeul = ()=>{
        setDonneesEnfantsP(donneesEnfants.filter(x=>x.sortie=="S"))
    }
    const filtreAccomp = ()=>{
      setDonneesEnfantsP(donneesEnfants.filter(x=>x.sortie=="A"))
    }
    const filtreAllergie = ()=>{
      setDonneesEnfantsP(donneesEnfants.filter(x=>x.allergie))
    }
    const filtrePhoto = (oui=true)=>{
      if(oui)setDonneesEnfantsP(donneesEnfants.filter(x=>x.acceptePhoto))
      else setDonneesEnfantsP(donneesEnfants.filter(x=>!x.acceptePhoto))
    }
    const filtreTraitement = ()=>{
      setDonneesEnfantsP(donneesEnfants.filter(x=>x.traitementMedical))
    }
    // const enfantpresentsseul = ()=>{
    //   return donneesEnfants.filter(x=>x.sortie=="S" && enfantsPresents.includes(x.uuid) && enfantsPartis.includes(x.uuid)).length 
    //   // enfantsPresents.filter(x=>donneesEnfants.filter(y=>y.uuid))
    // }
    const enfantpresentsseul = ()=>{
      return enfantsPresents.filter(x=>donneesEnfants.filter(y=>y.sortie=="S").map(y=>y.uuid).includes(x) && !enfantsPartis.includes(x)).length
      // return donneesEnfants.filter(x=>x.sortie=="S" && enfantsPresents.includes(x.uuid) && enfantsPartis.includes(x.uuid)).length 
      // enfantsPresents.filter(x=>donneesEnfants.filter(y=>y.uuid))
    }
    const enfantpresentPasAllergique = ()=>{
      return enfantsPresents.filter(
        x=>donneesEnfants.filter(y=>!y.allergie).map(y=>y.uuid).includes(x) &&!enfantsPartis.includes(x)
      ).length
    }
    const enfantpresentPasMed = ()=>{
      return enfantsPresents.filter(
        x=>donneesEnfants.filter(y=>!y.traitementMedical).map(y=>y.uuid).includes(x) &&!enfantsPartis.includes(x)
      ).length
    }
    const enfantpresentPasPhoto = ()=>{
      return enfantsPresents.filter(
        x=>donneesEnfants.filter(y=>!y.acceptePhoto).map(y=>y.uuid).includes(x) &&!enfantsPartis.includes(x)
      ).length
    }
    const enfantpresentPhoto = ()=>{
      return enfantsPresents.filter(
        x=>donneesEnfants.filter(y=>y.acceptePhoto).map(y=>y.uuid).includes(x) &&!enfantsPartis.includes(x)
      ).length
    }

    

    return (
      // <p>{typeElement}
      // </p>
      <Card className="col-12 p-1 bg-white fulllargeur" style={{ maxHeight: "90vh", minHeight:"90vh"}}>
          <Button onClick={()=>{console.log("cliquecanva");setShowEcran(!showEcran)}} variant={etatBouton==""?"ora text-white m-2":"ble text-white m-2"} size="lg" className="boutonfiltreappel">
            <FontAwesomeIcon icon={faFilter} />
          </Button>
          <Offcanvas show={showEcran} onHide={()=>setShowEcran(false)} className="bg-ble text-white">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>{donneesClub.nom}</Offcanvas.Title>
              
              
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ListGroup variant="flush" className="text-start">
                  <ListGroup.Item className="bg-ble text-white">Nb inscrits : <strong>{donneesEnfants.length}</strong></ListGroup.Item>
                  <ListGroup.Item className="bg-ble text-white">Nb présents sur la session : <strong>{enfantsPresents.length}</strong></ListGroup.Item>
                  <ListGroup.Item className="bg-ble text-white">Nb enfants actuellement présent : <strong>{enfantsPresents.length-enfantsPartis.length}</strong></ListGroup.Item>
                  <ListGroup.Item className="bg-ble text-white">Nb enfants partis : <strong>{enfantsPartis.length}</strong></ListGroup.Item>
              </ListGroup>
              <div className="mt-3 row border-top pt-3">
                <h2 className="col-12">Filtres</h2>
                <div>
                  Des boutons vous présentes le nombre d'enfants présents suivant des catégories. Appuyez sur les boutons pour ne voir que les enfants présents correspondants à la catégorie choisie.
                </div>
                <div className="row col-12 mt-3">
                  <div className="col-4 my-auto">
                    <FontAwesomeIcon icon={faDoorOpen} />
                     <span className="ms-3">Sorties</span>
                  </div>
                  <Button 
                      variant={etatBouton=="seul"?"light text-ble":"outline-light"} 
                      onClick={()=>{
                        if(etatBouton=="seul"){setDonneesEnfantsP(donneesEnfants);setEtatBouton("")}
                        else {filtreSeul();setEtatBouton("seul")}
                        setShowEcran(false)
                      }} 
                      className="p-1 text-nn col-3 me-2" >
                        {enfantpresentsseul()} seuls
                  </Button>
                    <Button variant={etatBouton=="accompagne"?"light text-ble":"outline-light"} onClick={()=>{
                      if(etatBouton=="accompagne"){setDonneesEnfantsP(donneesEnfants);setEtatBouton("")}
                      else {filtreAccomp();setEtatBouton("accompagne")}
                      setShowEcran(false)
                    }} className="p-1  col-4" >{enfantsPresents.length - enfantsPartis.length - enfantpresentsseul()}
                      <span> accompagnés</span>
                      </Button>
                  
                </div>
                <div className="row col-12 mt-3">
                  <div className="col-4 my-auto">
                    <FontAwesomeIcon icon={faHandDots} />
                     <span className="ms-3">Allergies</span>
                  </div>
                    <Button variant={etatBouton=="allergie"?"light text-ble":"outline-light"} onClick={()=>{
                      if(etatBouton=="allergie"){setDonneesEnfantsP(donneesEnfants);setEtatBouton("")}
                      else {filtreAllergie();setEtatBouton("allergie")}
                      setShowEcran(false)
                    }} className="p-1 text-break col-8" >{enfantsPresents.length - enfantsPartis.length - enfantpresentPasAllergique()} <br/>
                      <span>allergiques / restriction alimentaire</span>
                      </Button>
                  
                </div>
                <div className="row col-12 mt-3">
                  <div className="col-4 my-auto">
                  <FontAwesomeIcon icon={faStethoscope} />
                     <span className="ms-3">Médical</span>
                  </div>
                    <Button variant={etatBouton=="traiteM"?"light text-ble":"outline-light"} onClick={()=>{
                      if(etatBouton=="traiteM"){setDonneesEnfantsP(donneesEnfants);setEtatBouton("")}
                      else {filtreTraitement();setEtatBouton("traiteM")}
                      setShowEcran(false)
                    }} className="p-1 text-break col-8" >{enfantsPresents.length - enfantsPartis.length - enfantpresentPasMed()} <br/>
                      <span>Traitement Médical</span>
                    </Button>
                  
                </div>
                <div className="row col-12 mt-3">
                  <div className="col-4 my-auto">
                    <FontAwesomeIcon icon={faCamera} />
                     <span className="ms-3">Photo</span>
                  </div>
                  <Button 
                      variant={etatBouton=="photo_oui"?"light text-ble":"outline-light"} 
                      onClick={()=>{
                        if(etatBouton=="photo_oui"){setDonneesEnfantsP(donneesEnfants);setEtatBouton("")}
                        else {filtreSeul();setEtatBouton("photo_oui")}
                        setShowEcran(false)
                      }} 
                      className="p-1 text-nn col-3 me-2" >
                        {enfantsPresents.length - enfantsPartis.length - enfantpresentPhoto()}
                      <span> Oui</span>
                      </Button>
                    <Button variant={etatBouton=="photo_non"?"light text-ble":"outline-light"} onClick={()=>{
                      if(etatBouton=="photo_non"){setDonneesEnfantsP(donneesEnfants);setEtatBouton("")}
                      else {filtreAccomp();setEtatBouton("photo_non")}
                      setShowEcran(false)
                    }} className="p-1  col-4" >{enfantsPresents.length - enfantsPartis.length - enfantpresentPasPhoto()}
                      <span> Non</span>
                      </Button>
                  
                </div>
              </div>
              {etatBouton!=""?<Button className="m-5" variant="ora text-white fw-bold" onClick={()=>{setEtatBouton("");setShowEcran(false)}}>
                Annuler les filtres
              </Button>:false}

            </Offcanvas.Body>
          </Offcanvas>
          <Card.Body   className="row" style={{ overflowY: "scroll"}} >
            {/* {JSON.stringify(journeeP)} */}
            {/* <aside className="col-2 text-2 nopad pe-1" >
              <Stack gap={3}> */}
                {/* <FontAwesomeIcon icon={faFilter} />
                <Button variant="outline-dark"><FontAwesomeIcon icon={faChildren} /></Button>
                <Button variant="outline-dark"><FontAwesomeIcon icon={faPersonBreastfeeding} /></Button>
                <Button variant="outline-dark"><FontAwesomeIcon icon={faSyringe} /></Button> */}
                {/* <span className="text-muted text-center mt-4">Enfants</span>
                <Button variant="success" className="p-1 text-nn" >
                  {donneesEnfants.length}<br/>
                  <span style={{fontSize:"0.8em"}}>Inscrits</span>
                </Button>
                {
                  ![undefined, "", null, "undefined"].includes(enfantsPresents)&&![undefined, "", null, "undefined"].includes(enfantsPartis)?
                  <Button variant="success" className="p-1 text-nn">
                  {enfantsPresents.length - enfantsPartis.length}<br/>
                  <span style={{fontSize:"0.8em"}}>Présents</span>
                </Button>:false
                } */}
                
                {/* <Button variant="success" className="p-1 text-nn">12 allergique</Button> */}
                {/* <span className="text-muted text-center mt-4">Sortie</span>
                {
                  ![undefined, "", null, "undefined"].includes(enfantsPresents)&&![undefined, "", null, "undefined"].includes(enfantsPartis)?
                    <Button 
                      variant={etatBouton=="seul"?"dark":"outline-dark"} 
                      onClick={()=>{
                        if(etatBouton=="seul"){setDonneesEnfantsP(donneesEnfants);setEtatBouton("")}
                        else {filtreSeul();setEtatBouton("seul")}
                      }} 
                      className="p-1 text-nn text-break" >
                        {enfantpresentsseul()} seuls
                    </Button>
                  :false
                }
                {
                  ![undefined, "", null, "undefined"].includes(enfantsPresents)&&![undefined, "", null, "undefined"].includes(enfantsPartis)?
                    <Button variant={etatBouton=="accompagne"?"dark":"outline-dark"} onClick={()=>{
                      if(etatBouton=="accompagne"){setDonneesEnfantsP(donneesEnfants);setEtatBouton("")}
                      else {filtreAccomp();setEtatBouton("accompagne")}
                    }} className="p-1 text-nn text-break" >{enfantsPresents.length - enfantsPartis.length - enfantpresentsseul()} <br/>
                      <span style={{fontSize:"0.8em"}}>accompagnés</span>
                      </Button>
                  :false
                }
                
              </Stack>
            </aside> */}
            {/* <aside className="col-1 p-0">
              <Stack gap={3}>
                <Button variant="primary" className="px-0">
                  <FontAwesomeIcon icon={faFilter} />
                </Button>
                <Button variant="primary" className="px-0">
                <FontAwesomeIcon icon={faRankingStar} />
                </Button>
              </Stack>
            </aside> */}
            <section className="col-12 sectionAppel border-start ps-1" >
              <Tabs
                defaultActiveKey="absent"
                id="fill-tab-example"
                className="mb-3"
                // onSelect={(key) => setActiveTab(key)}
                fill
              >
                <Tab eventKey="absent" title="Absents">
                  {messageLoad}
                {/* {JSON.stringify(upData)} */}
                {/* {donneesEnfants.filter(y=>!(journeeP[creneau].sortie.includes(y) || journeeP[creneau].enfants.includes(y))).length} */}
                  {
                    donneesEnfants.length>0 && journeeP.length==1 ?
                    <ListeEnfantAppel etat="absent" enfants={donneesEnfantsP.filter(y=>!(enfantsPartis.includes(y.uuid) || enfantsPresents.includes(y.uuid)))} objPresences={journeeP[creneau]} trait_arr={trait_arr}/>
                    :<p>Chargement des données</p>
                  }
                </Tab>
                <Tab eventKey="present" title="Presents">
                  {messageLoad}
                {/* {JSON.stringify(upData)} */}
                  {
                    Array.isArray(enfantsPresents) && enfantsPresents.length>0?
                    <ListeEnfantAppel etat="present" enfants={donneesEnfantsP.filter(x=>enfantsPresents.includes(x.uuid) && !enfantsPartis.includes(x.uuid))} objPresences={journeeP[creneau]} trait_arr={trait_arr}/>
                    :false
                  }
                </Tab>
                <Tab eventKey="parti" title="Partis" >
                {/* {JSON.stringify(upData)} */}
                  {
                    Array.isArray(enfantsPartis) && enfantsPartis.length>0?
                    <ListeEnfantAppel  etat="parti" 
                      enfants={donneesEnfantsP.filter(x=>enfantsPartis.includes(x.uuid))} 
                      objPresences={journeeP[creneau]} trait_arr={trait_arr} />
                    :false
                  }
                </Tab>
              </Tabs>              
            </section>
            
            <Modal show={synchMod} backdrop="static" keyboard={false} fullscreen={true}>
              <Modal.Body>
                <p>Synchronisation des données</p>
                <Vortex
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="vortex-loading"
                    wrapperStyle={{}}
                    wrapperClass="vortex-wrapper"
                    colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                  />
                </Modal.Body>
            </Modal>
            {/* <p>{donneesEnfants.length}</p> */}
            {/* <p>{JSON.stringify(donneesEnfantsP)}</p> */}
              {/* <ListeElements 
                typeElement="Enfants" 
                donnees={donneesEnfantsP}
                uuidCont={uuidCamping}
                 /> */}
          </Card.Body>
          <Card.Footer className="row justify-content-around" >
            <Button variant={upData.addPresence.length+upData.addSortie.length+upData.popPresence.length+upData.popSortie.length==0?"outline-danger":"danger"} className="p-1 col-5" onClick={()=>synchronisation()}>Synchroniser<br/>Sauvegarder</Button>
            <FloatingLabel className="col-6" label="Rechercher">
              <Form.Control
                // className={`${nomclasse} text-capitalize`}
                type="text"
                id="recherche_enfant"
                placeholder="Rechercher"
                // defaultValue={recEnfant}
                onChange = {(event)=>{filtrerString(event.target.value)}}
              />
              </FloatingLabel>
            
          </Card.Footer>
      </Card>
    );
  };

