import React, {useState,useEffect} from "react";
import { v4 as uuidv4 } from 'uuid';
import {
  PageContent,
  PageHeader,
    Box,
  FormField, TextInput, Button,Form,DropButton,
  List
} from "grommet";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast } from 'react-toastify';

import ListeMatchs from "../components/listeMatchs";
import Classement from "../components/classement";
// import { Outlet } from "react-router-dom";
import { AddCircle as AddIcon } from "grommet-icons";


const Tournoi = () => {
    const [activeTab, setActiveTab] = useState("configuration");
    const [tournoiInit, setTournoiInit] = useState(false);
    const [tournoiType, setTournoiType] = useState("");
    // const [tournoiNbTerrain, setTournoiNbTerrain] = useState(1);
    const [equipes, setEquipes] = useState({})
    const [tableauScores, setTS] = useState({})
    // const [tournoiGenere, setTournoiGenere] = useState(false)


    var arrTypeTournoi = [
      'Tout le monde rencontre tout le monde',
      'Élimination directe',
      "Poule + Élimination directe"
    ]

    // const changeTab = (i)=>{
    //   var a = []
    //   for(let j=0; j<4; j++){
    //     a[j]= (j===i)
    //   }
    //   // var a = [false,false,false,false]
    //   // a[i]=true
    //   setActiveTabs(a)
    // }
    const cliqueIconTournoi = (kf)=>{}
    const addEquipe = (nom)=>{
      let tempEquipes = {...equipes}
      let identifiant = String(nom).toLowerCase().split(" ").join("")
      let uuid = uuidv4();
      if(String(nom).trim().length==0){
        alert("Entrez un nom d'équipe")
      }
      else if(Object.values(tempEquipes).filter(x=>x["id"]===identifiant).length!==0){
        alert("Cette équipe existe déjà, veuillez entrer un nom différent")
      }
      else{
        tempEquipes[uuid]={"nom":nom,"point":0, "id":identifiant, "nbprog":0, "uuid":uuid}
        setEquipes(tempEquipes)
      }
    }

    const gestionScore = (es1,es2,tour)=>{
      //es1 = [e1,s1] avec e1 uuid

      // ts = {...uuid_e1:{...uuid_e2:{...numerotour:[s1,s2]}}}
      // doublé pour chaque equipe
      console.log("GESTION SCORE")
      console.log(es1)
      console.log(es2)

      let tempTS = {...tableauScores}
      //e1
      tempTS[es1[0]["uuid"]] = tempTS[es1[0]["uuid"]] || {}
      tempTS[es1[0]["uuid"]][es2[0]["uuid"]] = tempTS[es1[0]["uuid"]][es2[0]["uuid"]] || {}
      tempTS[es1[0]["uuid"]][es2[0]["uuid"]][tour] = [es1[1],es2[1]]
      //e2
      tempTS[es2[0]["uuid"]] = tempTS[es2[0]["uuid"]] || {}
      tempTS[es2[0]["uuid"]][es1[0]["uuid"]] = tempTS[es2[0]["uuid"]][es1[0]["uuid"]] || {}
      tempTS[es2[0]["uuid"]][es1[0]["uuid"]][tour] = [es2[1],es1[1]]
      //reaffect
      console.log(tempTS)
      setTS(tempTS)
    }

    //notifications
    const notif_gen = (message)=>toast(message,
      {position: "top-right",autoClose: 5000,hideProgressBar: false,
      closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })
    const notif_tournoiInit = (typetournoi) => notif_gen(`Type de tournoi choisi : ${typetournoi}`);
    // const notif_ajoutezDesEquipes = () => toast(`Vous pouvez maintenant ajouter des équipes`);
    const notif_rencontreExplain = () => notif_gen(`Cliquez sur une rencontre pour en saisir le score`);
    const notif_equipeExplain = () => toast(`Cliquez sur le "+" pour ajoutez des équipes`,{onClose:()=>{console.log("marcoil");notif_passezaumatch()},position: "top-right",autoClose: 5000,hideProgressBar: false,
    closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
    const notif_passezaumatch = () => notif_gen(`Une fois toutes les équipes déclarées, cliquez sur "Matchs"`);

    const tournoiInitialise = ()=>{
      setTournoiInit(true);
      notif_tournoiInit(arrTypeTournoi[tournoiType])
      // notif_ajoutezDesEquipes()
      setActiveTab("equipes")
    }

    useEffect(()=>{

      if(activeTab==="equipes"){
        notif_equipeExplain()
      }
      else if(activeTab==="matchs")notif_rencontreExplain()
    }, [activeTab])
    


    return(
        <PageContent>
          <Tabs
              defaultActiveKey="configuration"
              activeKey={activeTab}
              onSelect={k => setActiveTab(k)}
              id="tab-tournoi"
              className="mb-3 text-grand"
              justify
            >
              <Tab eventKey="configuration" title="Configuration">
                <Box>
                  <PageHeader title="Parametres Tournoi" />
                    {/* <Select
                      placeholder="Type de tournoi"
                      size="medium"
                      options={arrTypeTournoi}
                      value={arrTypeTournoi[tournoiType]}
                      onChange={( {option}) => {setTournoiType(arrTypeTournoi.findIndex(x=>x===option))}}
                    /> */}
                    <Container>
                      <Row className="justify-content-around align-content-stretch">
                        <Col md={3} sm={4} xs={6} >
                          <Card onClick={()=>{setTournoiType(0)}} bg={(tournoiType===0?"secondary":"")}
                                text={(tournoiType===0?"white":"black")}
                                                      >
                            <Card.Img variant="top" src="img/icone_tournoi/tournante.svg" alt="tournoi tournante" />
                            <Card.Body>
                              <Card.Title className="text-center text-retract" >Tournante</Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={3} sm={4} xs={6}  >
                          <Card onClick={()=>{setTournoiType(1)}} 
                            bg={(tournoiType===1?"secondary":"")}
                            text={(tournoiType===1?"white":"black")}
                            >
                            <Card.Img variant="top" src="img/icone_tournoi/eliminatoire.svg" alt="tournoi à élminitation directe" />
                            <Card.Body>
                              <Card.Title className="text-center text-retract" >Élimination directe</Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={3} sm={4} xs={6} >
                          <Card onClick={()=>{setTournoiType(2)}} 
                            bg={(tournoiType===2?"secondary":"")}
                            text={(tournoiType===2?"white":"black")}
                            >
                            <Card.Img variant="top" src="img/icone_tournoi/poule_eliminatoire.svg" alt="tournoi avec poule + élminitation directe" />
                            <Card.Body>
                              <Card.Title className="text-center text-retract" >Poule + Élimination directe</Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                    {/* <FormField name="terrain" margin="medium" htmlFor="text-input-terrain" label="Nombre de terrain :">
                      <TextInput placeholder="Nombre de Terrain" name="terrain" value={tournoiNbTerrain} size="large" pad="small" onChange={(nbt)=>setTournoiNbTerrain(Number(nbt.target.value))} number />        
                    </FormField> */}
                    <Button  color="brand" pad="small" margin="small" onClick={tournoiInitialise}>Initialiser le tournoi</Button>
                </Box>
              </Tab>
              <Tab eventKey="equipes" title="Équipes">
                <Box>
                  <PageHeader title="Equipes" />
                  {
                    (tournoiInit?(equipes.length!==0?
                      <List
                        primaryKey="nom"
                        // secondaryKey="percent"
                        data={Object.values(equipes)}
                      />
                      
                    :<div className="contCent"><p>Appuyez sur le "+" pour ajouter des équipes</p></div>):<div className="contCent"><p>Vous devez d'abord initialiser un tournoi en cliquant sur la clef anglaise</p></div>)
                  }
                  <DropButton
                    className="stickyBG"
                    dropAlign={{bottom:"bottom",left:"right" }}
                      dropContent={
                          <Box pad="large" background="light-2" >
                            {/* <Button className="fermetureDrop" onClick={()=>{console.log("ciufyt");document.querySelector('#logoAP').click();}}><Close size="medium" /></Button> */}
                            <Form
                              // value={value}
                              // onChange={nextValue => setValue(nextValue)}
                              // onReset={() => setValue({})}
                              // onSubmit={({ value }) => {console.log(value);addEquipe(value.nom,value.score);return false}}
                              onSubmit={(event)=>{addEquipe(event.value.nom);event.target.reset();}}
                              >
                              <FormField name="nom" htmlFor="text-input-nom" label="Nom équipe">
                                <TextInput id="text-input-nom" name="nom" />
                              </FormField>
                              <Box direction="row" gap="medium">
                                <Button type="submit" primary label="Créer l'équipe"  />
                                <Button type="reset" label="Effacer" />
                              </Box>
                            </Form>
                          </Box>
                        }
                      >
                        <AddIcon size="large" color="brand"/>
                    </DropButton>
                    {(equipes.length>2?<div className="contCent" style={{font_size:"18px"}}><p>Générez le tableau des matchs avec le prochain onglet</p></div>:true)}
                </Box>
              </Tab>
              <Tab eventKey="matchs" title="Matchs">
                <Box>
                  <PageHeader title="Matchs" />
                  {
                    (tournoiInit?true:<div className="contCent"><p>Vous devez d'abord initialiser un tournoi en cliquant sur la clef anglaise</p></div>)
                  }
                  {
                    (equipes.length<3?true:
                      // (
                      //   tournoiGenere?
                      //   <ListeMatchs />:
                      //   <Button label="Générer le tableau des matchs" />
                      // )
                      <ListeMatchs equipes={equipes} typeTournoi={tournoiType} gs={gestionScore} ts={tableauScores} />
                    )
                  }

                </Box>
              </Tab>
              <Tab eventKey="classement" title="Classement" >
                <Box>
                  <PageHeader title="Classement" />
                  {
                    (tournoiInit?true:<div className="contCent"><p>Vous devez d'abord initialiser un tournoi en cliquant sur la clef anglaise</p></div>)
                  }
                  {
                    (equipes.length<3?true:
                      // (
                      //   tournoiGenere?
                      //   <ListeMatchs />:
                      //   <Button label="Générer le tableau des matchs" />
                      // )
                      <Classement equipes={equipes} typeTournoi={tournoiType} gs={gestionScore} ts={tableauScores} />
                    )
                  }
                </Box>
              </Tab>
            </Tabs>
            {/* <Tabs flex="grow" >
              <Tab icon={<Configure/>} primary={activeTab[0]} pad="5px" plain={true} margin="medium" onClick={()=>{changeTab(0)}}>
                
              </Tab>
              <Tab icon={<Group/>} primary={activeTab[1]} plain={true} pad="5px" margin="medium" onClick={()=>{changeTab(1)}} >
                
              </Tab>
              <Tab icon={<Calendar/>} primary={activeTab[2]} plain={true} pad="5px" margin="medium" onClick={()=>{changeTab(2)}}>
                
              </Tab>
              <Tab icon={<Achievement/>} primary={activeTab[3]} plain={true} pad="5px" margin="medium" onClick={()=>{changeTab(3)}}>
                
              </Tab>
            </Tabs> */}
            {/* <ToastContainer className="text-norm" /> */}
        </PageContent>
        )
    
  };
  
  export default Tournoi;
  