import React, {useState,useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'

import { useLoaderData,useOutletContext, useParams, useSearchParams  } from "react-router-dom";
import { toast } from 'react-toastify';

import LAppel from "../components/club/lappel";

export async function appel_loader({ params }) {
  //const contact = await getContact(params.contactId);
  const id_camp = params.id_camp
  const id_club = params.id_club
  const id_presence = params.id_presence
  return {id_camp, id_club, id_presence};
}

const PageAppel = ({}) => {
  const { id_camp, id_club, id_presence } = useLoaderData();
  const {setTitreBandeau} = useOutletContext()

  const [nom_club, setNomClub] = useState("CLUB")
  const [datePre, setDatePre] = useState("")
  // const [donnees, setDonnees] = useState([])
  // const [modalAcces, setModalAcces] = useState(false)
  let [searchParams, setSearchParams] = useSearchParams();

  const JETON = useSelector((state) => state.jeton.access)
  const USERUUID = useSelector((state) => state.statutUser.uuid)

  // const dispatch = useDispatch()

  
  // const urls={
  //   "Tournois":`api/peluche/${id_camp}/tournois`,
  //   "Scores":`api/peluche/${id_camp}/scores`,
  //   // "Enfants":`api/peluche/${id_camp}/enfants`,
  // }
  const notif_gen = (message)=>toast.error(message,
    {position: "top-right",autoClose: 5000,hideProgressBar: false,
    closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })
  useEffect(()=>{
    console.log(JETON)
    let previ = searchParams.get("c")
    let didi = searchParams.get("d")
    if(![null, "null", undefined, "undefined",""].includes(previ))setNomClub(previ)
    if(![null, "null", undefined, "undefined",""].includes(didi))setDatePre(didi)
    setTitreBandeau(previ+" - "+didi)      
  },[])

  return (
       <LAppel donneesClub={{nom:nom_club, uuid:id_club}} uuidCamping={id_camp} id_presence={id_presence}></LAppel>
  );
};

export default PageAppel;