import { createSlice } from '@reduxjs/toolkit'


// 0 -> null
// 1 -> anim
// 2 -> Resp
// 3 -> Gestionnaire

export const statutUserSlice = createSlice({
  name: 'statutUser',
  initialState: {
    statutu: (sessionStorage.getItem('statutu')!=null?Number(sessionStorage.getItem('statutu')):0),
    uuid: (sessionStorage.getItem('diuu')!=null?sessionStorage.getItem('diuu'):""),
  },
  reducers: {
    setIsGest: (state, action) => {
      state.statutu = 3
      sessionStorage.setItem('statutu', 3);
    },
    setIsResp: (state, action) => {
      state.isResp = 2
      sessionStorage.setItem('statutu', 2);
    },
    setIsAnim: (state, action) => {
      state.isResp = 1
      sessionStorage.setItem('statutu', 1);
    },
    setUuid: (state, action) => {
      state.uuid = action.payload
      sessionStorage.setItem('diuu', action.payload);
    }
  },
})

// Action creators are generated for each case reducer function
export const { setIsGest,setIsResp,setIsAnim, setUuid  } = statutUserSlice.actions

export default statutUserSlice.reducer