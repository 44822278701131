import React, {useState,useEffect, useRef} from "react";
import { v4 as uuidv4 } from 'uuid';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Button from "react-bootstrap/Button";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashCan, faPencil, faMinus,faLayerGroup, faPeopleGroup, faRankingStar, faMedal, faPeopleCarryBox, faPlusMinus } from '@fortawesome/free-solid-svg-icons'

// import ListeMatchs from "../components/listeMatchs";
// import Classement from "../components/classement";

import C from "../conf/tournoi.json";
import S from "../conf/serveur.json"
import capitalise from "../jsTools/toolsString"
import shuffle from '../jsTools/toolsArray';
// import ClassementTournante from "./classementTournante";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>{},
  );

  return (
    <div onClick={decoratedOnClick}>
      <Button
      variant="ble text-white"
      
    >
      <FontAwesomeIcon icon={faPlus} />
      {/* <FontAwesomeIcon icon={eventKey==0?faMinus:faPlus} /> */}
      
    </Button>
    <span className="ms-5">{children}</span>
    </div>
    
  );
}


const TournoiComp = ({uuidAnim, uuidCamping, uuidTournoi}) => {
    const [activeTab, setActiveTab] = useState("configuration");
    const [tournoiInit, setTournoiInit] = useState(false);
    const [tournoiType, setTournoiType] = useState("");
    const [disableTab, setDisableTab] = useState([false, false, false, false])
    const [inputEquipe, setInputEquipe] = useState("")
    // const [initiateur, setInitiateur] = useState(localStorage.getItem('listrand')!=null)
    // const [tournoiNbTerrain, setTournoiNbTerrain] = useState(1);
    const [equipes, setEquipes] = useState({})
    const [tableauScores, setTS] = useState({})

    const [tournoiEquipes, setTournoiEquipes] = useState({})
    const [tournoiResultats, setTournoiResultats] = useState({})

    const [listRand, setlistRand] = useState([])
    const [tl_state, setTl] = useState([])
    const [tempNobody, setTempNobody] = useState(null)
    var tl = [...tl_state] //tournante listes
    const nobody = C.nobody
    const [nbtour, setNbtour] = useState(0)
    const [modalScore, setModalScore] = useState("")
    const [tempScore1, setTS1] = useState(0)
    const [tempScore2, setTS2] = useState(0)

    const [classement, setClassement] = useState([])

    const [chaussette, setChaussette] = useState(null)
    const chaussetteFirst = useRef(true);
    // const [tournoiGenere, setTournoiGenere] = useState(false)


    var arrTypeTournoi = [
      'Tout le monde rencontre tout le monde',
      'Élimination directe',
      "Poule + Élimination directe"
    ]

    useEffect(()=>{
      console.log("maintenant")
      initierConnexion()
      // if(initiateur)setlistRand(JSON.parse(localStorage.getItem('listrand')))
    }, [])

    useEffect(()=>{
      console.log("chaussette")
    },[chaussette])

    useEffect(()=>{
      console.log("useeffecttournoiequipes")
      if(chaussette !==null){
        chaussette.onmessage = (event)=>{
          console.log("setmessage");
          handleMessage(event.data)}
      }
    }, [tournoiEquipes])
    useEffect(()=>{
      console.log("useeffecttournoiresultats")
      console.log(chaussette!==null)
      if(chaussette !==null){
        chaussette.onmessage = (event)=>{
          console.log("setmessage");
          handleMessage(event.data)}
      }
      if(Object.keys(tournoiResultats).length!=0 && Object.keys(tournoiEquipes).length!=0){
        setDisableTab([true, true, false, false])
        classerTournante()
        
        if(listRand.length==0)retrievelistRand()
      }
      
      // console.log(tournoiResultats)
      // console.trace()
    },[tournoiResultats])
    const otoa = (obj)=>Object.entries(obj).map(([key, value]) => ({ uuid: key, ...value }));
    const atoo = (arr)=>arr.reduce((acc, obj) => {acc[obj.uuid] = obj;return acc;}, {})
    const handleMessage = (data)=>{
        let d = JSON.parse(data)
        console.log(d)
        console.log(tournoiResultats)
        if(d?.methode){
          switch (d.methode) {
            case "creer_equipe":
              addEquipe(d.donnees.nom, d.donnees.uuid)
              break;
            case "modifier_equipe":
              modifyEquipe(d.donnees.nom, d.donnees.uuid)
              break;
            case "supprimer_equipe":
              deleteEquipe(d.donnees.uuid)
              break;
            case "creer_rencontres":
              addRencontres(d.donnees)
              break;
            case "modifier_rencontres":
              console.log("MODIFIER")
              modifyRencontres(d.donnees)
              break;
            case "supprimer_rencontres":
              deleteRencontres(d.donnees)
              break;
            case "modifier_conf":
              validerConf(d.donnees)
              break;
            default:
              console.warn("message websocket non prévu")
              break;
          }
        }
        else if (d?.uuid){
          console.log("données connexion websocket")
          
          if(Object.keys(d.jsonConf).length==0){
            console.log("ifif")
            setDisableTab([false, true, true, true])
            
          }
          else{
            console.log("coucouif")
            console.log(Object.keys(d.equipes).length>0)
            console.log(Object.keys(d.resultats).length>0)
            validerConf(d.jsonConf)
            if(Object.keys(d.equipes).length>0) setTournoiEquipes(atoo(d.equipes))
            if(Object.keys(d.resultats).length>0) setTournoiResultats(atoo(d.resultats))
            setTournoiInit(true)
            setActiveTab("matchs")
          }
          

        }
        else {
          notif_err("Erreur serveur, JSON non conforme")
        }
    }
    const initierConnexion = ()=>{
      console.log("initialisation")
      if(chaussette) chaussette.close();
      let chausson = new WebSocket(`${S.serveurApi.websocket}/ws/parasol/tournoi/${uuidCamping}/${uuidTournoi}/${uuidAnim}`);
      
      chausson.onclose = (event)=>{
        console.log("coucoucocu")
        console.log(event)
        notif_err("Connexion avec le serveur interrompue.\nVeuillez réessayer")
      }
      chausson.onOpen = (event)=>{
        console.log("onopen")
        console.log(event)
        console.log(event.data)
      }
      chausson.onmessage = (event)=>{
        console.log("onmessage")
        // console.log(event.data)
        handleMessage(event.data)
      }
      setChaussette(chausson);
    }

    const messageChaussette = (obj)=>{
      if(chaussette){
        chaussette.send(obj)
      }
    }

    const retrievelistRand= ()=>{
      let nbe = Object.keys(tournoiEquipes).length
      let cr = Object.keys(tournoiResultats)
      let liste_hasard = [[],[]]
      for(let r=0; r<nbe/2; r++){
        liste_hasard[0].push(tournoiResultats[cr[r]]["equipes"][0])
        liste_hasard[1].push(tournoiResultats[cr[r]]["equipes"][1])
      }
      let nt = nbe%2==0?nbe-1:nbe
      initTournante(liste_hasard.flat(),nt)
    }
    // const changeTab = (i)=>{
    //   var a = []
    //   for(let j=0; j<4; j++){
    //     a[j]= (j===i)
    //   }
    //   // var a = [false,false,false,false]
    //   // a[i]=true
    //   setActiveTabs(a)
    // }
    const validerConf = (d)=>{
      setTournoiType(d.tournoiType)
    }
    const normstring = (s)=>String(s).toLowerCase().split(" ").join("")
    const ajouterEquipe = (nom)=>{
      let uuid = uuidv4();
      if(String(nom).trim().length==0)notif_err("Entrez un nom d'équipe")
      else if(Object.keys(tournoiEquipes).filter(x=>normstring(tournoiEquipes[x].nom)==normstring(nom)).length!=0)notif_err(`Le nom "${nom}" est déjà utilisé`)
      else{
        addEquipe(capitalise(nom),uuid, true)
        setInputEquipe("")
        notif_gen(`Equipe "${nom}" créée`, 2000)
      }
    }
    const modifierEquipe = (equipe, nom)=>{
      // let uuid = uuidv4();
      if(String(nom).trim().length==0)notif_err("Entrez un nom d'équipe")
      else if(Object.keys(tournoiEquipes).filter(x=>normstring(tournoiEquipes[x].nom)==normstring(nom)).length!=0)notif_err(`Le nom "${nom}" est déjà utilisé`)
      else{
        modifyEquipe(capitalise(nom),equipe.uuid, true)
      }
    }
    const supprimerEquipe = (equipe)=>{
      deleteEquipe(equipe.uuid, true)
      notif_gen(`Equipe ${equipe.nom} supprimée`, 2000)
    }
    const ajouterRencontres = (rs)=>{
      addRencontres(rs,true)
      notif_gen(`${rs.length} rencontre${rs.length==1?"":"s"} ajoutée${rs.length==1?"":"s"}`)
    }
    const modifierRencontre = (r)=>{
      modifyRencontres([r], true)
      notif_gen(`Score de la rencontre ${r.equipes.map(x=>tournoiEquipes[x].nom)} enregistré`)
    }
    const supprimerRencontres = (rs)=>{
      deleteRencontres(rs, true)
      notif_gen(`${rs.length} rencontre${rs.length==1?"":"s"} supprimée${rs.length==1?"":"s"}`)
    }
    
    const addEquipe = (nom,uuid, send=false)=>{
      let tempEquipes = {...tournoiEquipes}
      let e = {"nom":nom, "uuid":uuid, "rencontres":[]}
      tempEquipes[uuid]=e
      setTournoiEquipes(tempEquipes)
      if(send){
        chaussette.send(
          JSON.stringify(
            {
              "methode":"creer_equipe",
              "donnees":{
                "nom":nom,
                "uuid":uuid
              }
            }
          )
        )
      }
    }
    const modifyEquipe = (nom,uuid, send=false)=>{
      let tempEquipes = {...tournoiEquipes}
      tempEquipes[uuid].nom = nom
      setTournoiEquipes(tempEquipes)
      if(send){
        chaussette.send(
          JSON.stringify(
            {
              "methode":"modifier_equipe",
              "donnees":{
                "nom":nom,
                "uuid":uuid
              }
            }
          )
        )
      }
    }
    const deleteEquipe = (uuid, send=false)=>{
      let tempEquipes = {...tournoiEquipes}
      delete tempEquipes[uuid]
      setTournoiEquipes(tempEquipes)
      if(send){
        chaussette.send(
          JSON.stringify(
            {
              "methode":"supprimer_equipe",
              "donnees":{
                "uuid":uuid
              }
            }
          )
        )
      }
    }
    const addRencontres = (rs, send=false)=>{
      let tempRencontres = {...tournoiResultats}
      for (let r of rs) tempRencontres[r["uuid"]]=r
      console.log("ADD RENCONTRES")
      console.log(rs)
      setTournoiResultats(tempRencontres)
      if(send){
        chaussette.send(
          JSON.stringify(
            {
              "methode":"creer_rencontres",
              "donnees":rs
            }
          )
        )
      }
    }
    const modifyRencontres = (rs, send=false)=>{
      let tempRencontres = {...tournoiResultats}
      console.log(tournoiResultats)
      console.log(tempRencontres)
      for (let r of rs) tempRencontres[r["uuid"]]=r
      console.log("before set")
      console.log(tempRencontres)
      setTournoiResultats(tempRencontres)
      if(send){
        chaussette.send(
          JSON.stringify(
            {
              "methode":"modifier_rencontres",
              "donnees":rs
            }
          )
        )
      }
    }
    const deleteRencontres = (rs, send=false)=>{
      console.log("DELETE")
      let tempRencontres = {...tournoiResultats}
      for (let r of rs) delete tempRencontres[r["uuid"]]
      setTournoiResultats(tempRencontres)
      if(send){
        chaussette.send(
          JSON.stringify(
            {
              "methode":"supprimer_rencontre",
              "donnees":rs
            }
          )
        )
      }
    }
    const genererMatchs = ()=>{
      console.log("genererMatch")
      var lr = [...listRand]
      var nt = nbtour
      console.log(lr)
      console.log(nt)
      if(Object.keys(tournoiResultats).length==0){
        console.log("if")
        lr = shuffle(Object.keys(tournoiEquipes))
        setlistRand(lr)
        // localStorage.setItem('listRand', JSON.stringify(lr));
        console.log(lr)
        console.log("nt")
        nt = lr.length%2==0?lr.length-1:lr.length
        console.log(nt)
        initTournante(lr, nt)
      }
      let nouveauxMatchs = Array.from({ length: nt }, (_, index) => index)
      console.log(nt)
      nouveauxMatchs = nouveauxMatchs.map(x=>tournante_ajouterTour(x==0,x+1)).flat()
      // nouveauxMatchs.flat()
      console.log("nouveaux matchs")
      console.log(nouveauxMatchs)
      ajouterRencontres(nouveauxMatchs)
    }
    const initTournante = (lr, nt)=>{
      // let tl = []
      let nono = false
      console.log("INI%T")
      console.log(lr)
      let lrl = nt
      console.log(lr.slice(0, Math.trunc(lr.length/2)))
      console.log(listRand.slice(0, Math.trunc(listRand.length/2)))
      tl.push(lr.slice(0, Math.trunc(lr.length/2)))
      tl.push(lr.slice(Math.trunc(lr.length/2)))
      if(tl[1].length>tl[0].length){
        addEquipe(C.nobodyobj.nom, C.nobodyobj.uuid, true)
        tl[0].push(nobody)
        nono = true
      }
      setTl(tl)
      // console.log(tl_state)
      // setTournoiRencontres([])
      // setTournoiResultats({})
      setNbtour(nt)
      // setInitiateur(true)
      // tournante_ajouterTour(nono)
    }
    const getTempNobody = (r1,r2) =>{
      if (r1===tempNobody)return r1
      else if (r2===tempNobody)return r2
      else return false
    }
    const getNotNobody = (r1,r2) =>{
      if (r1===nobody)return r2
      else if (r2===nobody)return r1
      else return false
    }
    const creer_match = (u_equipes, tr)=>{
      return {
        "uuid": uuidv4(),
        "equipes":u_equipes,
        "scores":u_equipes.map(x=>0),
        "etat":"EA",
        "tour":tr
      }
    }
    const tournante_ajouterTour = (firstno=false, tr=0)=>{
        console.log("#########ajout tour#########")
        //fill r avec nouvelles rencontres
        var r = []
        for(let i=0; i<tl[0].length; i++){
          // r.push([tl[0][i],tl[1][i]])
          r.push(creer_match([tl[0][i],tl[1][i]], tr))
        }

        //on fait tourner les vecteurs des rencontres
        let pivot = tl[0][tl[0].length-1]
        for(let i=tl[0].length-1; i>0; i--){
          tl[0][i] = tl[0][i-1]
        }
        tl[0][1] = tl[1][0]
        for(let i=0; i<tl[1].length-1;i++){
          tl[1][i] = tl[1][i+1]
        }
        tl[1][tl[1].length-1] = pivot

        //si un "nobody" on met le match de celui qui 
        //n'a pas joué au dernier tour en premier
        //et nouvelle team dans le tempnobody
        if(Object.keys(tournoiResultats).length===0 && firstno){
          for(let i=0; i<r.length;i++){
            if(getNotNobody(r[i][0],r[i][1])){
              setTempNobody(getNotNobody(r[i][0],r[i][1]))
            }
          }
        }
        else if (tempNobody!==null){
          let temp = 0
          for(let i=0; i<r.length;i++){
            if(getTempNobody(r[i][0],r[i][1])){
              temp = i
            }
          }
          for(let i=0; i<r.length;i++){
            if(getNotNobody(r[i][0],r[i][1])){
              setTempNobody(getNotNobody(r[i][0],r[i][1]))
            }
          }
          //r.unshift(r.splice(temp, 1)[0])
          [r[0],r[temp]] = [r[temp],r[0]]
        }
        console.log(r.length)
        console.log(r)
        return r
        //ajout au state du component
        // let tempR = [...rencontres,...r]
        // // tempR.push(r)
        // setRencontres(tempR)
        // setTl(tl)
    }
    

    const tournante_ajouterEquipe = (equipe)=>{
        // let tl = [...tl_state]
        if (tl[0].findIndex(x=>x===nobody)){
            tl[0][tl[0].findIndex(x=>x==nobody)] = equipe
        }
        else if (tl[1].findIndex(x=>x===nobody)){
            tl[1][tl[1].findIndex(x=>x===nobody)] = equipe
        }
        else {
            tl[0].push(nobody)
            tl[1].push(equipe)
        }
        setTl(tl)
    }
    // const addEquipe = (nom)=>{
    //   let tempEquipes = {...equipes}
    //   let identifiant = String(nom).toLowerCase().split(" ").join("")
    //   let uuid = uuidv4();
    //   if(String(nom).trim().length==0){
    //     alert("Entrez un nom d'équipe")
    //   }
    //   else if(Object.values(tempEquipes).filter(x=>x["id"]===identifiant).length!==0){
    //     alert("Cette équipe existe déjà, veuillez entrer un nom différent")
    //   }
    //   else{
    //     tempEquipes[uuid]={"nom":nom,"point":0, "id":identifiant, "nbprog":0, "uuid":uuid}
    //     setEquipes(tempEquipes)
    //   }
    // }

    const gestionScore = (es1,es2,tour)=>{
      //es1 = [e1,s1] avec e1 uuid

      // ts = {...uuid_e1:{...uuid_e2:{...numerotour:[s1,s2]}}}
      // doublé pour chaque equipe
      console.log("GESTION SCORE")
      console.log(es1)
      console.log(es2)

      let tempTS = {...tableauScores}
      //e1
      tempTS[es1[0]["uuid"]] = tempTS[es1[0]["uuid"]] || {}
      tempTS[es1[0]["uuid"]][es2[0]["uuid"]] = tempTS[es1[0]["uuid"]][es2[0]["uuid"]] || {}
      tempTS[es1[0]["uuid"]][es2[0]["uuid"]][tour] = [es1[1],es2[1]]
      //e2
      tempTS[es2[0]["uuid"]] = tempTS[es2[0]["uuid"]] || {}
      tempTS[es2[0]["uuid"]][es1[0]["uuid"]] = tempTS[es2[0]["uuid"]][es1[0]["uuid"]] || {}
      tempTS[es2[0]["uuid"]][es1[0]["uuid"]][tour] = [es2[1],es1[1]]
      //reaffect
      console.log(tempTS)
      setTS(tempTS)
    }

    //notifications
    const notif_err = (message, temps=5000)=>toast.error(message,
      {position: "top-right",autoClose: temps,hideProgressBar: false,
      closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })
    const notif_gen = (message, temps=5000)=>toast(message,
      {position: "top-right",autoClose: temps,hideProgressBar: false,
      closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })
    const notif_tournoiInit = (typetournoi) => notif_gen(`Type de tournoi choisi : ${typetournoi}`);
    // const notif_ajoutezDesEquipes = () => toast(`Vous pouvez maintenant ajouter des équipes`);
    const notif_rencontreExplain = () => notif_gen(`Cliquez sur une rencontre pour en saisir le score`);
    const notif_equipeExplain = () => toast(`Cliquez sur le "+" pour ajoutez des équipes`,{onClose:()=>{console.log("marcoil");notif_passezaumatch()},position: "top-right",autoClose: 5000,hideProgressBar: false,
    closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
    const notif_passezaumatch = () => notif_gen(`Une fois toutes les équipes déclarées, cliquez sur "Matchs"`);

    const tournoiInitialise = ()=>{
      setTournoiInit(true);
      setDisableTab([true, false, false, false])
      chaussette.send(
        JSON.stringify(
          {
            "methode":"modifier_conf",
            "donnees":{
              "tournoiType":tournoiType
            }
          }
        )
      )
      notif_tournoiInit(arrTypeTournoi[tournoiType])
      // notif_ajoutezDesEquipes()
      setActiveTab("equipes")
    }

    const classerTournante = ()=>{
      // console.log("classement Tournante")
      let tempR = {...tournoiResultats}
      let tempE = {...tournoiEquipes}
      let lr = Object.keys(tempR)
      let le = Object.keys(tempE)
      for(let e of le){
        tempE[e]["points"] = 0
        tempE[e]["diffpoints"] = 0
        tempE[e]["nbmatch"] = 0
      }
      // console.log("TEMPR")
      // console.log(tempR)
      for(let r of lr){
        // console.log(r)
        // console.log(getNotNobody(tempR[r].equipes[0],tempR[r].equipes[1]))
        // console.log(tempR[r].etat!="J")
        if(getNotNobody(tempR[r].equipes[0],tempR[r].equipes[1]) || tempR[r].etat!="J"){
          // console.log("nope")
        }
        else if(tempR[r].scores[0]==tempR[r].scores[1]){
          // console.log("PIOPIU")
          tempE[tempR[r].equipes[0]]["points"]+=C.score.egalite
          tempE[tempR[r].equipes[1]]["points"]+=C.score.egalite
          tempE[tempR[r].equipes[0]]["nbmatch"]+=1
          tempE[tempR[r].equipes[1]]["nbmatch"]+=1
        }
        else if (tempR[r].scores[0]<tempR[r].scores[1]){
          // console.log("MOMOMO")
          tempE[tempR[r].equipes[0]]["points"]+=C.score.perdu
          tempE[tempR[r].equipes[1]]["points"]+=C.score.gagne
          tempE[tempR[r].equipes[0]]["nbmatch"]+=1
          tempE[tempR[r].equipes[1]]["nbmatch"]+=1
          tempE[tempR[r].equipes[0]]["diffpoints"]-= (tempR[r].scores[1]-tempR[r].scores[0])
          tempE[tempR[r].equipes[1]]["diffpoints"]+= (tempR[r].scores[1]-tempR[r].scores[0])
        }
        else{
          tempE[tempR[r].equipes[1]]["points"]+=C.score.perdu
          tempE[tempR[r].equipes[0]]["points"]+=C.score.gagne
          tempE[tempR[r].equipes[1]]["nbmatch"]+=1
          tempE[tempR[r].equipes[0]]["nbmatch"]+=1
          tempE[tempR[r].equipes[1]]["diffpoints"]-= (tempR[r].scores[0]-tempR[r].scores[1])
          tempE[tempR[r].equipes[0]]["diffpoints"]+= (tempR[r].scores[0]-tempR[r].scores[1])
        }
      }
      delete tempE[C.nobody]
      // console.log("tempE")
      // console.log(tempE)
      tempE = otoa(tempE).sort((a,b)=>{
        let apts = a.pts_marques-a.pts_encaisses
        let bpts = b.pts_marques-b.pts_encaisses
        
        if(a.points<b.points)return 1
        else if (a.points>b.points) return -1
        else {
          if (a.diffpoints<b.diffpoints)return 1
          else if (a.diffpoints>b.diffpoints)return -1
          else return 0
        }
      })
      // console.log(tempE)
      setClassement(tempE)
      
    }
    // useEffect(()=>{

    //   if(activeTab==="equipes"){
    //     notif_equipeExplain()
    //   }
    //   else if(activeTab==="matchs")notif_rencontreExplain()
    // }, [activeTab])
    


    return(
        <div>
          {/* <Button size="sm" onClick={initierConnexion}>init chaussette</Button> */}
          <Tabs
              defaultActiveKey={tournoiInit?"matchs":"configuration"}
              activeKey={activeTab}
              onSelect={k => setActiveTab(k)}
              id="tab-tournoi"
              className="mb-3 text-grand"
              justify
            >
              <Tab eventKey="configuration" title="⚙️" disabled={disableTab[0]}>
                <div>
                  <h4>Parametres Tournoi</h4>
                    {/* <Select
                      placeholder="Type de tournoi"
                      size="medium"
                      options={arrTypeTournoi}
                      value={arrTypeTournoi[tournoiType]}
                      onChange={( {option}) => {setTournoiType(arrTypeTournoi.findIndex(x=>x===option))}}
                    /> */}
                    <Container>
                      <p>Choississez le type de tournoi :</p>
                      <p>{tournoiType}</p>
                      <Row className="justify-content-around align-content-stretch">
                        <Col md={3} sm={4} xs={6} >
                          <Card onClick={()=>{setTournoiType(0)}} bg={(tournoiType===0?"secondary":"")}
                                text={(tournoiType===0?"white":"black")} className="m-2"
                                                      >
                            <Card.Img variant="top" src="/img/icone_tournoi/tournante.svg" alt="tournoi tournante" />
                            <Card.Body>
                              <Card.Title className="text-center text-retract" >Tournante</Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={10} sm={10} xs={10} className="" >
                          <Card  
                            // bg={(tournoiType===1?"secondary":"")}
                            // text={(tournoiType===1?"white":"black")}
                            className="m-2"
                            >
                            {/* <Card.Img variant="top" src="/img/icone_tournoi/eliminatoire.svg" alt="tournoi à élminitation directe" /> */}
                            <Card.Body>
                              {/* <Card.Title className="text-center text-retract" >Élimination directe</Card.Title> */}
                              Tournoi à élimination directe (avec ou sans poule) à venir
                            </Card.Body>
                          </Card>
                        </Col>
                        {/* <Col md={3} sm={4} xs={6} className="" >
                          <Card onClick={()=>{setTournoiType(1)}} 
                            bg={(tournoiType===1?"secondary":"")}
                            text={(tournoiType===1?"white":"black")}
                            className="m-2"
                            >
                            <Card.Img variant="top" src="/img/icone_tournoi/eliminatoire.svg" alt="tournoi à élminitation directe" />
                            <Card.Body>
                              <Card.Title className="text-center text-retract" >Élimination directe</Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={3} sm={4} xs={6} >
                          <Card onClick={()=>{setTournoiType(2)}} 
                            bg={(tournoiType===2?"secondary":"")}
                            text={(tournoiType===2?"white":"black")}
                            className="m-2"
                            >
                            <Card.Img variant="top" src="/img/icone_tournoi/poule_eliminatoire.svg" alt="tournoi avec poule + élminitation directe" />
                            <Card.Body>
                              <Card.Title className="text-center text-retract" >Poule + Élimination directe</Card.Title>
                            </Card.Body>
                          </Card>
                        </Col> */}
                      </Row>
                    </Container>
                    {/* <FormField name="terrain" margin="medium" htmlFor="text-input-terrain" label="Nombre de terrain :">
                      <TextInput placeholder="Nombre de Terrain" name="terrain" value={tournoiNbTerrain} size="large" pad="small" onChange={(nbt)=>setTournoiNbTerrain(Number(nbt.target.value))} number />        
                    </FormField> */}
                    {/* <Button  color="brand" pad="small" margin="small" onClick={tournoiInitialise}>Initialiser le tournoi</Button> */}
                    <Button variant="ble" disabled={tournoiType===""} onClick={tournoiInitialise}>{tournoiInit?"Modifier configuration tournoi":"Initialiser le tournoi"}</Button>
                </div>
              </Tab>
              <Tab eventKey="equipes" title="Équipes" disabled={disableTab[1]}>
                <div>
                  {/* <h4>Equipes</h4> */}
                    <Accordion defaultActiveKey="">
                      <Card>
                        <Card.Header>
                          <CustomToggle eventKey="0">
                            Ajouter des équipes
                          </CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          {
                          Object.keys(tournoiResultats).length>0?
                          <Card.Body className="row">
                            <p className="col-8 mx-auto">Des matchs ont déjà été générés, impossible de rajouter d'autres équipes. </p>
                          </Card.Body>
                          :<Card.Body className="row">
                            <FloatingLabel className="col-8 ps-0"  label="Nom équipes">
                                <Form.Control
                                    className={`text-capitalize`}
                                    type="text"
                                    id="ffi_nomEnfant"
                                    placeholder="Nom équipe"
                                    value={inputEquipe}
                                    onChange = {(event)=>{setInputEquipe(event.target.value)}}
                                />
                            </FloatingLabel>
                            <Button variant="ble text-white fw-bold" className="col-4" onClick={()=>{ajouterEquipe(inputEquipe)}}>
                              Ajouter
                            </Button>
                          </Card.Body>
                          }
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    {/* {
                      Object.keys(tournoiEquipes).length==0?
                      <p>Appuyer sur le "+" pour rajouter des équipes</p>
                      :<ListGroup variant="flush">
                          {
                            Object.keys(tournoiEquipes).map(x=><ListGroup.Item key={x} className="bg-ora text-white">{x}</ListGroup.Item>)
                          }
                        </ListGroup>
                    } */}
                    {
                      Object.keys(tournoiEquipes).length==0?
                      <p>Appuyer sur le "+" pour rajouter des équipes</p>
                      :<div className="tournoi_scroll">
                          {
                            Object.keys(tournoiEquipes).map(x=>
                              x==C.nobody?false
                              :<div key={`eq_${tournoiEquipes[x].uuid}`} className="bg-ora text-white my-3 mx-1 rounded row">
                                {/* <Button>
                                  <FontAwesomeIcon icon={faPencil} />
                                </Button> */}
                                <span className="col-10 py-2">{tournoiEquipes[x]["nom"]}</span>
                                <Button className="col-2" onClick={()=>supprimerEquipe(tournoiEquipes[x])}>
                                  <FontAwesomeIcon variant="dark" icon={faTrashCan} />
                                </Button>
                              </div>)
                          }
                        </div>
                    }
                </div>
              </Tab>
              <Tab eventKey="matchs" title="Matchs" disabled={disableTab[2]}>
                  {/* <h4>Matchs</h4> */}
                  {/* {
                    (tournoiInit?true:<div className="contCent"><p>Vous devez d'abord initialiser un tournoi en cliquant sur la clef anglaise</p></div>)
                  } */}
                  {/* {
                    (equipes.length<3?true:
                      // (
                      //   tournoiGenere?
                      //   <ListeMatchs />:
                      //   <Button label="Générer le tableau des matchs" />
                      // )
                      // <ListeMatchs equipes={equipes} typeTournoi={tournoiType} gs={gestionScore} ts={tableauScores} />
                    )
                  } */}
                  {
                    tournoiType==0?
                      <div className="row fulllargeur">
                        <div className="col-12 mb-3">
                          {
                            Object.keys(tournoiResultats).length<=(Object.keys(tournoiEquipes).length*Object.keys(tournoiEquipes).length-1)/2?
                            <Button className="ms-3" variant="outline-ora" onClick={()=>genererMatchs()}>
                              <FontAwesomeIcon icon={faLayerGroup} />
                              <span className="ms-3">{Object.keys(tournoiResultats).length==0?"Générer les matchs":"Générer les matchs retours (ou plus)"}</span>
                            </Button>
                            :false
                          }
                        </div>
                        <div className="col-12 tournoi_scroll px-0 ms-2">
                          {
                            Object.keys(tournoiEquipes).length>0 && Object.keys(tournoiResultats).length>0 ?
                            Object.keys(tournoiResultats).map(
                              (x,kx)=><div key={`rencontres_${x}`}>
                                {((kx)%(Object.keys(tournoiEquipes).length/2))==0 || kx==0 ?<div className="border-bottom mt-4 border-ligth text-muted text-center">Tour n° {kx==0?1:((kx)/(Object.keys(tournoiEquipes).length/2))+1}</div>:false}
                                <div  className={tournoiResultats[x].etat=="J"?"border border-ora text-ora row m-2 p-2":(!getNotNobody(tournoiResultats[x].equipes[0], tournoiResultats[x].equipes[1])?"bg-ora text-white row m-2 p-2":"bg-dark text-white row m-2 p-2")}>
                                  {
                                    tournoiResultats[x].equipes.map(
                                      (y,k)=>
                                      <Stack key={`rencontres_${x}_${y}`} className={"text-center fw-bold col-"+(tournoiResultats[x].equipes.length==2?"5 ":"3 ")}>
                                        <div className="">{Object.keys(tournoiEquipes).includes(y)?tournoiEquipes[y]["nom"]: ""}</div>
                                        {
                                          !getNotNobody(tournoiResultats[x].equipes[0], tournoiResultats[x].equipes[1])?
                                          <div className="">{tournoiResultats[x].scores==null?"0":tournoiResultats[x].scores[k]}</div>
                                          :false
                                        }
                                      </Stack>
                                      )
                                  }
                                  <div className="col-2">
                                    {
                                      !getNotNobody(tournoiResultats[x].equipes[0], tournoiResultats[x].equipes[1])?
                                      <Button variant="ble text-white" onClick={()=>{setTS1(tournoiResultats[x].scores[0]);setTS2(tournoiResultats[x].scores[1]);setModalScore(tournoiResultats[x].uuid)}}>
                                        <FontAwesomeIcon icon={faPencil} />
                                      </Button>
                                      :false
                                    }
                                    
                                  </div>
                                </div>
                              </div>
                            )
                            :false
                          }
                          <Modal
                            show={modalScore!=""}
                            onHide={()=>{
                              setModalScore("")
                            }}
                          >
                            <Modal.Header closeButton>
                            {
                              modalScore!=""?
                              <Modal.Title>{tournoiResultats[modalScore].equipes.map(z=>tournoiEquipes[z].nom+" ")}</Modal.Title>
                              :false
                            }
                            </Modal.Header>
                            <Modal.Body>
                              {
                                modalScore!="" && tournoiResultats[modalScore].equipes.length==2?
                                <div className="row">
                                  <span className="col-5 my-auto">{tournoiEquipes[tournoiResultats[modalScore].equipes[0]].nom}</span>
                                  <div className="col-5 my-2">
                                    <Form.Control 
                                      type="number"
                                      placeholder="Score"
                                      defaultValue={tempScore1}
                                      onChange = {(event)=>{setTS1(event.target.value)}}
                                    />
                                  </div>
                                  
                                  <span className="col-5 my-auto">{tournoiEquipes[tournoiResultats[modalScore].equipes[1]].nom}</span>
                                  <div className="col-5 my-2">
                                    <Form.Control
                                      className="col-5"
                                      type="number"
                                      placeholder="Score"
                                      defaultValue={tempScore2}
                                      onChange = {(event)=>{setTS2(event.target.value)}}
                                    />
                                  </div>
                                </div>
                                :<div>Faire la gestion pour des rencontres de plus de 2 équipes</div>
                              }
                            </Modal.Body>
                            <Modal.Footer>
                            {
                              modalScore!=""?
                              <Button variant="primary" onClick={()=>{
                                let t = {...tournoiResultats[modalScore]}
                                t.scores = [Number(tempScore1), Number(tempScore2)]
                                t.etat = "J"
                                modifierRencontre(t)
                                setModalScore("")
                              }}>Valider score de la rencontre</Button>
                              :false
                            }
                            </Modal.Footer>
                          </Modal>
                        </div>
                        <div className="col-12 bg-secondary text-white">
                          À venir module de recherche
                        </div>
                      </div>
                    :false
                  }
              </Tab>
              <Tab eventKey="classement" title="Classement" disabled={disableTab[3]} >
                <div className="row p-0" style={{height:"80vh", overflowY:"scroll"}}>
                <div className="text-center bg-ora text-white col-10 row px-0 mx-auto">
                        <div className="col-2 my-auto ">
                        <FontAwesomeIcon icon={faRankingStar} />
                        </div>
                        <div className="col-4 my-auto"><FontAwesomeIcon icon={faPeopleGroup} /></div>
                        <div className="col-2 my-auto"><FontAwesomeIcon icon={faMedal} /></div>
                        <div className="col-2 my-auto"><FontAwesomeIcon icon={faPeopleCarryBox} /></div>
                        <div className="col-2 my-auto"><FontAwesomeIcon icon={faPlusMinus} /></div>
                      </div>
                  {
                    classement.map((x,k)=>
                      <div key={`classement_${x.uuid}`} className="text-center col-10 row px-0 mx-auto">
                        <div className="col-2 my-auto text-ora fw-bold ">
                          {k+1}
                        </div>
                        <div className="col-4 my-auto">{x.nom}</div>
                        <div className="col-2 my-auto fw-bold">{x.points}</div>
                        <div className="col-2 my-auto">{x.nbmatch}</div>
                        <div className="col-2 my-auto">{x.diffpoints}</div>
                      </div>
                    )
                  }
                </div>
              </Tab>
            </Tabs>
            
        </div>
        )
    
  };
  
  export default TournoiComp;
  