import React, {useState, useEffect} from "react";
import { Outlet } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'


import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';


import '../App.css';


// const theme = deepMerge(grommet, {
//   global: {
//     colors: {
//       brand: "#228BE6",
//     },
//     font: {
//       family: "Roboto",
//       size: "14px",
//       height: "20px",
//     },
//   },
//   list: {
//     item:{
//       // border:false,
//       // extend: "font-size: 1.8em!important;"
//     }
//   }
// });

// const AppBar = (props) => (
//   <Header
//     background="brand"
//     pad={{ left: "medium", right: "small", vertical: "small" }}
//     elevation="small"
//     {...props}
//   />
// );



const Basic = () => {
  // const [dark, setDark] = useState(false);
  const [ecranMarron, setEcranMarron] = useState(true)
  const [showCanvas, setShowCanva] = useState(false)
  const [titreBandeau, setTitreBandeau] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEcranMarron(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    // <Grommet theme={theme} full themeMode={dark ? "dark" : "light"}>
      // <Page>
      <Container style={{padding:"0px", paddingTop:"80px"}}>
        {
          ecranMarron &&
          (window.location.href.substring(window.location.href.length-6)==":3000/"
          || window.location.href.substring(window.location.href.length-4)==".fr/")
          ?<div className="screenMarron"></div>:
          <Navbar bg="ora" variant="ora"fixed="top" className="justify-content-between" >
            <Container className="d-flex justify-content-between">
              <Navbar.Brand href="/">
                <img
                  src="/logoAP.svg"
                  width="40"
                  height="40"
                  className="d-inline-block align-top mx-2"
                  alt="AP"
                />
              </Navbar.Brand>
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Brand href="/AP_explication.pdf" target="_blank" className="text-white">
                  ?
                </Navbar.Brand>
              </Navbar.Collapse>
              {/* <Navbar.Brand href="/AP_explication.pdf" target="_blank" className="me-auto text-white"> */}
                {/* <a className="nav-link" href="/AP_explication.pdf" target="_blank">?</a> */}
                {/* {titreBandeau} */}
              {/* </Navbar.Brand> */}
              {/* <Nav className="me-auto">
                <Link to="mots">
                  <Button variant="dark" jusitfy="center" >Liste de mots</Button>
                </Link>
                <Link to="tournoi">
                  <Button variant="dark" jusitfy="center">Tournoi</Button>
                </Link>
                <Link to="scores">
                  <Button variant="dark" jusitfy="center">Score</Button>
                </Link>
              </Nav> */}
              {/* <Nav>
              </Nav> */}
              <Nav>
                
                {/* <Button variant="ora" className="text-white" onClick={()=>setShowCanva(true)}>
                  <FontAwesomeIcon icon={faBars} />
                </Button> */}

                <Offcanvas className="bg-ora text-white" show={showCanvas} onHide={()=>setShowCanva(false)} placement="end">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>AP</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="bg-ora text-white">
                      <strong>Activités</strong>
                      <ListGroup variant="flush">
                        <ListGroup.Item className="bg-ora text-white">
                          <Link to="mots" className="liennorm" onClick={()=>{setShowCanva(false)}} >
                            Liste de mots
                          </Link>
                        </ListGroup.Item>
                        {/* <ListGroup.Item className="bg-ora text-white">
                          <Link to="tournoi" className="liennorm" onClick={()=>{setShowCanva(false)}}>
                            Tournois
                          </Link>
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-ora text-white">
                          <Link to="scores" className="liennorm" onClick={()=>{setShowCanva(false)}}>
                            Scores
                          </Link>
                        </ListGroup.Item> */}
                        
                      </ListGroup>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-ora text-white">
                      <Link to="/" className="liennorm" onClick={()=>{setShowCanva(false)}} >
                        Campings
                      </Link>
                    </ListGroup.Item>
                    {/* <ListGroup.Item className="bg-ora text-white">Dapibus ac facilisis in</ListGroup.Item>
                    <ListGroup.Item className="bg-ora text-white">Morbi leo risus</ListGroup.Item>
                    <ListGroup.Item className="bg-ora text-white">Porta ac consectetur ac</ListGroup.Item> */}
                  </ListGroup>
                  </Offcanvas.Body>
                </Offcanvas>
              </Nav>
            </Container>
          </Navbar>
        }
        
        <Outlet context={{ setTitreBandeau }} />
        <div className="notificationGrillee">
          <ToastContainer className="text-norm" />
        </div>
      {/* </Page>
    </Grommet> */}
      </Container>

  );
};

export default Basic;