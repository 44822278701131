import React, { useState, useEffect } from 'react';
// import {
//   List,
//   PageContent,
//   Button,Grid, Nav, CheckBox, Card,CardBody, CardHeader, CardFooter,
//   Stack,Box,RangeSelector,Text
// } from "grommet";
import { useLoaderData, Link } from "react-router-dom";
// import Modal from "../components/modal";
import shuffle from '../jsTools/toolsArray';
// import { FormRefresh, List as ListIcon, Filter } from 'grommet-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faArrowRotateRight, faBackward} from '@fortawesome/free-solid-svg-icons'

import ListGroup from 'react-bootstrap/ListGroup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export async function loader({ params }) {
  //const contact = await getContact(params.contactId);
  const id_lm = params.id_lm
  return {id_lm};
}

// const useMountEffect = (fun) => useEffect(fun, [])


const ListeMots = () => {
  const { id_lm } = useLoaderData();

  const [showNewModal, setShowNewModal] = useState(false);
  const [valeurModal, setValeurModal] = useState("");
  const [data, setData] = useState([]);
  const [sampleData, setSampleData] = useState([])
  const [enfant, setEnfant] = useState(false)
  const [niveaux, setNiveaux] = useState([1,5])
  const [showFilter, setShowFilter] = useState(false);

  const filterData = (size=10)=>{
    var donnees = data.filter(x=> x["niveau"]<=niveaux[1]&&x["niveau"]>=niveaux[0])
    if(enfant)donnees = donnees.filter(x=>x["enfant"]==1)
    donnees = shuffle(donnees)
    donnees = donnees.slice(0,size)
    setSampleData(donnees.slice(0,size))
  }

  useEffect(() => {
    // console.log({"./data/"+id_lm+".json"})
    fetch("/donnees/"+id_lm+".json")
      .then((response) => response.json())
      .then((json) => {
        setData(json.map(x=>{return{"label":x[0],"niveau":x[1], "enfant":x[2]}}));
      });
    
  }, []);
  useEffect(() => {
    filterData()
  }, [data, enfant,niveaux]);
 
  const touchRange = (what, n)=>{
    if(what=="min" && niveaux[1]<n)setNiveaux([n,n])
    else if (what=="max" && niveaux[0]>n)setNiveaux([n,n])
    else if (what=="max") setNiveaux([niveaux[0],n])
    else setNiveaux([n,niveaux[1]])
  }

  // const handleClick = (itm) => {
  //   setValeurModal(itm["label"])
  //   setShowNewModal(true);
  // };
  // const fermerModal = ()=>{
  //   setShowNewModal(false)
  // }

  return(
    <div>
        <p className="m-2" >theme : {id_lm}</p>
          {/* <List
            primaryKey="label"
            // secondaryKey="percent"
            data={sampleData}
            alignSelf="center"
            // onClickItem={({ item, index }) => {console.log(item); return <BoutonMot texte={item.label}/> }}
            onClickItem={({ item, index }) => {handleClick(item)}}
            // action={(item, index) => (<Button label={item.name}/>)}
          />   */}
          <ListGroup variant="flush" className="bg-ble mt-5">
            {
              sampleData.map(x=><ListGroup.Item onClick={()=>{setValeurModal(x.label); setShowNewModal(true)}} className="bg-ble text-center text-white fs-3" key={x.label}>{x.label}</ListGroup.Item>)
            }
          </ListGroup>
          {/* {showNewModal && <Modal texte={valeurModal} onClose={fermerModal} />} */}
          <Modal
            fullscreen={true}
            show={showNewModal}
            onHide={() => setShowNewModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            
          >
            <Modal.Header className="bg-ora text-white" closeButton>
              
            </Modal.Header>
            <Modal.Body className="bg-ora text-white">
              <p className="text-center" style={{fontSize:"3.3em", marginTop:"30vh"}}>{valeurModal}</p>
            </Modal.Body>
          </Modal>
          <Offcanvas show={showFilter} onHide={()=>setShowFilter(!showFilter)} placement="bottom" >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Filtres</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form.Label>Niveau Min <span className="fw-bold">{niveaux[0]}</span></Form.Label>
              <Form.Range min="1" max="5" step="1" value={niveaux[0]} onChange={(e)=>touchRange("min",e.target.value)} />
              <Form.Label >Niveau Max <span className="fw-bold">{niveaux[1]}</span></Form.Label>
              <Form.Range min="1" max="5" step="1" value={niveaux[1]} onChange={(e)=>touchRange("max",e.target.value)} />
              {/* <Form.Check
                inline
                label="Que pour les enfants"
                name="group1"
                onChange = {(event)=>{setEnfant(event.target.checked)}}
                defaultValue={enfant}
                type="checkbox"
                id="check_enfant"
              /> */}
              
            </Offcanvas.Body>
          </Offcanvas>
          {/* <Card  className="filterMots" background="light-1">
            <CardHeader pad="medium">Filtres <Filter/></CardHeader>
            <CardBody pad="medium">
            <CheckBox
              checked={enfant}
              label="Que niveau enfant"
              pad="medium"
              onChange={(event) => setEnfant(event.target.checked)}
            />
              <span>Niveaux de difficulté des mots:</span>
              <Stack>

                <Box direction="row" justify="between">
                  {[0,1, 2, 3, 4, 5,6].map(value => (
                    <Box key={value} pad="small" border={false}>
                      <Text style={{ fontFamily: 'monospace' }}>
                        {value}
                      </Text>
                    </Box>
                  ))}
                </Box>
                <RangeSelector
                  direction="horizontal"
                  invert={false}
                  min={0}
                  max={6}
                  size="full"
                  round="small"
                  values={niveaux}
                  onChange={values => setNiveaux(values)}
                />
              </Stack>
            </CardBody>
            <CardFooter pad={{horizontal: "small"}} background="light-2">
              <Button
              icon={<Icons.Favorite color="red" />}
              hoverIndicator
              />
              <Button icon={<Icons.ShareOption color="plain" />} hoverIndicator />
            </CardFooter>
          </Card> */}
    {/* <Nav background="brand" pad="medium" basis="full" className='piedpage' as="footer">
      <Grid
        rows={[ 'flex']}
        columns={[ 'flex']}
        
        areas={[
          { name: 'refresh', start: [0, 0], end: [0, 0] },
          { name: 'golist', start: [1, 0], end: [1, 0] },
        ]}
        >
        <Button onClick={()=>{filterData()}} gridarea="refresh">
          <FormRefresh size="large"/>
        </Button>
        <Link to="/mots" gridarea="golist">
          <Button >
            <ListIcon size="large"/>
          </Button>
        </Link>
      </Grid>
    </Nav> */}
    <Navbar fixed="bottom"  bg="dark" className="text-white nav-fill d-flex justify-content-around" >
        <Button onClick={()=>setShowFilter(!showFilter)} variant="dark" >
          <FontAwesomeIcon icon={faFilter} />
        </Button>
        <Button size="lg" variant="dark" onClick={()=>{filterData()}}>
          <FontAwesomeIcon icon={faArrowRotateRight} />
        </Button>
        <Link to="/mots" gridarea="golist">
          <Button size="lg" variant="dark" >
            <FontAwesomeIcon icon={faBackward} style={{color:"#FFFFFF"}}/>
          </Button>
        </Link>
      </Navbar>
    </div>
    )
  };
  
  export default ListeMots;
  