import React, {useState} from "react";
import {
  Button,
  PageContent,
  Box, DropButton,TextInput,
  Form,FormField
} from "grommet";
import Equipe from "../components/scoreEquipe";
import {AddCircle as AddIcon, Close} from "grommet-icons"


const Scores = () => {
  const [equipes, setEquipes] = useState([]);
  // const [tricks1, setTricks] = useState(false);
  

  // const equipes = []
  const couleurslabel = [["status-critical","Rouge1"],["#B30000","Rouge2"],["status-warning","OrangeJaune"],["status-ok","Vert1"],["status-disabled","Gris1"],["graph-0","VertFlashy"],["graph-1","VertFoncé"],["graph-2","Violet1"],["graph-3","Bleu1"],["graph-4","Rouge3"],["accent-2","Rose1"],["accent-3","Bleu2"],["accent-4","Jaune2"],["dark-4","Gris2"]]
  
  const addEquipe = (nom, score=0) => {
    console.log("addequipe")
    console.log(equipes)
    let tempEquipes = [...equipes]
    let couleur = couleurslabel[Math.floor(Math.random() * couleurslabel.length)][0];
    tempEquipes.push(<Equipe couleur={couleur} nom={nom} key={equipes.length+1} score={score}/>)
    console.log(tempEquipes)
    setEquipes(tempEquipes)
  }
  return (
        <PageContent>
          {(equipes.length===0?
              <div className="contCent"><p>Bouton "+" pour ajouter une équipe</p></div>:
              equipes
              )}
          <DropButton
          className="stickyBG"
          dropAlign={{bottom:"bottom",left:"right" }}
            dropContent={
                <Box pad="large" background="light-2" >
                  {/* <Button className="fermetureDrop" onClick={()=>{console.log("ciufyt");document.querySelector('#logoAP').click();}}><Close size="medium" /></Button> */}
                  <Form
                    // value={value}
                    // onChange={nextValue => setValue(nextValue)}
                    // onReset={() => setValue({})}
                    // onSubmit={({ value }) => {console.log(value);addEquipe(value.nom,value.score);return false}}
                    onSubmit={(event)=>{console.log(event.value);addEquipe(event.value.nom,event.value.score);event.target.reset();}}
                    >
                    <FormField name="nom" htmlFor="text-input-nom" label="Nom équipe">
                      <TextInput id="text-input-nom" name="nom" />
                    </FormField>
                    <FormField name="score" htmlFor="text-input-score" label="Score de départ">
                      <TextInput id="text-input-score" name="score" defaultValue={0} numbe />
                    </FormField>

                    <Box direction="row" gap="medium">
                      <Button type="submit" primary label="Créer l'équipe"  />
                      <Button type="reset" label="Effacer" />
                    </Box>
                  </Form>
                </Box>
              }
            >
              <AddIcon size="large" color="brand"/>
          </DropButton>
        </PageContent>
  );
};

export default Scores;