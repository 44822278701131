import React, {useState,useEffect,useRef } from "react";

import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera,faTent, faCakeCandles, faStethoscope, faBandage,faHandDots,faComment,faDoorOpen} from '@fortawesome/free-solid-svg-icons'

import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';

import Dropdown from 'react-bootstrap/Dropdown';
import Stack from 'react-bootstrap/Stack';


import ModalContact from "./modalContact";

export default function CarteProTableEnfantPresence({donnees, cat="absent", fctAction, action}){
    const [sejFormatDate, setSejFormatDate] = useState([]);
    const [ddnFormatDate, setDdnFormatDate] = useState("");
    const [age, setAge] = useState(0)
    // const [targetCont, setTargetCont] = useState(null);

    const coul={
      "absent" : "secondary",
      "present" : "ora",
      "parti" : "ble"
    }
    // const notif_gen = (message)=>toast.error(message,
    //     {position: "top-right",autoClose: 5000,hideProgressBar: false,
    //     closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    // })

    useEffect(()=>{
      // const date = new Date(donnees.dateDerModif);
      let ddnDate = new Date(donnees.ddn)
      let debDate = new Date(donnees.sejour_debut)
      let finDate = new Date(donnees.sejour_fin)
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      // setFormatDate(date.toLocaleString("fr-FR", options))
      setDdnFormatDate(ddnDate.toLocaleString("fr-FR", options))
      setAge(Math.floor((new Date() - ddnDate) / (365.25 * 24 * 60 * 60 * 1000)))
      setSejFormatDate([
        debDate.toLocaleString("fr-FR", options), 
        finDate.toLocaleString("fr-FR", options)
      ])

    },[])
    
    
    // useEffect(()=>{
    //       console.log("DONNNNNNEEES")
    //       console.log(donnees)
    //   },[donnees])
    

    return(
        <Card bg={coul[cat]} text="white" className="my-2" id={`cptep_${cat.substring(0,3)}_${donnees.uuid}`}>
          {Object.keys(donnees).length < 1 ? (
            <Card.Body>
              <ListGroup>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
              </ListGroup>
            </Card.Body>
          ) : (
            <Card.Body className="row pe-2">
                <div className="col-8 row">
                  <span className="col-12 col-xl-2 col-lg-2 col-md-6"><strong>{donnees.prenom}</strong> <span>{donnees.nom}</span></span>
                  <div className="col-8 col-xl-2 col-lg-2 col-md-6"> <FontAwesomeIcon icon={faCakeCandles} /> <span style={{fontSize:"0.7em"}}>{ddnFormatDate}</span> <br/>(<strong>{age}</strong> ans)</div>
                  <div className="col-4 col-xl-2 col-lg-2 col-md-6"> <FontAwesomeIcon icon={faTent} /> {donnees.emplacement}</div>
                  <div className="mt-1 col-12" style={{fontSize:"0.6em"}}>
                    <FontAwesomeIcon className="me-2 px-0" icon={faDoorOpen} />
                    <span>{donnees.sortie=="A"?"Sort accompagné(e)":'Sort seul(e)'}</span>
                  </div>
                  {
                    donnees.allergie ?
                    <div className="mt-1 col-12" style={{fontSize:"0.6em"}}>
                      <FontAwesomeIcon className="me-2 px-0" icon={faHandDots} />
                      <span>{donnees.commentaireAllergie}</span>
                    </div>
                    :false
                  }
                  {
                    donnees.traitementMedical ?
                    <div className="mt-1 col-12" style={{fontSize:"0.6em"}}>
                      <FontAwesomeIcon className="me-2 px-0" icon={faStethoscope} />
                      <span>{donnees.commentaireMedical}</span>
                    </div>
                    :false
                  }
                  {
                    donnees.autorisationInterMed ?
                    false:<div className="mt-1 col-12" style={{fontSize:"0.6em"}}>
                      <FontAwesomeIcon className="me-2 px-0" icon={faBandage} />
                      <span>Pas d'inter. médicale</span>
                    </div>
                  }
                  {
                    donnees.acceptePhoto ?
                    false:<div className="mt-1 col-12" style={{fontSize:"0.6em"}}>
                      <FontAwesomeIcon className="me-2 px-0" icon={faCamera} />
                      <span>Pas de photo</span>
                    </div>
                  }
                  {
                    donnees.commentaire.length==0 ?
                    false:<div className="mt-1 col-12" style={{fontSize:"0.6em"}}>
                      <FontAwesomeIcon className="me-2 px-0" icon={faComment} />
                      <span>{donnees.commentaire}</span>
                    </div>
                  }
                  {/* <div className="col-4 col-xl-2 col-lg-2 col-md-6">
                    {donnees.uuid.substring(0,4)}
                  </div> */}
                </div>
                <div className="col-4">
                  <Stack gap={2}>
                    <ModalContact donnees={donnees} className=""/>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        Action
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Header>Mettre l'enfant</Dropdown.Header>
                        {
                          cat=="absent" || cat=="parti"?
                            <Dropdown.Item eventKey={"present"} onClick={()=>fctAction("present",cat, donnees.uuid)}>Présent</Dropdown.Item>
                          :<div>
                            <Dropdown.Item eventKey={"absent"} onClick={()=>fctAction("absent",cat, donnees.uuid)}>Absent</Dropdown.Item>
                            <Dropdown.Item eventKey={"parti"} onClick={()=>fctAction("parti",cat, donnees.uuid)}>Parti</Dropdown.Item>
                          </div>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </Stack>
                  
                   
                  {/* <div className="col-xl-2 col-lg-2 col-md-6"><FontAwesomeIcon icon={faEnvelope} /> {donnees.email}</div> */}
                  {/* <div className="col-12 col-xl-2 col-lg-2 col-md-4 col">
                    <FormEnfant jeton={JETON} actionM="m" camping={uuidCont} element={donnees} /> 
                      {
                        ROLE>1
                        ?<FormEnfant jeton={JETON} actionM="d" camping={uuidCont} element={donnees} /> 
                        :false
                      }
                  </div> */}
                </div>
               
               
               <small text="gray" className="minicoindroit text-end">{sejFormatDate[0]} ⇒ {sejFormatDate[1]}</small>
               
            </Card.Body>
          )}
          {/* <ToastContainer className="text-norm notificationGrillee" /> */}
        </Card>
      )
  };
