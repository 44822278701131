// import { Header } from "grommet";
import React, {useState}  from "react";
// import { useSelector, useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from "react-bootstrap/FloatingLabel";

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil,faTrash,faPlus } from '@fortawesome/free-solid-svg-icons'

// import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
import recuperer from '../../jsTools/toolsFetch'

var donneesDefault = {
    "uuid":"",
    "titre":"",
    // "structure":"",
    // "telephone":"",
    // "email":"",
}

// action = m || d || c (modify, delete, create)
export default function FormScore({jeton,actionM="m",camping,element=donneesDefault}){
    const [modalForm, setModalForm] = useState(false);
    
    const [f_titre, setFTitre] = useState(element["titre"]);
    
    
    const noire = false;
    const nomclasse = noire ? "bg-dark text-white" : ""

    // const [f_prenom, setFPrenom] = useState(element["prenom"]);
    // const [f_tel, setFTel] = useState(element["telephone"]);
    // const [f_email, setFEmail] = useState(element["email"]);
    // const [f_nom, setFNom] = useState("");
    // const [f_prenom, setFPrenom] = useState("");
    // const [f_tel, setFTel] = useState("");
    // const [f_email, setFEmail] = useState("");

    // useEffect(()=>{
    // //   JETON = useSelector((state) => state.jeton.access)
    // console.log("m  rco")
    // // dispatch(clearJetons());
    // }, [changeJeton])

    // const JETON = useSelector((state) => state.jeton.access)
    // const STATUTUSER = useSelector((state) => state.statutUser.access)
    // const dispatch = useDispatch()
    const act = (m,d,c)=>{
        // console.log(`actionM ${actionM}`)
        // console.log(`camping ${camping}`)
        // console.log(`jeton ${jeton}`)
        // console.log(actionM==="m")
        if(actionM=="m")return m
        else if (actionM=="d") return d
        else return c
    }
    const notif_gen = (message)=>toast.error(message,
        {position: "top-right",autoClose: 5000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_norm = (message)=>{
        toast(message,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}
    const formCM = ()=>{
        return <Form>
                <FloatingLabel className="text-2" label="Titre du tableau des scores">
                    <Form.Control
                        className={`${nomclasse} text-capitalize`}
                        type="text"
                        id="ft_titre"
                        placeholder="Titre du tableau des scores"
                        defaultValue={f_titre}
                        onChange = {(event)=>{setFTitre(event.target.value)}}
                        aria-describedby="ft_titre_aide"
                    />
                </FloatingLabel>
            </Form>
    }
    // const handleForm = ()=>{
    //     recuperer()
    // }
    const modifier = ()=>{
        recuperer(
            `api/peluche/${camping}/scores/${element.uuid}/`,
            jeton,
            (rep)=>{
                setModalForm(false)
                notif_norm(`Modification de ${element.titre} confirmée`)
                setTimeout(() => {
                    window.location.reload();
                  }, "1000")
            },
            (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
            "PATCH",
            {
                "titre":f_titre,
            }
        )
    }
    const supprimer = ()=>{
        recuperer(
            `api/peluche/${camping}/scores/${element.uuid}/`,
            jeton,
            (rep)=>{
                if(rep?.statut??false){
                    console.log("204 confirmation suppression")
                }
                setModalForm(false)
                notif_norm(`Suppression de ${element.titre} confirmée`)
                setTimeout(() => {
                    window.location.reload();
                  }, "1000")
            },
            (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
            "DELETE",
        )
    }
    const creer = ()=>{
        recuperer(
            `api/peluche/${camping}/scores/`,
            jeton,
            (rep)=>{
                setModalForm(false)
                notif_norm(`Création de ${element.titre} confirmée`)
                setTimeout(() => {
                    window.location.reload();
                  }, "1000")
            },
            (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
            "POST",
            {
                "titre":f_titre
            }
        )
    }
    
    return (
      <div>
            <div>
                <Button variant={act("outline-light","outline-light","dark")} onClick={()=>{setModalForm(!modalForm)}}>
                    {act(
                        <FontAwesomeIcon icon={faPencil} />,
                        <FontAwesomeIcon icon={faTrash} />,
                        <FontAwesomeIcon icon={faPlus} />
                        )}
                </Button>
                <Modal show={modalForm} onHide={()=>{setModalForm(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>{act("Modifier","Supprimer","Créer")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                        act(
                            formCM(),
                            <div>
                                <p>Confirmer la suppression de <strong>{element.titre} du {element.dateCreation}</strong></p>
                                {/* <Button onClick={supprimer}>
                                    Supprimer
                                </Button> */}
                            </div>,
                            formCM()
                        )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            act(
                                <Button variant="secondary" onClick={modifier}> Modifier le score</Button>,
                                <Button variant="secondary" onClick={supprimer}> Supprimer le score </Button>,
                                <Button variant="secondary" onClick={creer}> Créer le score</Button>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        {/* <ToastContainer className="text-norm notificationGrillee" /> */}
      </div>
    );
  };

