import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useSearchParams  } from "react-router-dom";

import { toast } from 'react-toastify';

import { Vortex } from  'react-loader-spinner'

import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

import { setUuid } from '../store/statutUserSlice'
import { setJetonAccess,setJetonRefresh } from '../store/jetonSlice'

import Connexion from "../components/auth/connexion";
import CartePro from "../components/utilisateur/cartePro"
import CarteCamping from "../components/utilisateur/carteCamping"
import CarteAction from "../components/utilisateur/carteAction";

import recuperer from '../jsTools/toolsFetch'


const Accueil = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [donneesU, setDonneesU] = useState({});
  const [donneesS, setDonneesS] = useState("");
  const [showWait, setShowWait] = useState(false)
  const [infocharge, setInfocharge] = useState(false);

  const JETON = useSelector((state) => state.jeton.access)
  const dispatch = useDispatch()

  let [searchParams, setSearchParams] = useSearchParams();
  const para = useParams()

  const notif_gen = (message, temps=5000)=>toast.error(message,
      {position: "top-right",autoClose: temps,hideProgressBar: false,
      closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })

  useEffect(() => {
    // Hide the splash screen after 2 seconds
    const timeout = setTimeout(() => {
      setShowSplash(false);
    }, 1000);
    let forcelog = searchParams.get("force_login")
    if(![null, "null", undefined, "undefined",""].includes(forcelog)&&forcelog==1){
      dispatch(setJetonAccess(""))
      dispatch(setJetonRefresh(""))
      dispatch(setUuid(""))
      setShowWait(false);
      notif_gen(`Session de connexion révolue. Veuillez vous reconnecter.`, false)
    }
    // Clean up the timeout on component unmount
    return () => clearTimeout(timeout);
  }, []);

  useEffect(()=>{
    console.log("useeffectjeton")
    if(JETON.length>60 && !infocharge){
      console.log(JETON)
      recuperer("api/self", JETON, 
          (rep)=>{
            setShowWait(false);
            setDonneesU(rep);
            dispatch(setUuid(rep.uuid))
            // setDonneesS(JSON.stringify(rep))
            setInfocharge(true)
          },
          (e)=>{setShowWait(false);notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`, false)}
          )
      setInfocharge(true)
      
    }
    
  },[JETON])

  const rec_self = ()=>{
    console.log(JETON)
    console.log("REC SELF")
    recuperer("api/self", JETON, 
        (rep)=>{setDonneesU(rep);setDonneesS(JSON.stringify(rep))},
        (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)}
        )   
  }

  return (
      <div className={JETON.length<60?"bg-ble text-white row position-absolute top-50 start-50 translate-middle":"fulllargeur"} style={{paddingTop:"10px"}}>
          <Modal show={showSplash} fullscreen={true} >
              <Modal.Body className="nopad d-flex justify-content-center align-items-center" style={{backgroundColor:"#a3aa9a"}}>
                <Image src="/img/slayer1.jpg" fluid />
              </Modal.Body>
            </Modal> 
            <Modal show={showWait} fullscreen={true} >
              <Modal.Body className="nopad d-flex justify-content-center align-items-center" style={{backgroundColor:"#a3aa9a55"}}>
                <div>
                    <p>Connexion ...</p>
                    <Vortex
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="vortex-loading"
                      wrapperStyle={{}}
                      wrapperClass="vortex-wrapper"
                      colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                    />
                  </div>
              </Modal.Body>
            </Modal> 
            {
              JETON.length<60?
              <Connexion waitingScreen={(b)=>setShowWait(b)} />
              :
              <div className="">
                {
                  Object.keys(donneesU).length==0
                  ?<p>Connexion en cours...</p>
                  :<div className="ps-3 fulllargeur">
                    <div className="row col-12">
                      {
                        (Object.keys(donneesU).includes("campings")
                          ?donneesU.campings.map(x=><CarteCamping key={x.uuid} donnees={x} className="bg-ora" />)
                          :false)
                      }
                    </div>
                    <div className="row col-12">
                      {
                        (donneesU.role.includes("gestionnaire") || donneesU.role.includes("responsable")
                          ?<CarteAction donnees={donneesU} role={donneesU.role.includes("gestionnaire")?"gestionnaire":"responsable"}/>
                          :false)
                      }
                    </div>
                    
                    
                    <CartePro className="" donnees={donneesU} />
                    
                  </div>
                }
              </div>
            }
        </div>
          
  );
};

export default Accueil;