import React, {useState} from "react";
// import { useSelector, useDispatch } from 'react-redux'

import { toast } from 'react-toastify';


import AskAccess from "../components/auth/askAccess";


const DemandeAcces = () => {
  // const [showSplash, setShowSplash] = useState(true);
  // const [donneesU, setDonneesU] = useState({});
  // const [donneesS, setDonneesS] = useState("");
  // const [infocharge, setInfocharge] = useState(false);

  // const JETON = useSelector((state) => state.jeton.access)
  // const dispatch = useDispatch()



  // const rec_self = ()=>{
  //   console.log(JETON)
  //   recuperer("api/self", JETON, 
  //       (rep)=>{setDonneesU(rep);setDonneesS(JSON.stringify(rep))},
  //       (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)}
  //       )   
  // }

  return (
      <div className="fulllargeur" style={{paddingTop:"10px"}}>
          <AskAccess />
        </div>
          
  );
};

export default DemandeAcces;