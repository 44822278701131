// import { Header } from "grommet";
import React, {useState}  from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil,faTrash,faPlus } from '@fortawesome/free-solid-svg-icons'

// import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
import recuperer from '../../jsTools/toolsFetch'

var donneesDefault = {
    "uuid":"",
    "nom":"",
    "prenom":"",
    // "structure":"",
    "telephone":"",
    "email":"",
}

// action = m || d || c (modify, delete, create)
export default function FormAnims({jeton,actionM="m",camping,element=donneesDefault}){
    const [modalForm, setModalForm] = useState(false);
    
    const [f_nom, setFNom] = useState(element["nom"]);
    const [f_prenom, setFPrenom] = useState(element["prenom"]);
    const [f_tel, setFTel] = useState(element["telephone"]);
    const [f_email, setFEmail] = useState(element["email"]);
    // const [f_nom, setFNom] = useState("");
    // const [f_prenom, setFPrenom] = useState("");
    // const [f_tel, setFTel] = useState("");
    // const [f_email, setFEmail] = useState("");

    // useEffect(()=>{
    // //   JETON = useSelector((state) => state.jeton.access)
    // console.log("m  rco")
    // // dispatch(clearJetons());
    // }, [changeJeton])

    // const JETON = useSelector((state) => state.jeton.access)
    // const STATUTUSER = useSelector((state) => state.statutUser.access)
    // const dispatch = useDispatch()
    const act = (m,d,c)=>{
        // console.log(`actionM ${actionM}`)
        // console.log(`camping ${camping}`)
        // console.log(`jeton ${jeton}`)
        // console.log(actionM==="m")
        if(actionM=="m")return m
        else if (actionM=="d") return d
        else return c
    }
    const notif_gen = (message)=>toast.error(message,
        {position: "top-right",autoClose: 5000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_norm = (message)=>{
        toast(message,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}
    const formCM = ()=>{
        return <Form>
                <Form.Group className="mb-3" controlId="modifFormNom">
                    <Form.Text className="text-muted" style={{"fontSize":"12px"}}>
                        Nom
                    </Form.Text>
                    <Form.Control 
                        type="text" 
                        placeholder="Nom Anim" 
                        defaultValue={f_nom}
                        onChange = {(event)=>{setFNom(event.target.value)}}
                        />
                </Form.Group>
                <Form.Group className="mb-3" controlId="modifFormPrenom">
                    <Form.Text className="text-muted" style={{"fontSize":"12px"}}>
                        Prénom
                    </Form.Text>
                    <Form.Control 
                        type="text" 
                        placeholder="Prénom Anim" 
                        defaultValue={f_prenom}
                        onChange = {(event)=>{setFPrenom(event.target.value)}}
                        />
                </Form.Group>
                <Form.Group className="mb-3" controlId="modifFormTel">
                    <Form.Text className="text-muted" style={{"fontSize":"12px"}}>
                        Téléphone
                    </Form.Text>
                    <Form.Control 
                        type="tel" 
                        placeholder="0605040302" 
                        defaultValue={f_tel}
                        onChange = {(event)=>{setFTel(event.target.value)}}
                        />
                </Form.Group>
                <Form.Group className="mb-3" controlId="modifFormEmail">
                    <Form.Text className="text-muted" style={{"fontSize":"12px"}}>
                        Email
                    </Form.Text>
                    <Form.Control
                        type="email" 
                        placeholder="email@anim.fr" 
                        defaultValue={f_email}
                        onChange = {(event)=>{setFEmail(event.target.value)}}
                        />
                </Form.Group>
            </Form>
    }
    // const handleForm = ()=>{
    //     recuperer()
    // }
    const modifier = ()=>{
        recuperer(
            `api/prout/${camping}/anims/${element.uuid}/`,
            jeton,
            (rep)=>{
                setModalForm(false)
                notif_norm(`Modification de ${element.prenom} ${element.nom} confirmée`)
                setTimeout(() => {
                    window.location.reload();
                  }, "1000")
            },
            (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
            "PATCH",
            {
                "telephone":f_tel,
                "email":f_email,
                "prenom":f_prenom,
                "nom":f_nom
            }
        )
    }
    const supprimer = ()=>{
        recuperer(
            `api/prout/${camping}/anims/${element.uuid}/`,
            jeton,
            (rep)=>{
                if(rep?.statut??false){
                    console.log("204 confirmation suppression")
                }
                setModalForm(false)
                notif_norm(`Suppression de ${element.prenom} ${element.nom} confirmée`)
                setTimeout(() => {
                    window.location.reload();
                  }, "1000")
            },
            (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
            "DELETE",
        )
    }
    const creer = ()=>{
        recuperer(
            `api/prout/${camping}/anims/`,
            jeton,
            (rep)=>{
                setModalForm(false)
                notif_norm(`Création de ${element.prenom} ${element.nom} confirmée`)
                setTimeout(() => {
                    window.location.reload();
                  }, "1000")
            },
            (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
            "POST",
            {
                "telephone":f_tel,
                "email":f_email,
                "prenom":f_prenom,
                "nom":f_nom
            }
        )
    }
    // const [tournoi, setTournoi] = useState(null)
    // const [nbTour, setNbTour] = useState(3)
    
    
    // useEffect(()=>{
    // //   JETON = useSelector((state) => state.jeton.access)
    // console.log("m  rco")
    // // dispatch(clearJetons());
    // }, [changeJeton])
    // const size = useContext(ResponsiveContext);
    return (
      <div>
            <div>
                <Button variant={act("outline-light","outline-light","dark")} onClick={()=>{setModalForm(!modalForm)}}>
                    {act(
                        <FontAwesomeIcon icon={faPencil} />,
                        <FontAwesomeIcon icon={faTrash} />,
                        <FontAwesomeIcon icon={faPlus} />
                        )}
                </Button>
                <Modal show={modalForm} onHide={()=>{setModalForm(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>{act("Modifier","Supprimer","Créer")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                        act(
                            formCM(),
                            <div>
                                <p>Confirmer la suppression de <strong>{element.prenom} - {element.nom}</strong></p>
                                {/* <Button onClick={supprimer}>
                                    Supprimer
                                </Button> */}
                            </div>,
                            formCM()
                        )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            act(
                                <Button variant="secondary" onClick={modifier}> Modifier l'anim</Button>,
                                <Button variant="secondary" onClick={supprimer}> Supprimer l'anim </Button>,
                                <Button variant="secondary" onClick={creer}> Créer l'anim</Button>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        {/* <ToastContainer className="text-norm notificationGrillee" /> */}
      </div>
    );
  };

