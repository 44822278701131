// import { Header } from "grommet";
import React, {useEffect,useState}  from "react";
// import { useSelector, useDispatch } from 'react-redux'
// import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { toast } from 'react-toastify';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser,faDoorOpen } from '@fortawesome/free-solid-svg-icons'

// import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
// import S from "../../conf/serveur.json"
import recuperer from "../../jsTools/toolsFetch";
import { Vortex } from "react-loader-spinner";



export default function AskAccess(){
    const [fait, setFait] = useState(false);
    const [waitingscreen, setWaintingScreen] = useState(false);
    const [form_mail, setFormMail] = useState("");
    const [form_nom, setFormNom] = useState("");
    const [form_prenom, setFormPrenom] = useState("");
    const [form_camping, setFormCamping] = useState("");
    const [form_statut, setFormStatut] = useState("");
    // const [form_p, setFormP] = useState("");
    // const [changeJeton, setChangeJeton] = useState(true);


    // const JETON = useSelector((state) => state.jeton.access)
    // const STATUTUSER = useSelector((state) => state.statutUser.access)
    // const dispatch = useDispatch()

    const notif_gen = (message, temps=5000)=>toast.error(message,
        {position: "top-right",autoClose: temps,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_connecte = ()=>{
        // console.log("notif connecté")
        toast(`Demande envoyée, une réponse vous sera donnée sur le mail ${form_mail}`,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}
    const effaceJeton = ()=>{

    }
    const handleForm = ()=>{
        if(form_nom.trim().length<1)notif_gen("Obligation de saisir un nom", false)
        else if(form_prenom.trim().length<1)notif_gen("Obligation de saisir un prénom", false)
        else if(form_camping.trim().length<6)notif_gen("Obligation de saisir un nom de camping", false)
        else if(form_statut=="")notif_gen("Obligation de saisir une fonction au sein du camping", false)
        else if(form_mail.length<1 || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form_mail)))notif_gen("Obligation de saisir un email valide", false)
        else {
            setWaintingScreen(true)
            recuperer(
                `api/pouliche/demande_acces`,
                "",
                (rep)=>{
                    console.log("reussie")
                    console.log(rep)
                    if(rep?.erreur){
                        notif_gen(rep.erreur)
                    }
                    else if(rep?.succes){
                        console.log("succes")
                        notif_connecte()
                        setFait(true)
                        setWaintingScreen(false)
                    }
                    else{
                        notif_gen("")
                    }
                },
                (err)=>{
                    notif_gen(err)
                    console.log("coucou")
                    console.error(err)
                    setWaintingScreen(false)
                },
                "POST",
                {
                    "mail":form_mail,
                    "prenom":form_prenom,
                    "nom":form_nom,
                    "camping":form_camping,
                    "statut":form_statut,
                }
            )
        }
    }
    // const [tournoi, setTournoi] = useState(null)
    // const [nbTour, setNbTour] = useState(3)
    
    
    // useEffect(()=>{
    // //   JETON = useSelector((state) => state.jeton.access)
    // console.log("m  rco")
    // // dispatch(clearJetons());
    // }, [changeJeton])
    // const size = useContext(ResponsiveContext);
    return (               
        <Modal.Dialog style={{maxWidth:"600px", minWidth:"300px"}} >
            <Modal.Header >
                <Modal.Title className="ms-4" >Demande d'accès</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    fait?
                    <p className="m-4 bg-white p-3 rounded">
                        Merci pour cette demande.<br/>
                        Nous vous contacterons sur le mail {form_mail} et vous fournirons des identifiants.
                    </p>
                    :<Form>
                        <p className="m-4">Remplissez le formulaire ci-dessous, nos équipes vous contacterons pour vous donner accès à l'ensemble de notre plateforme gratuitement, pour la saison estivale 2024.</p>
                        <FloatingLabel label="Adresse email" className="m-4 text-ora fw-bold" >
                            <Form.Control 
                                type="email" 
                                placeholder="Email"
                                defaultValue={form_mail}
                                onChange = {(event)=>{setFormMail(event.target.value)}}
                                />
                        </FloatingLabel>
                        <FloatingLabel label="Prénom" className="m-4 text-ora fw-bold" >
                            <Form.Control 
                                type="text" 
                                placeholder=""
                                defaultValue={form_prenom}
                                onChange = {(event)=>{setFormPrenom(event.target.value)}}
                                />
                        </FloatingLabel>
                        <FloatingLabel label="Nom" className="m-4 text-ora fw-bold" >
                            <Form.Control 
                                type="text" 
                                placeholder=""
                                defaultValue={form_nom}
                                onChange = {(event)=>{setFormNom(event.target.value)}}
                                />
                        </FloatingLabel>
                        <FloatingLabel label="Nom du camping/club vacances" className="m-4 text-ora fw-bold" >
                            <Form.Control 
                                type="text" 
                                placeholder=""
                                defaultValue={form_camping}
                                onChange = {(event)=>{setFormCamping(event.target.value)}}
                                />
                        </FloatingLabel>
                        <FloatingLabel className="m-4 text-ora fw-bold" label="Votre rôle">
                            <Form.Select 
                                onChange={(event)=>{setFormStatut(event.target.value)}}
                                defaultValue={form_statut}
                                // aria-label={gererForm?"Sort seul(e)":libelleTrad["quest_sortie"][trad]}
                                >
                                {/* <option>Open this select menu</option> */}
                                <option></option>
                                <option value="resp">Responsable animation</option>
                                <option value="dir">Direction de camping/club vacances</option>
                                <option value="anim_e">Anim enfant</option>
                                <option value="anim_g">Anim général</option>
                                <option value="autre">Autre</option>
                                {/* <option value="3">Three</option> */}
                            </Form.Select>
                        </FloatingLabel>
                    </Form>
                }
            
            </Modal.Body>
            <Modal.Footer>
                {
                    fait?
                    false:
                    <Button variant="ora" className="text-white fw-bolder me-4" onClick={handleForm}>Demander un accès</Button>
                }
            </Modal.Footer>
            <Modal show={waitingscreen} backdrop="static" keyboard={false}>
              <Modal.Body>
                <p>Envoi de la demande</p>
                <Vortex
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="vortex-loading"
                    wrapperStyle={{}}
                    wrapperClass="vortex-wrapper"
                    colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                  />
                </Modal.Body>
            </Modal>
        </Modal.Dialog>
    );
  };

