// import { Header } from "grommet";
import React, {useState}  from "react";
// import { useSelector, useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil,faTrash,faPlus } from '@fortawesome/free-solid-svg-icons'

// import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
import recuperer from '../../jsTools/toolsFetch'
import FormFicheInfoEnfantPro from "./formFicheInfoEnfantPro";

var donneesDefault = {
        "nom": "",
        "prenom": "",
        "ddn": null,
        "sejour_debut": null,
        "sejour_fin": null,
        "emplacement": "",
        "acceptePhoto": false,
        "sortie": false,
        "commentaireAllergie": "",
        "traitementMedical": false,
        "commentaireMedical": "",
        "autorisationInterMed": false,
        "autorisationRepresentantLegal": false,
        "commentaire": "",
        "representantLegal": null,
        "club": null,
        "autresContacts": []
    }

// action = m || d || c (modify, delete, create)
export default function FormEnfants({jeton,actionM="m",camping,element=donneesDefault}){
    const [modalForm, setModalForm] = useState(false);
    
    const [formDonnees, setFormDonnees] = useState(element)
    // const [f_nom, setFNom] = useState(element["nom"]);
    // const [f_prenom, setFPrenom] = useState(element["prenom"]);
    // const [f_tel, setFTel] = useState(element["telephone"]);
    // const [f_email, setFEmail] = useState(element["email"]);
    // const [f_nom, setFNom] = useState("");
    // const [f_prenom, setFPrenom] = useState("");
    // const [f_tel, setFTel] = useState("");
    // const [f_email, setFEmail] = useState("");
    const noire = true;
    const nomclasse = noire ? "bg-dark text-white" : ""

    // const valeurFormDonnees = (clef, valeur)=>{
    //     setFormDonnees((prevValues) => ({
    //             ...prevValues,
    //             [clef]: valeur,
    //           }));
    //     }
    // useEffect(()=>{
    // //   JETON = useSelector((state) => state.jeton.access)
    // console.log("m  rco")
    // // dispatch(clearJetons());
    // }, [changeJeton])

    // const JETON = useSelector((state) => state.jeton.access)
    // const STATUTUSER = useSelector((state) => state.statutUser.access)
    // const dispatch = useDispatch()
    const act = (m,d,c)=>{
        // console.log(`actionM ${actionM}`)
        // console.log(`camping ${camping}`)
        // console.log(`jeton ${jeton}`)
        // console.log(actionM==="m")
        if(actionM=="m")return m
        else if (actionM=="d") return d
        else return c
    }
    const notif_gen = (message)=>toast.error(message,
        {position: "top-right",autoClose: 5000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_norm = (message)=>{
        toast(message,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}
    const formCM = ()=>{
        return <FormFicheInfoEnfantPro 
                    donneesPrim={null} 
                    campuuid={camping} 
                    clubuuid={formDonnees.club}
                    donneesPrev={formDonnees}
                    gererForm={(d)=>{
                        console.log("gerer form")
                        // act(
                        //     modifier,
                        //     supprimer,
                        //     creer
                        // )
                        modifier(d)
                        // recuperer(
                        //     `api/pipou/${campuuid}/${clubuuid}`,
                        //     jeton,
                        //     (rep)=>{
                        //         console.log(rep)
                        //         if(rep.statut=="succes"){
                        //             notif_norm(rep.message)
                        //         }
                        //         else{
                        //             notif_gen("Erreur")
                        //             notif_gen(JSON.stringify(rep))
                        //         }
                        //     },
                        //     (e)=>{
                        //         console.log(e)
                        //         notif_gen(e.message)
                        //     },
                        //     "POST",
                        //     corps
                        // )
                    }}
                     />
    }
    // const handleForm = ()=>{
    //     recuperer()
    // }
    const modifier = (d)=>{
        console.log(d)
        console.log("modifier enfant")
        recuperer(
            `api/peluche/${camping}/${formDonnees.club}/enfants/${d.uuid}/`,
            jeton,
            (rep)=>{
                console.log(rep)
                setModalForm(false)
                notif_norm(`Modification de ${element.prenom} ${element.nom} confirmée`)
                // setTimeout(() => {
                //     window.location.reload();
                //   }, "1000")
            },
            (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
            "PATCH",
            d
        )
    }
    const supprimer = ()=>{
        console.log("supprimer enfant")

        // recuperer(
        //     `api/prout/${camping}/anims/${element.uuid}/`,
        //     jeton,
        //     (rep)=>{
        //         if(rep?.statut??false){
        //             console.log("204 confirmation suppression")
        //         }
        //         setModalForm(false)
        //         notif_norm(`Suppression de ${element.prenom} ${element.nom} confirmée`)
        //         setTimeout(() => {
        //             window.location.reload();
        //           }, "1000")
        //     },
        //     (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
        //     "DELETE",
        // )
    }
    const creer = ()=>{
        console.log("créer enfant")
        // recuperer(
        //     `api/prout/${camping}/anims/`,
        //     jeton,
        //     (rep)=>{
        //         setModalForm(false)
        //         notif_norm(`Création de ${element.prenom} ${element.nom} confirmée`)
        //         setTimeout(() => {
        //             window.location.reload();
        //           }, "1000")
        //     },
        //     (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
        //     "POST",
        //     {
        //         "telephone":f_tel,
        //         "email":f_email,
        //         "prenom":f_prenom,
        //         "nom":f_nom
        //     }
        // )
    }
    // const [tournoi, setTournoi] = useState(null)
    // const [nbTour, setNbTour] = useState(3)
    
    
    // useEffect(()=>{
    // //   JETON = useSelector((state) => state.jeton.access)
    // console.log("m  rco")
    // // dispatch(clearJetons());
    // }, [changeJeton])
    // const size = useContext(ResponsiveContext);
    return (
      <div>
            <div>
                <Button variant={act("outline-light","outline-light","dark")} onClick={()=>{setModalForm(!modalForm)}}>
                    {act(
                        <FontAwesomeIcon icon={faPencil} />,
                        <FontAwesomeIcon icon={faTrash} />,
                        <FontAwesomeIcon icon={faPlus} />
                        )}
                </Button>
                <Modal show={modalForm} onHide={()=>{setModalForm(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>{act("Modifier","Supprimer","Créer")}
                        {/* <p>{camping}</p>
                        <p>{element.uuid}</p> */}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="bg-dark text-white">

                        {
                        act(
                            formCM(),
                            <div>
                                <p>Confirmer la suppression de <strong>{element.prenom} - {element.nom}</strong></p>
                                {/* <Button onClick={supprimer}>
                                    Supprimer
                                </Button> */}
                            </div>,
                            formCM()
                        )
                        }
                    </Modal.Body>
                    {/* <Modal.Footer>
                        {
                            act(
                                <Button variant="secondary" onClick={modifier}> Modifier l'enfant</Button>,
                                <Button variant="secondary" onClick={supprimer}> Supprimer l'enfant </Button>,
                                <Button variant="secondary" onClick={creer}> Créer l'enfant</Button>
                            )
                        }
                        
                    </Modal.Footer> */}
                </Modal>
            </div>
        {/* <ToastContainer className="text-norm notificationGrillee" /> */}
      </div>
    );
  };

