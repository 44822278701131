// import { Header } from "grommet";
import React, {useEffect,useState}  from "react";
// import { useSelector, useDispatch } from 'react-redux'
// import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { toast } from 'react-toastify';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser,faDoorOpen } from '@fortawesome/free-solid-svg-icons'

// import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
// import S from "../../conf/serveur.json"
import recuperer from "../../jsTools/toolsFetch";



export default function ResetMdp(){
    const [fait, setFait] = useState(false);
    const [form_m, setFormM] = useState("");
    // const [form_p, setFormP] = useState("");
    // const [changeJeton, setChangeJeton] = useState(true);


    // const JETON = useSelector((state) => state.jeton.access)
    // const STATUTUSER = useSelector((state) => state.statutUser.access)
    // const dispatch = useDispatch()

    const notif_gen = (message)=>toast.error(message,
        {position: "top-right",autoClose: 5000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_connecte = ()=>{
        console.log("notif connecte")
        toast(`Mail envoyé à ${form_m}`,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}
    const effaceJeton = ()=>{

    }
    const handleForm = ()=>{
        recuperer(
            `api/pouliche/mdp/nouveau?m=${form_m}`,
            "",
            (rep)=>{
                console.log("reussie")
                console.log(rep)
                if(rep?.erreur){
                    notif_gen(rep.erreur)
                }
                else if(rep?.succes){
                    console.log("succes")
                    notif_connecte()
                    setFait(true)
                }
                else{
                    notif_gen("")
                }
            },
            (err)=>{
                notif_gen(err.message)
                console.error(err)
            },
            "GET"
        )
       
    }
    // const [tournoi, setTournoi] = useState(null)
    // const [nbTour, setNbTour] = useState(3)
    
    
    // useEffect(()=>{
    // //   JETON = useSelector((state) => state.jeton.access)
    // console.log("m  rco")
    // // dispatch(clearJetons());
    // }, [changeJeton])
    // const size = useContext(ResponsiveContext);
    return (               
        <Modal.Dialog style={{maxWidth:"600px", minWidth:"300px"}} >
            <Modal.Header >
                <Modal.Title className="ms-4" >Mot de passe oublié</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    fait?
                    <p className="m-4">
                        Verifiez vos mails à l'adresse {form_m}, un lien de regeneration de mot de passe doit s'y trouver
                    </p>
                    :<Form>
                        <p className="m-4">Saisissez votre adresse email de connexion pour recevoir un lien vous permettant d'entrer votre nouveau mot de passe</p>
                        <FloatingLabel label="Adresse email de connexion" className="m-4 text-ora fw-bold" >
                            <Form.Control 
                                type="email" 
                                placeholder="Email"
                                defaultValue={form_m}
                                onChange = {(event)=>{setFormM(event.target.value)}}
                                />
                        </FloatingLabel>
                    </Form>
                }
            
            </Modal.Body>
            <Modal.Footer>
                {
                    fait?
                    false:
                    <Button variant="ora" className="text-white fw-bolder me-4" onClick={handleForm}>Regénérer votre mot de passe</Button>
                }
            </Modal.Footer>
        </Modal.Dialog>
    );
  };

