import React from "react";
import { useSelector } from 'react-redux';

// import ReactDOM from 'react-dom'
import CarteProTable from "./utilisateur/carteProTable";
import CarteProTableResp from "./utilisateur/carteProTableResp";
import CarteProTableEnfant from "./club/carteProTableEnfant";
import FormAnims from "./formulaires/formAnims";
import FormResp from "./formulaires/formResp";
import FormsClubs from "./formulaires/formClub";
import CarteProTableClub from "./utilisateur/carteProTableClub";


export default function ListeElements({typeElement,donnees=[], uuidCont}){
    // const [rencontres, setRencontres] = useState([]);
    const JETON = useSelector((state) => state.jeton.access)

    return (
      // <p>{typeElement}
      // </p>
      <div className="ps-3 pe-3">
        { 
          typeElement=="Anims"
          ? <div>
              <FormAnims jeton={JETON} actionM="c" camping={uuidCont} className="my-4" /> 
            {
              donnees==null
              ? false
              :donnees.map(x=><CarteProTable key={x.uuid} donnees={x} uuidCont={uuidCont} />)
            }</div>
          : typeElement=="Responsables"
          ? <div>
              <FormResp jeton={JETON} actionM="c" structure={uuidCont} className="my-4" /> 
            {
              donnees==null
              ? false
              :donnees.map(x=><CarteProTableResp key={x.uuid} donnees={x} uuidCont={uuidCont} />)
            }</div>
          : typeElement=="Enfants"
          ? <div>
              {/* <FormEnfant jeton={JETON} actionM="c" camping={uuidCont} className="my-4" />  */}
            {
              donnees==null
              ? false
              :donnees.map(x=><CarteProTableEnfant key={x.uuid} donnees={x} uuidCont={uuidCont} />)
            }</div>
          :typeElement=="Clubs"
          ? <div>
              <FormsClubs jeton={JETON} actionM="c" camping={uuidCont} className="my-4" /> 
            {
              donnees==null
              ? false
              :donnees.map(x=><CarteProTableClub key={x.uuid} donnees={x} uuidCont={uuidCont} />)
            }</div>
          :<p>Nope ./listeElements.js</p>
        }
        
      </div>
    );
  };

