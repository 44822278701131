import React, {useEffect,useState}  from "react";
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus} from '@fortawesome/free-solid-svg-icons'

import { Link } from "react-router-dom";
// import ReactDOM from 'react-dom'
// import CarteProTable from "./utilisateur/carteProTable";
// import CarteProTableResp from "./utilisateur/carteProTableResp";
// import FormAnims from "./formulaires/formAnims";
// import FormResp from "./formulaires/formResp";
// import { Button } from "grommet";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import recuperer from "../../jsTools/toolsFetch";
import ListeElements from "../listeElements";

export default function ListeEnfant({donneesClub, uuidCamping}){
    const [booModal, setBooModal] = useState(false);
    const [alreadyLoad, setAlreadyLoad] = useState(false);
    const [donneesEnfants, setDonneesEnfants] =useState([])
    const [donneesEnfantsP, setDonneesEnfantsP] =useState([])
    const [recEnfant, setRecEnfant] =useState("")
    const [messageLoad, setMessageLoad] = useState("Chargement des données")
    // const [rencontres, setRencontres] = useState([]);
    
    const JETON = useSelector((state) => state.jeton.access)

    useEffect(()=>{
      // console.log("uuss")
      // console.log(donneesEnfants)
      // console.log([...donneesEnfants].sort((a,b)=>a.nom.localeCompare(b.nom)))
      setDonneesEnfantsP([...donneesEnfants].sort((a,b)=>a.nom.localeCompare(b.nom)))
    },[donneesEnfants])

    const notif_gen = (message)=>toast.error(message,
      {position: "top-right",autoClose: 5000,hideProgressBar: false,
      closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
     })
    const notif_norm = (message)=>{
        toast(message,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
      })}
    
    const estDansString = (s, srec)=>{
      let searchWords = srec.split(/\s+/); // Split search string into individual words
      let regex = new RegExp(`^.*${searchWords.map(word => `(?=.*${word})`).join('')}.*`, 'i');
      return regex.test(s); 
    }
    
    const filtrerString = (s)=>{
      let temp = donneesEnfants.filter(x=>estDansString(JSON.stringify(x),s))
      temp.sort((a,b)=>{
        if(s.toLowerCase().includes(a.prenom.toLowerCase()))return -1
        else if(s.toLowerCase().includes(b.prenom.toLowerCase()))return 1
        if(s.toLowerCase().includes(a.nom.toLowerCase()))return -1
        else if(s.toLowerCase().includes(b.nom.toLowerCase()))return 1
        else return -1
      })
      setDonneesEnfantsP(temp)
    }
    
    const ouvrirModal = ()=>{
      // console.log("ouvrir modal")
      if(!alreadyLoad){
        // console.log("load")
        recuperer(
          `api/peluche/${uuidCamping}/${donneesClub.uuid}/enfants/`,
          JETON,
          (rep)=>{
            // console.log(rep);
            if(rep.length==0){
              setMessageLoad(`Pas de données pour ce club (${donneesClub.nom})`)
              notif_gen(`Pas de données pour ce club (${donneesClub.nom})`)
            }
            else{
              setMessageLoad("")
            }
            // console.log("coucou")
            setDonneesEnfants(rep)
            // setDonneesEnfantsS(JSON.stringify(rep))
            // notif_norm("")  
          },
          (err)=>{
            notif_gen(err.message)
            setMessageLoad("Erreur, veuillez réessayer")
            console.log(err)

          }
        )
        setAlreadyLoad(false)
      }
      setBooModal(!booModal)
      // console.log(donneesEnfants)
    }

    return (
      // <p>{typeElement}
      // </p>
      <div className="col-12 p-0">
        <Button className="m-2" size="sm" onClick={ouvrirModal} variant="ora text-white fw-bold"> Liste enfants (créer/modifier)</Button>
          <Modal
            size="lg"
            show={booModal}
            onHide={() => setBooModal(false)}
            fullscreen={true}
            // className="modal-max-h"
            //aria-labelledby="example-mo:dal-sizes-title-lg"
          >
          <Modal.Header closeButton>
            <Modal.Title >
              Liste Enfant - {donneesClub.nom}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  style={{ maxHeight: "85vh",overflowY: "auto" }}>
            {messageLoad.length<1?false:<span>{messageLoad}</span>}
            <Link to={`/fiche/${uuidCamping}/${donneesClub.uuid}`}>
              <Button variant="ble fw-bold text-white" className="m-1" jusitfy="center" >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Link>
            <ListeElements 
                typeElement="Enfants" 
                donnees={donneesEnfantsP}
                uuidCont={uuidCamping}
                 />
          </Modal.Body>
          <Modal.Footer style={{ position: 'sticky', bottom: 0 }}>
            {/* <FontAwesomeIcon icon={faFilter} /> */}
            <FloatingLabel className="text-2" label="Rechercher">
              <Form.Control
                // className={`${nomclasse} text-capitalize`}
                type="text"
                id="recherche_enfant"
                placeholder="Rechercher"
                // defaultValue={recEnfant}
                onChange = {(event)=>{filtrerString(event.target.value)}}
              />
              </FloatingLabel>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

