import React, {useState,useEffect} from "react";
// import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux'



import { toast } from 'react-toastify';

import CartePro from "../components/utilisateur/cartePro"
import CarteCamping from "../components/utilisateur/carteCamping"
import CarteAction from "../components/utilisateur/carteAction";

import recuperer from '../jsTools/toolsFetch'
// import { Outlet } from "react-router-dom";


const Utilisateur = () => {
  const [donneesU, setDonneesU] = useState({});
  const [donneesS, setDonneesS] = useState("");
  

  const JETON = useSelector((state) => state.jeton.access)

  const notif_gen = (message)=>toast.error(message,
      {position: "top-right",autoClose: 5000,hideProgressBar: false,
      closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })

  useEffect(()=>{
    console.log(JETON)
      recuperer("api/self", JETON, 
          (rep)=>{setDonneesU(rep);setDonneesS(JSON.stringify(rep))},
          (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)}
          )       
  },[])
  
  useEffect(()=>{
        // console.log("DONNNNNNEEES")
        // console.log(donneesU)
    },[donneesU])
    


    return(
        <div >
          {
            Object.keys(donneesU).length==0
            ?<p>Connexion en cours...</p>
            :<div className="row justify-content-around ">
            <CartePro donnees={donneesU} />
            {
              (Object.keys(donneesU).includes("campings")
                ?donneesU.campings.map(x=><CarteCamping key={x.uuid} donnees={x} />)
                :false)
            }
            {
              (donneesU.role.includes("gestionnaire") || donneesU.role.includes("responsable")
                ?<CarteAction donnees={donneesU} role={donneesU.role.includes("gestionnaire")?"gestionnaire":"responsable"}/>
                :false)
            }
            
          </div>
          }
            {donneesS}
            
            {/* <CarteCamping chemin="api/" /> */}
            {/* <ToastContainer className="text-norm" /> */}
        </div>
        )
    
  };
  
  export default Utilisateur;
  