// import { Header } from "grommet";
import React, {useEffect,useState}  from "react";
// import { useSelector, useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Toast from 'react-bootstrap/Toast';

import { DateRange, Calendar } from 'react-date-range';
import {fr} from 'react-date-range/dist/locale/index'

import { toast } from 'react-toastify';

// import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
import capitalise from '../../jsTools/toolsString'
// import recuperer from '../../jsTools/toolsFetch'
import recuperer from "../../jsTools/toolsFetch";
import FormContact from "./formContact"



// action = m || d || c (modify, delete, create)
export default function FormFicheInfoEnfantPro({donneesPrim, campuuid, clubuuid, gererForm=false, donneesPrev=false}){
    // const [modalForm, setModalForm] = useState(false);
    
    // const [form_prenom, setFormPrenom] = useState("");
    // const [form_nom, setFormNom] = useState("");
    const [formDonnees, setFormDonnees] = useState(donneesPrev?donneesPrev:{
        "nom": "",
        "prenom": "",
        "ddn": null,
        "sejour_debut": null,
        "sejour_fin": null,
        "emplacement": "",
        "acceptePhoto": false,
        "sortie": false,
        "allergie":false,
        "commentaireAllergie": "",
        "traitementMedical": false,
        "commentaireMedical": "",
        "autorisationInterMed": false,
        "autorisationRepresentantLegal": false,
        "commentaire": "",
        "representantLegal": null,
        "club": null,
        "autresContacts": []
    })
    const [rangeSejour, setRangeSejour] = useState([
        {
          startDate: new Date(),
          endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
          key: 'selection'
        }
      ])
    const [sejourModal, setSejourModal] = useState(false)
    const [dateSejourVal, setDateSejourVal] = useState(false)
    const [labelRangeSejour, setLabelRangeSejour] = useState("")

    const [ddn, setDdn] = useState(null)
    const [ddnModal, setDdnModal] = useState(false)
    const [ddnVal, setDdnVal] = useState(false)
    const [labelDdn, setLabelDdn] = useState("")
    
    const [repModal, setRepModal] = useState(false)
    const [repVal, setRepVal] = useState(false)
    const [labelRep, setLabelRep] = useState("")
    
    const [contactModal, setContactModal] = useState(false)
    // const [repVal, setRepVal] = useState(false)
    const [labelContact, setLabelContact] = useState("")

    const noire = true;
    const nomclasse = noire ? "bg-dark text-white" : ""

    const valeurFormDonnees = (clef, valeur)=>{
    setFormDonnees((prevValues) => ({
            ...prevValues,
            [clef]: valeur,
          }));
    }
    const addArrayFormDonnees = (clef, valeur)=>{
        // console.log(clef)
        // console.log(formDonnees)
        let tempd = [...formDonnees[clef]]
        tempd.push(valeur)
        setFormDonnees((prevValues) => ({
                ...prevValues,
                [clef]: tempd,
              }));
        }
    const popArrayFormDonnees = (clef, ind)=>{
        let tempd = [...formDonnees[clef]]
        tempd.splice(ind,1)
        setFormDonnees((prevValues) => ({
                ...prevValues,
                [clef]: tempd,
              }));
        }
    const validerDatesSejour = ()=>{
        try{
            // console.log(rangeSejour)
            // console.log(typeof(rangeSejour[0].startDate))
            // console.log(rangeSejour[0].startDate)
            let sdeb = rangeSejour[0].startDate.toLocaleDateString("fr-FR");
            let sfin = rangeSejour[0].endDate.toLocaleDateString("fr-FR");
            setLabelRangeSejour(`${sdeb} ⇒ ${sfin}`)
            // let datedeb = new Date(rangeSejour[0].startDate);
            // let datefin = new Date(rangeSejour[0].startDate);
            setDateSejourVal(true)
            valeurFormDonnees("sejour_debut",sdeb.split("/").reverse().join("-"))
            valeurFormDonnees("sejour_fin",sfin.split("/").reverse().join("-"))
            setSejourModal(false)
        }catch(e){
            setDateSejourVal(false)
            setSejourModal(false)
            console.log(e)
        }        
    }
    const validerDdn = ()=>{
        try{
            // let sddn = ddn.startDate.toLocaleDateString("fr-FR");
            // let sfin = rangeSejour[0].endDate.toLocaleDateString("fr-FR");
            let sddn = ddn.toLocaleDateString("fr-FR")
            setLabelDdn(sddn)
            // let datedeb = new Date(rangeSejour[0].startDate);
            // let datefin = new Date(rangeSejour[0].startDate);
            setDdnVal(true)
            valeurFormDonnees("ddn",sddn.split("/").reverse().join("-"))
            // valeurFormDonnees("sejour_fin",sfin.split("/").reverse().join("-"))
            setDdnModal(false)
        }catch(e){
            setDdnVal(false)
            setDdnModal(false)
            console.log(e)
        }        
    }


    const confirmerRepLeg = (dt)=>{
        valeurFormDonnees("representantLegal",dt);
        setLabelRep(`${capitalise(String(dt.prenom))} ${capitalise(String(dt.nom))}`);
        setRepVal(true);
        setRepModal(false)
    }
    

    useEffect(()=>{
        if(gererForm){
            //pas fiche info mais form de l'anim
            setRangeSejour([{
                startDate: new Date(donneesPrev.sejour_debut),
                endDate: new Date(donneesPrev.sejour_fin),
                key: 'selection'
            }])
            confirmerRepLeg(donneesPrev.representantLegal)
            validerDatesSejour()

            
        }
    }, [])

    
    const notif_gen = (message)=>toast.error(message,
        {position: "top-right",autoClose: 5000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_norm = (message)=>{
        toast(message,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}
    
    // const formatageForm = (obj)=>{
    //     obj
    // }
    const handleForm = ()=>{
        var corps = {...formDonnees}
        corps["club"] = clubuuid
        if (gererForm){
            gererForm(corps)
        }
        else{
            // corps = formatageForm(corps)
            recuperer(
                `api/pipou/${campuuid}/${clubuuid}`,
                "",
                (rep)=>{
                    if(rep.statut=="succes"){
                        notif_norm(rep.message)
                    }
                    else{
                        notif_gen("Erreur")
                        notif_gen(JSON.stringify(rep))
                    }
                },
                (e)=>{
                    console.log(e)
                    notif_gen(e.message)
                },
                "POST",
                corps
            )
        }
        
    }
    
    // const size = useContext(ResponsiveContext);
    return (
      <form>
            <div className="row d-flex align-items-end">
                <div className="col-6">
                    <FloatingLabel className="text-2" label="Prénom enfant">
                        <Form.Control
                            className={`${nomclasse} text-capitalize`}
                            type="text"
                            id="ffi_prenomEnfant"
                            placeholder="Prénom enfant"
                            defaultValue={formDonnees.prenom}
                            onChange = {(event)=>{valeurFormDonnees("prenom",event.target.value)}}
                            aria-describedby="ffi_prenomEnfant_aide"
                        />
                    </FloatingLabel>
                    
                </div>
                <div className="col-6">
                    <FloatingLabel className="text-2" label="Nom enfant">
                        <Form.Control
                            // dark={true}
                            className={`${nomclasse} text-capitalize`}
                            type="text"
                            id="ffi_nomEnfant"
                            placeholder="Nom enfant"
                            defaultValue={formDonnees.nom}
                            onChange = {(event)=>{valeurFormDonnees("nom",event.target.value)}}
                            // aria-describedby="ffi_nomEnfant_aide"
                        />
                    </FloatingLabel>
                </div>
                <div className="col-6">
                    <FloatingLabel className="text-2" label="N° d' emplacement">
                        <Form.Control
                            // dark={true}
                            className={`${nomclasse} text-capitalize`}
                            type="text"
                            id="ffi_emplacement"
                            placeholder="Emplacement"
                            defaultValue={formDonnees.emplacement}
                            onChange = {(event)=>{valeurFormDonnees("emplacement",event.target.value)}}
                            // aria-describedby="ffi_nomEnfant_aide"
                        />
                    </FloatingLabel>
                </div>
                {/* <div className="col-6">
                    <FloatingLabel className="text-2" label="Date de naissance enfant">
                        <Form.Control
                            // dark={true}
                            className={`${nomclasse}`}
                            type="date"
                            id="ffi_ddnEnfant"
                            placeholder="Date de naissance enfant"
                            defaultValue={formDonnees.ddn}
                            onChange = {(event)=>{valeurFormDonnees("ddn",event.target.value)}}
                            // aria-describedby="ffi_nomEnfant_aide"
                        />
                    </FloatingLabel>
                </div> */}
                {/* <div className="col-6">
                    <FloatingLabel className="text-2" label="Date de naissance enfant">
                        <Form.Control
                            // dark={true}
                            className={`${nomclasse}`}
                            type="date"
                            id="ffi_ddnEnfant"
                            placeholder="Date de naissance enfant"
                            defaultValue={formDonnees.ddn}
                            onChange = {(event)=>{valeurFormDonnees("ddn",event.target.value)}}
                            // aria-describedby="ffi_nomEnfant_aide"
                        />
                    </FloatingLabel>
                </div> */}
                <div className="col-6 ">
                    <div id="ffi_ddn_aide" className="text-sm">
                        Dates de naissance
                    </div>
                    <Button className="" onClick={()=>setDdnModal(!ddnModal)} variant="outline-light">
                        {ddnVal?labelDdn:"Dates de naissance"}
                    </Button>
                        <Modal show={ddnModal} onHide={()=>{setDdnVal(false);setDdnModal(false)}}>
                            <Modal.Body>
                                <Calendar 
                                    onChange={item => setDdn(item)}
                                    className="wmax100"
                                    locale={fr} 
                                    date={ddn} />
                                {/* <div style={{width:"100%"}}> */}
                                    {/* <DateRange
                                        className="wmax100"
                                        editableDateInputs={true}
                                        locale={fr}
                                        // weekdayDisplayFormat="EEEEE"
                                        onChange={item => setDdn(item.selection)}
                                        // moveRangeOnFirstSelection={false}
                                        // ranges={rangeSejour}
                                        dateDisplayFormat="dd MMM yyyy"
                                        rangeColors={["#212529"]}
                                    /> */}
                                {/* </div> */}
                                
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="dark" onClick={()=>setDdnModal(false)}>
                                Fermer
                            </Button>
                            <Button variant="outline-dark" onClick={()=>validerDdn()}>
                                Valider date de naissance
                            </Button>
                            </Modal.Footer>
                        </Modal>
                </div>
                <div className="col-12 ">
                    <div id="ffi_dds_aide" className="text-sm">
                        Dates de séjour
                    </div>
                    <Button className="" onClick={()=>setSejourModal(!sejourModal)} variant="outline-light">
                        {dateSejourVal?labelRangeSejour:"Dates de séjour"}
                    </Button>
                        <Modal show={sejourModal} onHide={()=>{setDateSejourVal(false);setSejourModal(false)}}>
                            <Modal.Body>
                                {/* <div style={{width:"100%"}}> */}
                                    <DateRange
                                        className="wmax100"
                                        editableDateInputs={true}
                                        locale={fr}
                                        // weekdayDisplayFormat="EEEEE"
                                        onChange={item => setRangeSejour([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={rangeSejour}
                                        dateDisplayFormat="dd MMM yyyy"
                                        rangeColors={["#212529"]}
                                    />
                                {/* </div> */}
                                
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="dark" onClick={()=>setSejourModal(false)}>
                                Fermer
                            </Button>
                            <Button variant="outline-dark" onClick={()=>validerDatesSejour()}>
                                Valider dates séjour
                            </Button>
                            </Modal.Footer>
                        </Modal>
                </div>
                <div className="col-12 ">
                    <div id="ffi_dds_aide" className="text-sm">
                        Représentant(e) légal(e)
                    </div>
                    
                    <Button className="" onClick={()=>setRepModal(!repModal)} variant="outline-light">
                        {repVal?labelRep:"Représentant(e) légal(e)"}
                    </Button>
                        <Modal show={repModal} onHide={()=>{setRepVal(false);setRepModal(false)}}>
                            <Modal.Body>
                                <FormContact 
                                    principal={true} 
                                    emplacement={formDonnees.emplacement}
                                    confirmValues={confirmerRepLeg}
                                    />
                            </Modal.Body>
                        </Modal>
                </div>
                <div className="col-6">
                    
                    <Button className="" onClick={()=>setContactModal(!contactModal)} variant="outline-light">
                        Ajouter d'autres contacts
                    </Button>
                        <Modal show={contactModal} onHide={()=>{setContactModal(false)}}>
                            <Modal.Body>
                                <FormContact 
                                    principal={false} 
                                    emplacement={formDonnees.emplacement}
                                    gommer={true}
                                    confirmValues={
                                        (dt)=>{
                                            // console.log(dt);
                                            // valeurFormDonnees("representantLegal",dt);
                                            // setLabelRep(`${capitalise(String(dt.prenom))} ${capitalise(String(dt.nom))}`);
                                            // setRepVal(true);
                                            addArrayFormDonnees("autresContacts",dt)
                                            setContactModal(false)
                                            
                                            }
                                        }
                                    />
                            </Modal.Body>
                        </Modal>
                        <div className="row">
                        {formDonnees.autresContacts.map((x,i)=><Toast
                            className="m-1 col-3"
                            bg="ligth"
                            onClose={()=>popArrayFormDonnees("autresContacts",i)}
                            key={i}
                            >
                            <Toast.Header>
                               {x.prenom} {x.nom}
                            </Toast.Header>
                            {/* <Toast.Body className={variant === 'Dark' && 'text-white'}>
                                Hello, world! This is a toast message.
                            </Toast.Body> */}
                            </Toast>)}
                        </div>
                        
                </div>
                {/* <div className="col-12 ">
                    <div id="ffi_dds_aide" className="text-sm">
                        Autres contacts
                    </div>
                    
                </div> */}
                <div className="col-12 ">
                    {/* <div id="ffi_photo_aide" className="text-sm">
                        Représentant(e) légal(e) [Vous]
                    </div> */}
                    <Form.Check // prettier-ignore
                        className="text-sm"
                        type="checkbox"
                        id="ffi_photo"
                        onChange = {(event)=>{valeurFormDonnees("acceptePhoto",event.target.value)}}
                        defaultValue={formDonnees.acceptePhoto}
                        label={donneesPrim?.data.structure.description_legale_droit_image??"Droit diffusion photo"}
                    />
                </div>
                <div className="col-12 ">
                    {/* <div id="ffi_photo_aide" className="text-sm">
                        Représentant(e) légal(e) [Vous]
                    </div> */}
                    <FloatingLabel className="text-2" label={gererForm?"Sortie":`J'autorise mon enfant à sortir tout seul du club (exclusivement à la fin des activités)`}>
                        <Form.Select 
                            className={`${nomclasse} text-sm`}
                            id="ffi_sortie"
                            onChange={(event)=>{valeurFormDonnees("sortie",event.target.value)}}
                            defaultValue={formDonnees.sortie}
                            aria-label={gererForm?"Sort seul(e)":`J'autorise mon enfant à sortir tout seul du club (exclusivement à la fin des activités)`}
                            >
                            {/* <option>Open this select menu</option> */}
                            <option value="A">Accompagné(e)</option>
                            <option value="S">Seul(e)</option>
                            {/* <option value="3">Three</option> */}
                        </Form.Select>
                    </FloatingLabel>
                    
                    {/* <Form.Check // prettier-ignore
                        className="text-sm"
                        type="checkbox"
                        id="ffi_sortie"
                        onChange = {(event)=>{valeurFormDonnees("sortie",event.target.checked)}}
                        defaultValue={formDonnees.sortie}
                        label="J'autorise mon enfant à sortir tout seul du club (exclusivement à la fin des activités)"
                    /> */}
                </div>

                <div className="col-12 ">
                    <Form.Check // prettier-ignore
                        className="text-sm"
                        type="checkbox"
                        id="ffi_allergie"
                        onChange = {(event)=>{valeurFormDonnees("allergie",event.target.checked)}}
                        defaultChecked={formDonnees.allergie}
                        label="Votre enfant a t-il une allergie ou une contre-indication alimentaire ?"
                    />
                </div>
                {/* {formDonnees.traitementMedical} */}
                {
                    formDonnees.allergie
                    ?<div className="col-12 ">
                        <FloatingLabel className="text-2" label="Votre enfant a t-il des allergies ?">
                            <Form.Control
                                as="textarea"
                                className={`${nomclasse} text-sm`}
                                placeholder="(alimentation, médicaments, maquillage, autres...)"
                                id="ffi_allergiecom"
                                onChange = {(event)=>{valeurFormDonnees("commentaireAllergie",event.target.value)}}
                                defaultValue={formDonnees.commentaireAllergie}
                                style={{ height: '200px' }}
                            />
                        </FloatingLabel>
                    </div>
                    :false
                }
                <div className="col-12 ">
                    <Form.Check // prettier-ignore
                        className="text-sm"
                        type="checkbox"
                        id="ffi_trtmed"
                        onChange = {(event)=>{valeurFormDonnees("traitementMedical",event.target.checked)}}
                        defaultChecked={formDonnees.traitementMedical}
                        label="Votre enfant suit-il un traitement médical ?"
                    />
                </div>
                {/* {formDonnees.traitementMedical} */}
                {
                    formDonnees.traitementMedical
                    ?<div className="col-12 ">
                        <FloatingLabel className="text-sm" label="Quels traitements ?">
                            <Form.Control
                            as="textarea"
                            className={`${nomclasse} text-sm`}
                            placeholder="(diabetique, asthme, ...)"
                            id="ffi_commed"
                            onChange = {(event)=>{valeurFormDonnees("commentaireMedical",event.target.value)}}
                            defaultValue={formDonnees.commentaireMedical}
                            style={{ height: '200px' }}
                            />
                        </FloatingLabel>
                    </div>
                    :false
                }
                <div className="col-12 ">
                    <Form.Check // prettier-ignore
                        className="text-sm"
                        type="checkbox"
                        id="ffi_intermed"
                        onChange = {(event)=>{valeurFormDonnees("autorisationInterMed",event.target.value)}}
                        defaultValue={formDonnees.autorisationInterMed}
                        label="Autorisation d’intervention médicale en cas d’accident"
                    />
                </div>
                {gererForm?false:<div className="col-12 ">
                    <Form.Check // prettier-ignore
                        className="text-sm"
                        type="checkbox"
                        id="ffi_autorisation"
                        onChange = {(event)=>{valeurFormDonnees("autorisationRepresentantLegal",event.target.value)}}
                        defaultValue={formDonnees.autorisationRepresentantLegal}
                        label={`Je soussigné(e), Madame, Monsieur ${labelRep} autorise mon enfant, ${formDonnees.prenom} ${formDonnees.nom}, à participer aux activités proposées par les animateurs dans le cadre du club enfant`}
                    />
                </div>}
                <div className="col-12 ">
                    {/* <div id="ffi_photo_aide" className="text-sm">
                        Représentant(e) légal(e) [Vous]
                    </div> */}
                    <FloatingLabel className="text-2" label="Commentaire">
                        <Form.Control
                        as="textarea"
                        className={`${nomclasse} text-sm`}
                        placeholder=""
                        id="ffi_commentaire"
                        onChange = {(event)=>{valeurFormDonnees("commentaire",event.target.value)}}
                        defaultValue={formDonnees.commentaire}
                        style={{ height: '200px' }}
                        />
                    </FloatingLabel>
                </div>
            </div>
            <Button onClick={handleForm}>
                {gererForm?"Modifier l'enfant":"Valider la fiche d'information et l'envoyer"}
            </Button>
            
            {/* <button onClick={()=>{console.log({formDonnees})}}>Marco</button>
            {JSON.stringify(formDonnees)} */}
      </form>
    );
  };

