import React, {useEffect,useState}  from "react";
// import {
//     // Card,
//     // CardHeader,
//     // CardBody,
//     // CardFooter,
//     // Heading,
//     // Paragraph,
//     Button,
//   } from "grommet";
// import ReactDOM from 'react-dom'
// import { Link } from "react-router-dom";
import Rencontre from "../rencontre";
// import shuffle from '../../jsTools/toolsArray';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import C from "../../conf/tournoi.json"

export default function Tour({equipes, rencontres,numeroTour,gs,ts, nobody="nobody"}){
    // const [nbTour, setNbTour] = useState(3)

    const getScore = (item,i)=>{
      let autre = (i==0?1:0)
      let res =  ts?.[item[i]]?.[item[autre]]?.[numeroTour]?.[0] ?? 0;
      return res
    }
    
    return (
      <div>
        <p style={{fontSize:"min(0.7em,10vw)"}}>Tour n°{numeroTour}</p>
        {/* {rencontres.length} */}
        {/* {
          rencontres.map((item,index)=>(
            // <p key={index} >{JSON.stringify(equipes[item[0]])} <br/>?!? <br/>{JSON.stringify(equipes[item[1]])}</p>
            <p key={item[0]+""+item[1]} >{equipes[item[0]]["nom"]} <br/>?!? <br/>{equipes[item[1]]["nom"]}</p>
          ))
        } */}
        <Row>
          {
            rencontres.map((item,index)=>(
              <Col key={"Rencontre"+crypto.randomUUID()} xs={12} sm={11} md={6} lg={6} xl={6} >
                <Rencontre 
                  equipe1={(item[0]===C.nobody?C.nobody:equipes[item[0]])} 
                  equipe2={(item[1]===C.nobody?C.nobody:equipes[item[1]])}
                  gs={gs}
                  score1={getScore(item,0)}
                  score2={getScore(item,1)}
                  numTour={numeroTour}
                  />
              </Col>
            ))
          }
        </Row>
        
        {/* <Rencontre equipe1="marco" equipe2="polo"></Rencontre> */}
      </div>
      
    );
  };
