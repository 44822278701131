import React, {useEffect} from "react";
// import { useSelector, useDispatch } from 'react-redux'
import { useLoaderData } from "react-router-dom";

import NewMdp from "../components/auth/newMdp";

export async function loaderuuid({ params }) {
  console.log("LOADER")
  console.log(params)
  const uanim = params.uanim
  const upantin = params.upantin
  console.log(uanim)
  console.log(upantin)
  return {uanim, upantin};
}


const MdpNouveau = () => {
  const { uanim, upantin } = useLoaderData();

  // const [showSplash, setShowSplash] = useState(true);
  // const [donneesU, setDonneesU] = useState({});
  // const [donneesS, setDonneesS] = useState("");
  // const [infocharge, setInfocharge] = useState(false);

  // const JETON = useSelector((state) => state.jeton.access)
  // const dispatch = useDispatch()

  // const notif_gen = (message)=>toast.error(message,
  //     {position: "top-right",autoClose: 5000,hideProgressBar: false,
  //     closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  // })
  useEffect(()=>{
    console.log("useeffectte")
    console.log(uanim)
  },[uanim])

  // const rec_self = ()=>{
  //   console.log(JETON)
  //   recuperer("api/self", JETON, 
  //       (rep)=>{setDonneesU(rep);setDonneesS(JSON.stringify(rep))},
  //       (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)}
  //       )   
  // }

  return (
      <div className="fulllargeur" style={{paddingTop:"10px"}}>
          <NewMdp uanim={uanim} upantin={upantin} />
        </div>
          
  );
};

export default MdpNouveau;