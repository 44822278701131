import React, {useState,useEffect} from "react";

// import ReactDOM from 'react-dom'
// import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
// import { ToastContainer, toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays} from '@fortawesome/free-solid-svg-icons'

import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// import FormAnims from "../formulaires/formAnims";
import FormScore from "../formulaires/formScore";
import FormTournoi from "../formulaires/formTournoi";
import TournoiComp from "../tournoiComp";
import ScoreEquipe from "../scoreEquipe";

export default function CarteActiviteTable({donnees, typeAct,uuidCont, uuidAnim}){
    const [formatDate, setFormatDate] = useState("");
    const [labelDateCrea, setLabelDateCrea] = useState("")
    const [modalAcces, setModalAcces] = useState(false)

    const JETON = useSelector((state) => state.jeton.access)


    // const notif_gen = (message)=>toast.error(message,
    //     {position: "top-right",autoClose: 5000,hideProgressBar: false,
    //     closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    // })

    useEffect(()=>{
      const date = new Date(donnees.dateDerModif);
      const crea = new Date(donnees.dateCreation)

      const options = { day: "2-digit", month: "2-digit", year: "numeric",hour: "2-digit",
      minute: "2-digit",
      second: "2-digit", };
      setFormatDate(date.toLocaleString("fr-FR", options))   
      setLabelDateCrea(crea.toLocaleDateString("fr-FR",{
        weekday: "long", day: "2-digit", month: "long", year: "numeric"
      }))
      
    },[])
    

    const ouvrirModal = ()=>{


      setModalAcces(true)
    }

    const fermerModal = ()=>{
      console.log("fermer");
      setModalAcces(false)
    }
    // useEffect(()=>{
    //       console.log("DONNNNNNEEES")
    //       console.log(donnees)
    //   },[donnees])
    

    return true ? (
        <Card bg="dark" text="white" className="mt-4 mx-3">
          {Object.keys(donnees).length < 1 ? (
            <Card.Body>
              <ListGroup>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
              </ListGroup>
            </Card.Body>
          ) : (
            <Card.Body className="row pb-1">
                <div className="row col-9">
                  <strong className="col-12 mb-1">{donnees.titre}</strong>
                  <div className="col-12 mb-2"><FontAwesomeIcon icon={faCalendarDays} /> {labelDateCrea}</div>
                  <div className="col-12 mb-2">
                      <Button onClick={ouvrirModal} variant="ora" >
                      {
                        typeAct=="Tournois"?
                            <span>Accèder au tournoi</span>
                            :<span>Accèder au score</span>
                      }
                      </Button>                    
                  </div>
                </div>
               
               {/* <div className="col-xl-4 col-lg-4 col-md-6">{donnees.uuid}</div> */}

               {/* <strong className="col-xl-2 col-lg-2 col-md-6">{donnees.prenom}</strong>
               <span className="col-xl-2 col-lg-2 col-md-6">{donnees.nom}</span>
               <div className="col-xl-2 col-lg-2 col-md-6"><FontAwesomeIcon icon={faPhone} /> {donnees.telephone}</div>
               <div className="col-xl-2 col-lg-2 col-md-6"><FontAwesomeIcon icon={faEnvelope} /> {donnees.email}</div> */}
               <div className="col-xl-2 col-lg-2 col-md-4 col-3 col">
                  {
                    typeAct=="Tournois"?
                      <div className="col-xl-2 col-lg-2 col-md-4 col">
                        <FormTournoi jeton={JETON} actionM="m" camping={uuidCont} element={donnees} /> 
                        <FormTournoi jeton={JETON} actionM="d" camping={uuidCont} element={donnees} /> 
                      </div>
                      :<div className="col-xl-2 col-lg-2 col-md-4 col">
                        <FormScore jeton={JETON} actionM="m" camping={uuidCont} element={donnees} /> 
                        <FormScore jeton={JETON} actionM="d" camping={uuidCont} element={donnees} /> 
                      </div>
                  }
                </div>
               <small text="gray" className="minicoindroit col-8">Modifié le {formatDate}</small>
               <Modal 
                  show={modalAcces} 
                  onHide={fermerModal}
                  fullscreen={true}
                  >
                  <Modal.Header className="py-2" closeButton>
                    <strong>{donnees.titre}</strong>
                  </Modal.Header>
                  <Modal.Body className="p-1">
                  {
                    typeAct=="Tournois"?
                    <TournoiComp uuidAnim={uuidAnim} uuidCamping={uuidCont} uuidTournoi={donnees.uuid} />
                    :<ScoreEquipe />
                  }
                  </Modal.Body>
                </Modal>
            </Card.Body>
          )}
          
          {/* <ToastContainer className="text-norm notificationGrillee" /> */}
        </Card>
      ) : (
        false
      );
  };
