import React, {useState,useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'

import { useLoaderData,useOutletContext, useSearchParams  } from "react-router-dom";
import { toast } from 'react-toastify';
import recuperer from "../jsTools/toolsFetch";

import CartePresencesJournee from '../components/utilisateur/cartePresencesJournee'
import { Vortex } from "react-loader-spinner";

// import LAppel from "../components/club/lappel";

export async function appel_liste_loader({ params }) {
  //const contact = await getContact(params.contactId);
  const id_camp = params.id_camp
  const id_club = params.id_club
  return {id_camp, id_club};
}

const PageListeAppel = ({typeAct}) => {
  const { id_camp, id_club } = useLoaderData();
  const {setTitreBandeau} = useOutletContext()

  const [nom_club, setNomClub] = useState("CLUB")
  const [presences, setPresences] = useState([])
  const [presencesOrd, setPresencesOrd] = useState({})
  const [listeOrd, setListeOrd]  =useState([])
  const [ready, setReady] = useState(false)
  // const [donnees, setDonnees] = useState([])
  // const [modalAcces, setModalAcces] = useState(false)
  let [searchParams, setSearchParams] = useSearchParams();

  const JETON = useSelector((state) => state.jeton.access)
  const USERUUID = useSelector((state) => state.statutUser.uuid)

  const auj = new Date()
  var hier = new Date()
  var avhier = new Date()
  hier.setDate(hier.getDate()-1)
  avhier.setDate(avhier.getDate()-2)
  // const dispatch = useDispatch()


  const formatToDate = (s=false)=>{
    if(!s)return Date.now()
    return new Date(s)
  }

  const dateToString = (s)=>{
    let d = formatToDate(s)
    let sd = d.toDateString()
    let fd = d.toLocaleDateString('fr-FR', { weekday: 'short', day: '2-digit', month: '2-digit' }).replace(/\//g, '/')
    if(sd==auj.toDateString())return ["Aujourd'hui",true]
    // else if(sd==hier.toDateString())return ["Hier", true]
    // else if(sd==avhier.toDateString())return ["Avant-hier", true]
    else{
      return [fd, false];
    }
  } 
  
  const notif_norm = (message, temps=5000)=>toast(message,
    {position: "top-right",autoClose: temps,hideProgressBar: false,
    closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })
  const notif_gen = (message, temps=5000)=>toast.error(message,
    {position: "top-right",autoClose: temps,hideProgressBar: false,
    closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })
  useEffect(()=>{
    let previ = searchParams.get("c")
    if(![null, "null", undefined, "undefined",""].includes(previ))setNomClub(previ)
    setTitreBandeau("Feuilles de présence - "+previ)      

    recuperer(
      `api/poche/${id_camp}/${id_club}/presences/creer/`,
      JETON,
      (res)=>{
        if(res?.erreur)throw "Une erreur est survenue avec la fiche de présence du jour"
        else{
          notif_norm("Feuille du jour récupérée")
          recuperer(
            `api/peluche/${id_camp}/${id_club}/presences/`,
            JETON,
            (res)=>{
              setPresences(res)
              setReady(true)
            },
            (err)=>{notif_gen(err,false)}
          )
        }
      },
      (err)=>{notif_gen(err,false)}
    )
          
  },[])
  
  useEffect(()=>{
    console.log("useeffectpresence")
    let p = [...presences]
    p = p.reduce((acc, obj) => {
      if(!acc?.[obj.jour])acc[obj.jour] = {matin:false,aprem:false,soir:false}
      if(obj.matin)acc[obj.jour]["matin"] = obj
      if(obj.aprem)acc[obj.jour]["aprem"] = obj
      if(obj.soir)acc[obj.jour]["soir"] = obj
      
      // acc[obj.jour] = obj;
      return acc;}, {})
    setPresencesOrd(p)
  },[presences])

  useEffect(()=>{
    let l = Object.keys(presencesOrd)
    l = l.sort().reverse()
    setListeOrd(l)
  },[presencesOrd])
  return (
    <div>
      {/* <h1 className="fs-4 ms-4 me-4 text-white text-center">Feuilles de présence <br/> {nom_club}</h1> */}
      {
        ready?
        listeOrd.map(x=>
          <div key={x}>
            <CartePresencesJournee 
              club={id_club}
              camp={id_camp}
              journee={dateToString(x)} 
              donnees = {presencesOrd[x]}
              nom_club={nom_club}
              />
          </div>
        ):
        <Vortex
            visible={true}
            height="80"
            width="80"
            ariaLabel="vortex-loading"
            wrapperStyle={{}}
            wrapperClass="vortex-wrapper"
            colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
        />
      }
    </div>
  );
};

export default PageListeAppel;