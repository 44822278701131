import React, {useState,useEffect} from "react";
import { useLoaderData, useParams, useSearchParams  } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";

import { toast } from 'react-toastify';


import { Vortex } from  'react-loader-spinner'

import recuperer from '../jsTools/toolsFetch'
// import { Outlet } from "react-router-dom";
import FormFicheInfo from "../components/formulaires/formFicheInfo";


export async function loaderCampFiche({ params }) {
  const id_fiche = params.id_fiche
  const id_camp = params.id_camp
  return {id_camp, id_fiche};
}



const FicheInscription = () => {
  const { id_camp, id_fiche } = useLoaderData();
  const [donnees, setDonnees] = useState({});
  const [recup, setRecup] = useState(false);
  const [nomClub, setNomClub] = useState("")
  const [trad, setTrad] = useState("fr")

  let [searchParams, setSearchParams] = useSearchParams();
  const para = useParams()

  const [dp, setDP] = useState({
    "nom": "",
    "prenom": "",
    "ddn": null,
    "sejour_debut": null,
    "sejour_fin": null,
    "emplacement": "",
    "acceptePhoto": false,
    "sortie": "A",
    "allergie":false,
    "commentaireAllergie": "",
    "traitementMedical": false,
    "commentaireMedical": "",
    "autorisationInterMed": false,
    "autorisationRepresentantLegal": false,
    "commentaire": "",
    "representantLegal": null,
    "club": null,
    "autresContacts": []
})
  
  const noire = false
  const bgnoire = (noire?"bg-dark":"bg-white")
  const txtblanc = (noire?"text-white":"text-dark")
  // const [donneesU, setDonneesU] = useState({});

  // const JETON = useSelector((state) => state.jeton.access)

  const notif_gen = (message)=>toast.error(message,
      {position: "top-right",autoClose: 5000,hideProgressBar: false,
      closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })

  useEffect(()=>{
    // console.log(JETON)
    //   recuperer("api/self", JETON, 
    //       (rep)=>{setDonneesU(rep)},
    //       (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)}
    //       )  
    // console.log("parametere")
    // console.log(para)
    // console.log("POLO")
    // console.log(searchParams)
    // console.log(searchParams.get("marcopolo"))
    // console.log(searchParams.get("coucou"))
    setRecup(false)
    console.log(`api/pipou/${id_camp}/`)

    //btoa(JSON.stringify(obj))
    //JSON.parse(atob(encoded))
    let previ = searchParams.get("dd")
    if([null, "null", undefined, "undefined",""].includes(previ)){
      console.log("le defaut")
    }
    else{
      try{
        console.log("recuperer et envoyer")
        // console.log(atob(previ))
        let temprev = JSON.parse(atob(previ))
        if(typeof(temprev?.autorisationRepresentantLegal)==typeof(true)){console.log("pieuvre");setDP(temprev)}
        else {console.log("moiuju");throw "Mauvais formatage JSON"}
      }
      catch{
        notif_gen("Erreur dans la récupération des données")
      }
    }

    recuperer(`api/pipou/${id_camp}/`, "", 
          (rep)=>{
            console.log(rep);
            setDonnees(rep);
            setRecup(true);
            let temp = rep.data.camping.clubs.filter(x=>x.uuid==id_fiche)
            if(temp.length==1)setNomClub(temp[0].nom)
            else notif_gen("URL erronée, veuillez vérifier l'adresse de la page")
          },
          (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)}
          )       
  },[])

  
  

    


    return(
        <div>
          
          <Modal
            show={true}
            onHide={() => false}

            // dialogClassName="modal-100w"
            fullscreen={true}
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header className={`${bgnoire} ${txtblanc}`} >
              <Modal.Title id="example-custom-modal-styling-title">
                {trad=="fr"?"Fiche d'information":"Information sheet"} - {donnees?.data?.camping?.nom??""} - {nomClub}
              </Modal.Title>
              <Button variant="ligth" onClick={()=>setTrad(trad=="eng"?"fr":"eng")}>{trad=="fr"?"🇬🇧":"🇫🇷"}</Button>
            </Modal.Header>
            <Modal.Body className={`${bgnoire} ${txtblanc}`} >
              {
                !recup
                ? <div>
                    <p>Chargement des données</p>
                    <Vortex
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="vortex-loading"
                      wrapperStyle={{}}
                      wrapperClass="vortex-wrapper"
                      colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                    />
                  </div>
                : <FormFicheInfo donneesPrim={donnees} campuuid={id_camp} clubuuid={id_fiche} donneesPrev={dp} trad={trad} />
              }
              <p>
                {/* {JSON.stringify(donnees)} */}
              </p>

            </Modal.Body>
          </Modal>
            {/* <CarteCamping chemin="api/" /> */}
            {/* <ToastContainer className="text-norm" /> */}
        </div>
        )
    
  };
  
  export default FicheInscription;
  