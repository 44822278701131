import React, {useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook} from '@fortawesome/free-solid-svg-icons'

import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import BandeContact from "./bandeContact";



export default function ModalContact({donnees}){
    const [showCont, setShowCont] = useState(false);
    

    return(
        <div>
          <Button variant="outline-light" onClick={()=>setShowCont(!showCont)}>
            <FontAwesomeIcon icon={faAddressBook} /> Contacts
          </Button>
          <Modal show={showCont} centered >
            <Modal.Header closeButton onHide={()=>setShowCont(false)}>
              <Modal.Title>Contact de {donnees.prenom} {donnees.nom}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup>
                <BandeContact donnees={donnees.representantLegal}/>
                {
                  donnees.autresContacts.map(
                    x=><BandeContact donnees={x} key={x.id} />
                  )
                }
              </ListGroup>
            </Modal.Body>
          </Modal>
        </div>
      )
  };