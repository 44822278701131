import React from "react";
import { Link } from "react-router-dom";


import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';



export default function CartePresencesJournee({club, camp,journee,donnees,nom_club}){
    
    return (
      <Card className="border border-0">
        <Card.Body className="row d-flex justify-content-evenly bg-ora">
          {
            journee[1]?
            <div className="col-12 text-light text-center border-bottom mb-3 py-4">{journee[0]}</div>
            :<div className="col-3 text-light">{journee[0]}</div>
          }
          <div className="col-3">
            <Link to={`/appel/${camp}/${club}/${donnees.aprem.uuid}?d=${journee[0]}&c=${nom_club}`}>
              <Button size={journee[1]?"lg":"sm"} variant="ble text-white">Matin</Button>
            </Link>
          </div>
          <div className="col-3">
            <Link to={`/appel/${camp}/${club}/${donnees.aprem.uuid}?d=${journee[0]}&c=${nom_club}`}>
              <Button size={journee[1]?"lg":"sm"} variant="ble text-white">Aprem</Button>
            </Link>
          </div>
          <div className="col-3">
            <Link to={`/appel/${camp}/${club}/${donnees.aprem.uuid}?d=${journee[0]}&c=${nom_club}`} >
              <Button size={journee[1]?"lg":"sm"} variant="ble text-white">Soir</Button>
            </Link>
          </div>
          {
            journee[1]?
            false
            :<div className="col-6 text-light border-bottom p-1"></div>
          }
        </Card.Body>
      </Card>
    )
  };
