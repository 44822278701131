import React, {useState,useEffect} from "react";


import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck,faChildren} from '@fortawesome/free-solid-svg-icons'

import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';

import FormsClubs from "../formulaires/formClub";


export default function CarteProTableClub({donnees, uuidCont}){
    // const [formatDate, setFormatDate] = useState("");

    const JETON = useSelector((state) => state.jeton.access)


    // const notif_gen = (message)=>toast.error(message,
    //     {position: "top-right",autoClose: 5000,hideProgressBar: false,
    //     closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    // })

    useEffect(()=>{
      // const date = new Date(donnees.dateDerModif);

      // const options = { day: "2-digit", month: "2-digit", year: "numeric",hour: "2-digit",
      // minute: "2-digit",
      // second: "2-digit", };
      // setFormatDate(date.toLocaleString("fr-FR", options))     
    },[])
    
    // useEffect(()=>{
    //       console.log("DONNNNNNEEES")
    //       console.log(donnees)
    //   },[donnees])
    

    return true ? (
        <Card bg="dark" text="white" className="my-5">
          {Object.keys(donnees).length < 1 ? (
            <Card.Body>
              <ListGroup>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
              </ListGroup>
            </Card.Body>
          ) : (
            <Card.Body className="row me-0">
                <div className="row col-10">
                  <strong className="col-xl-2 col-lg-2 col-6">{donnees.nom}</strong>
                  <span className="col-xl-2 col-lg-2 col-6">{donnees.ado?<div><FontAwesomeIcon icon={faCircleCheck} /> Ados</div>:<div><FontAwesomeIcon icon={faChildren} />Enfants</div>}</span>
                  <div className="col-xl-2 col-lg-2 col-10 text-muted">{donnees.description}</div>
                  {/* <div className="col-xl-2 col-lg-2 col-6"><FontAwesomeIcon icon={faEnvelope} /> {donnees.email}</div> */}
                </div>          
               <div className="col-2">
                 <FormsClubs jeton={JETON} actionM="m" camping={uuidCont} element={donnees} /> 
                 <FormsClubs jeton={JETON} actionM="d" camping={uuidCont} element={donnees} /> 
                </div>
               {/* <small text="gray" className="minicoindroit">Modifié le {formatDate}</small> */}
               
            </Card.Body>
          )}
          {/* <ToastContainer className="text-norm notificationGrillee" /> */}
        </Card>
      ) : (
        false
      );
  };
