import React  from "react";

import ClassementTournante from "./classementTournante";



export default function Classement({equipes, typeTournoi,ts}){
    // const [rencontres, setRencontres] = useState([]);
    // const [tournoi, setTournoi] = useState(null)
    // const [nbTour, setNbTour] = useState(3)

    
    // useEffect(()=>{
    //   console.log("useeffect LIstrand")
    // }, [listRand])
    // const size = useContext(ResponsiveContext);
    return (
      <div>
      {
        (typeTournoi==0?
          <ClassementTournante equipes={equipes} ts={ts} />:
          (typeTournoi==1?
            <p>Faire à elim direct</p>:
            <p>Faire poule + elim</p>
            ))
      }
      </div>
    );
  };

// const CardTemplate = ({ title }) => {
//     const size = useContext(ResponsiveContext);
//     return (
//       <Card>
//         <CardHeader pad="medium">
//           <Heading level={2} margin="none">
//             {title}
//           </Heading>
//         </CardHeader>
//         <CardBody pad="medium">
//           <Paragraph maxLines={size === "small" ? 3 : undefined}>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
//             porttitor non nulla ac vehicula. Aliquam erat volutpat. Mauris auctor
//             faucibus est at mattis. Aliquam a enim ac nisi aliquam consectetur et
//             ac velit. Mauris ut imperdiet libero.
//           </Paragraph>
//         </CardBody>
//         <CardFooter pad="medium" background="background-contrast">
//           Footer
//         </CardFooter>
//       </Card>
//     );
//   };