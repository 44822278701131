// import { Header } from "grommet";
import React, {useEffect,useState}  from "react";
// import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { toast } from 'react-toastify';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser,faDoorOpen } from '@fortawesome/free-solid-svg-icons'

// import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
// import S from "../../conf/serveur.json"
import recuperer from "../../jsTools/toolsFetch";
// import { createSerializableStateInvariantMiddleware } from "@reduxjs/toolkit";



export default function NewMdp({uanim,upantin}){
    const [fait, setFait] = useState(false);
    const [form_p1, setFormP1] = useState("");
    const [form_p2, setFormP2] = useState("");
    const [mail, setMail] = useState("");
    // const [form_p, setFormP] = useState("");
    // const [changeJeton, setChangeJeton] = useState(true);


    // const JETON = useSelector((state) => state.jeton.access)
    // const STATUTUSER = useSelector((state) => state.statutUser.access)
    // const dispatch = useDispatch()

    const notif_gen = (message)=>toast.error(message,
        {position: "top-right",autoClose: 5000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_connecte = ()=>{
        console.log("notif connecte")
        toast(`Mot de passe regénérer`,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}

    const handleForm = ()=>{
        if(form_p1!=form_p2){
            notif_gen("les mots de passe ne correspondent pas")
        }
        else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(form_p1)){
            notif_gen("Mot de passe trop faible (au moins : 8 caractères, une majuscule, une minuscule, un chiffre)")
        }
        else{
            recuperer(
                `api/pouliche/${uanim}/${upantin}/mdp`,
                "",
                (rep)=>{
                    console.log("reussie")
                    console.log(rep)
                    if(rep?.erreur){
                        notif_gen(rep.erreur)
                    }
                    else if(rep?.succes){
                        console.log("succes")
                        notif_connecte()
                        setMail(rep.succes)
                        setFait(true)
                    }
                    else{
                        notif_gen("Probleme non prévu")
                        notif_gen(JSON.stringify(rep))
                        notif_gen(rep)
                    }
                },
                (err)=>{
                    notif_gen(err.message)
                    console.error(err)
                },
                "POST",
                {
                    mdp : form_p1,
                    mdp2 : form_p2
                }
            )
        }
        
       
    }
    // const [tournoi, setTournoi] = useState(null)
    // const [nbTour, setNbTour] = useState(3)
    
    
    // useEffect(()=>{
    // //   JETON = useSelector((state) => state.jeton.access)
    // console.log("m  rco")
    // // dispatch(clearJetons());
    // }, [changeJeton])
    // const size = useContext(ResponsiveContext);
    return (               
        <Modal.Dialog style={{maxWidth:"600px", minWidth:"300px"}} >
            <Modal.Header >
                <Modal.Title className="ms-4" >Nouveau mot de passe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    fait?
                    <p className="m-4">
                        Nouveau mot de passe effectif, rendez-vous sur <Link to="/" className="text-ora">la page d'accueil</Link> pour vous connecter avec vos nouveaux identifiants: {mail} et le mot de passe nouvellement entré
                    </p>
                    :<Form>
                        <p className="m-4">Saisissez votre nouveau mot de passe</p>
                        <FloatingLabel label="Mot de passe" className="m-4 text-ora fw-bold" >
                            <Form.Control 
                                type="password" 
                                placeholder="Mot de passe"
                                defaultValue={form_p1}
                                onChange = {(event)=>{setFormP1(event.target.value)}}
                                />
                        </FloatingLabel>
                        <FloatingLabel label="Confirmation mot de passe" className="m-4 text-ora fw-bold" >
                            <Form.Control 
                                type="password" 
                                placeholder="Confirmation mot de passe"
                                defaultValue={form_p2}
                                onChange = {(event)=>{setFormP2(event.target.value)}}
                                />
                        </FloatingLabel>
                    </Form>
                }
            
            </Modal.Body>
            <Modal.Footer>
                {
                    fait?
                    false:
                    <Button variant="ora" className="text-white fw-bolder me-4" onClick={handleForm}>Valider nouveau mot de passe</Button>
                }
            </Modal.Footer>
        </Modal.Dialog>
    );
  };

