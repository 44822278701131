import S from "../conf/serveur.json"


function recuperer(chemin, jeton, callback, callbackErreur=(e)=>console.error(e), methode="GET", body=false){
    if (jeton.length>60){
        let he = new Headers();
        he.append('Content-Type', 'application/json');
        he.append('Authorization', 'Bearer '+jeton);

        let opt = {
            method : methode,
            headers : he
        }
        if(body) opt["body"] = JSON.stringify(body) 
        fetch(
            `${S.serveurApi.domaine}/${chemin}`,
            opt
        )
        .then(
            (response) => {
                // let rep = response.json();
                // if(Object.keys(rep).includes("code") && rep.code=="token not valid"){
                //     callbackreset()
                // }
                if(response.status<500 && response.status>=400){
                    console.log("marco")
                    console.log(response)
                    console.log(response.json())
                    if(response.status==403)window.location.href = S.serveurWeb.domaine+"/?force_login=1";
                    if(response?.detail) throw "Erreur dans la requète - " + response.detail
                    else throw "Erreur dans la requète - " + response
                }
                else if(response.status==204){
                    return {"statut":"succes"}
                }
                else{
                    return response.json()
                }
            }
        )
        .then((j)=>callback(j))
        .catch((err) => {
            callbackErreur(err)
            console.error(err);
            
        });
    }
    else if (jeton.length == 0){
        let he = new Headers();
        he.append('Content-Type', 'application/json');
        let opt = {
            method : methode,
            headers : he
        }
        if(body) opt["body"] = JSON.stringify(body) 
        fetch(
            `${S.serveurApi.domaine}/${chemin}`,
            opt
        )
        .then(
            (response) => {
                if(response.status<500 && response.status>=400){
                    if(response.status==401) throw "Identifiants incorrects"
                    if(response?.detail) throw "Erreur dans la requète - " + response.detail
                    else throw "Erreur dans la requète - " + response
                }
                else if(response.status==204){
                    return {"statut":"succes"}
                }
                else{
                    return response.json()
                }
            }
        )
        .then((j)=>callback(j))
        .catch((err) => {
            callbackErreur(err)
            console.error(err.message);
        });
    }
}


export default recuperer