import React from "react";

// import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";


import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';



export default function CarteAction({donnees,role}){
    // const [donnees, setDonnees] = useState({});

    // const JETON = useSelector((state) => state.jeton.access)

    // const notif_gen = (message)=>toast.error(message,
    //     {position: "top-right",autoClose: 5000,hideProgressBar: false,
    //     closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    // })

    // useEffect(()=>{
    //   console.log(chemin)
    //   console.log(JETON)
    //     recuperer(chemin, JETON, 
    //         (rep)=>{console.log("setlesdonnees");console.log(rep);setDonnees(rep)},
    //         (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)}
    //         )       
    // },[])
    
    // useEffect(()=>{
    //       console.log("DONNNNNNEEES")
    //       console.log(donnees)
    //   },[donnees])
    

    return (
        <Card className="col-lg-4 col-sm-6 col-xxl-3 bg-ora">
            <Card.Body>
              <ListGroup variant="flush" className="bg-ora">
                {/* <ListGroup.Item className="bg-ora text-white" >
                  <Link to={"/pro/responsables/"+donnees.structure.uuid} >
                    <Button variant="ble text-white">
                      Liste Responsable (ajouter/modifier/supprimer)
                    </Button>
                  </Link>
                  
                </ListGroup.Item> */}
                <ListGroup.Item className="bg-ora text-white">
                  <ListGroup variant="flush">
                    Campings
                    {
                      donnees.campings ?
                      donnees.campings.map(x=><ListGroup.Item className="bg-ora text-white" key={x.uuid}>
                        <b>{x.nom}</b>
                          <ListGroup variant="flush">
                            <ListGroup.Item key={x.uuid+"_anim"} className="bg-ora text-white">
                              <Link to={"/pro/anims/"+x.uuid}>
                                <Button variant="ble text-white">
                                  <b>Animateur</b> (ajouter/modifier/supprimer)
                                </Button>
                              </Link>
                            </ListGroup.Item>
                            <ListGroup.Item key={x.uuid+"_club"} className="bg-ora text-white">
                              <Link to={"/pro/clubs/"+x.uuid} >
                                <Button variant="ble text-white" className="mt-3">
                                  <b>Clubs</b> (ajouter/modifier/supprimer)
                                </Button>
                              </Link>
                            </ListGroup.Item>
                          </ListGroup>
                          
                          
                        </ListGroup.Item>)
                        :false
                    }
                    
                  </ListGroup>
                  
                </ListGroup.Item>
                {/* <ListGroup.Item>
                  Liste camping (ajouter/modifier/supprimer)
                </ListGroup.Item> */}
                <ListGroup.Item className="bg-ora text-white">{donnees.email}</ListGroup.Item>    
              </ListGroup>
            </Card.Body>

          {/* <ToastContainer className="text-norm notificationGrillee" /> */}
        </Card>
      )
  };
