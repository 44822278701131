// import { Header } from "grommet";
import React, {useState}  from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil,faTrash,faPlus } from '@fortawesome/free-solid-svg-icons'

// import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
import recuperer from '../../jsTools/toolsFetch'

var donneesDefault = {
    "uuid":"",
    "nom":"",
    "description":"",
    "ado":false,
    // "responsable":
    // "structure":"",
    // "telephone":"",
    // "email":"",
}

// action = m || d || c (modify, delete, create)
export default function FormsClubs({jeton,actionM="m",camping,element=donneesDefault}){
    const [modalForm, setModalForm] = useState(false);
    
    const [f_nom, setFNom] = useState(element["nom"]);
    const [f_description, setFDescription] = useState(element["description"]);
    const [f_ado, setFAdo] = useState(element["ado"]);
    
    // useEffect(()=>{
    // //   JETON = useSelector((state) => state.jeton.access)
    // console.log("m  rco")
    // // dispatch(clearJetons());
    // }, [changeJeton])

    // const JETON = useSelector((state) => state.jeton.access)
    // const STATUTUSER = useSelector((state) => state.statutUser.access)
    // const dispatch = useDispatch()
    const act = (m,d,c)=>{
        // console.log(`actionM ${actionM}`)
        // console.log(`camping ${camping}`)
        // console.log(`jeton ${jeton}`)
        // console.log(actionM==="m")
        if(actionM=="m")return m
        else if (actionM=="d") return d
        else return c
    }
    const notif_gen = (message)=>toast.error(message,
        {position: "top-right",autoClose: 5000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_norm = (message)=>{
        toast(message,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}
    const formCM = ()=>{
        return <Form>
                <Form.Group className="mb-3" controlId="modifFormNom">
                    <Form.Text className="text-muted" style={{"fontSize":"12px"}}>
                        Nom
                    </Form.Text>
                    <Form.Control 
                        type="text" 
                        placeholder="Nom Club" 
                        defaultValue={f_nom}
                        onChange = {(event)=>{setFNom(event.target.value)}}
                        />
                </Form.Group>
                <Form.Group className="mb-3" controlId="modifFormDescription">
                    <Form.Text className="text-muted" style={{"fontSize":"12px"}}>
                        Description
                    </Form.Text>
                    <Form.Control 
                        type="text" 
                        placeholder="Description"
                        as="textarea"
                        defaultValue={f_description}
                        onChange = {(event)=>{setFDescription(event.target.value)}}
                        />
                </Form.Group>
                <Form.Group className="mb-3" controlId="modifFormAdo">
                    <Form.Check 
                        className="text-sm"
                        type="checkbox"
                        id="modifFormAdo"
                        onChange = {(event)=>{setFAdo(event.target.value)}}
                        defaultValue={f_ado}
                        label={`Club ado (sortie libre du club, pas de case "sortie" à cocher dans le formulaire d'inscription)`}
                    />
                </Form.Group>
            </Form>
    }
    // const handleForm = ()=>{
    //     recuperer()
    // }
    const modifier = ()=>{
        recuperer(
            `api/peluche/${camping}/clubs/${element.uuid}/`,
            jeton,
            (rep)=>{
                setModalForm(false)
                notif_norm(`Modification de ${f_nom} confirmée`)
                setTimeout(() => {
                    window.location.reload();
                  }, "1000")
            },
            (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
            "PATCH",
            {
                "ado":f_ado,
                "description":f_description,
                "nom":f_nom
            }
        )
    }
    const supprimer = ()=>{
        recuperer(
            `api/peluche/${camping}/clubs/${element.uuid}/`,
            jeton,
            (rep)=>{
                if(rep?.statut??false){
                    console.log("204 confirmation suppression")
                }
                setModalForm(false)
                notif_norm(`Suppression de ${element.nom} confirmée`)
                setTimeout(() => {
                    window.location.reload();
                  }, "1000")
            },
            (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
            "DELETE",
        )
    }
    const creer = ()=>{
        recuperer(
            `api/peluche/${camping}/clubs/`,
            jeton,
            (rep)=>{
                setModalForm(false)
                notif_norm(`Création de ${f_nom} confirmée`)
                setTimeout(() => {
                    window.location.reload();
                  }, "1000")
            },
            (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)},
            "POST",
            {
                "ado":f_ado,
                "description":f_description,
                "nom":f_nom
            }
        )
    }

    return (
      <div>
            <div>
                <Button variant={act("outline-light","outline-light","dark")} onClick={()=>{setModalForm(!modalForm)}}>
                    {act(
                        <FontAwesomeIcon icon={faPencil} />,
                        <FontAwesomeIcon icon={faTrash} />,
                        <FontAwesomeIcon icon={faPlus} />
                        )}
                </Button>
                <Modal show={modalForm} onHide={()=>{setModalForm(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>{act("Modifier","Supprimer","Créer")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                        act(
                            formCM(),
                            <div>
                                <p>Confirmer la suppression de <strong>{element["nom"]}</strong></p>
                                {/* <Button onClick={supprimer}>
                                    Supprimer
                                </Button> */}
                            </div>,
                            formCM()
                        )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            act(
                                <Button variant="secondary" onClick={modifier}> Modifier le club</Button>,
                                <Button variant="secondary" onClick={supprimer}> Supprimer le club </Button>,
                                <Button variant="secondary" onClick={creer}> Créer le club</Button>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        {/* <ToastContainer className="text-norm notificationGrillee" /> */}
      </div>
    );
  };

