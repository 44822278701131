import React, {useEffect,useState}  from "react";

import shuffle from '../jsTools/toolsArray';
import ElimDirecte from "./tournois/elimDirecte";
import PouleElimDirecte from "./tournois/pouleElimDirect";
import Tournante from "./tournois/tournante";



export default function ListeMatchs({equipes, typeTournoi,gs,ts}){
    // const [rencontres, setRencontres] = useState([]);
    const [listRand, setlistRand] = useState([])
    const [tournoi, setTournoi] = useState(null)
    // const [nbTour, setNbTour] = useState(3)

    var tl = [[],[]] //tournante listes
    // var tournoi = null
    var dicoPivot = {}
    // const initRencontres = ()=>{
    //   if(typeTournoi===0)setTournoi(<Tournante equipes={equipes} listRand={listRand} />)
    //   else if (typeTournoi===1)setTournoi(<ElimDirecte />)
    //   else if (typeTournoi===2)setTournoi(<PouleElimDirecte />)
    //   else console.log("rien ne va plus")
    // }

    useEffect(()=>{
      console.log("LISTE match equipes")
      setlistRand(shuffle(Object.keys(equipes)))

      // initRencontres()
    },[equipes])
    // useEffect(() => {
    //   console.log("Rematching dès qu' équipe et type de tournoi sont modifiés")
    //   let messageErreur = false
    //   let message = false
    //   if (typeTournoi=="Tout le monde rencontre tout le monde"){
    //     if(rencontres.length==0){
    //       for
    //     }
    //     else{
    //        message="Ajout de matchs aux rencontres existentes"
    //     }
    //   }
    //   if(messageErreur){alert(messageErreur)}
    // }, [equipes]);
    useEffect(()=>{
      console.log("useeffect LIstrand")
    }, [listRand])
    // const size = useContext(ResponsiveContext);
    return (
      <div>{tournoi}
      {
        (typeTournoi==0?
          <Tournante equipes={equipes} listRand={listRand} gs={gs} ts={ts} />:
          (typeTournoi==1?
            <ElimDirecte />:
            <PouleElimDirecte />
            ))
      }
      {/* <p>{listRand}</p>
      <p>{listRand.length}</p> */}
      </div>
      
    );
  };

// const CardTemplate = ({ title }) => {
//     const size = useContext(ResponsiveContext);
//     return (
//       <Card>
//         <CardHeader pad="medium">
//           <Heading level={2} margin="none">
//             {title}
//           </Heading>
//         </CardHeader>
//         <CardBody pad="medium">
//           <Paragraph maxLines={size === "small" ? 3 : undefined}>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
//             porttitor non nulla ac vehicula. Aliquam erat volutpat. Mauris auctor
//             faucibus est at mattis. Aliquam a enim ac nisi aliquam consectetur et
//             ac velit. Mauris ut imperdiet libero.
//           </Paragraph>
//         </CardBody>
//         <CardFooter pad="medium" background="background-contrast">
//           Footer
//         </CardFooter>
//       </Card>
//     );
//   };