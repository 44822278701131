import React, {useState, useRef}  from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload} from '@fortawesome/free-solid-svg-icons'

import {QRCodeSVG} from 'qrcode.react';

import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
// import ReactDOM from 'react-dom'
// import CarteProTable from "./utilisateur/carteProTable";
// import CarteProTableResp from "./utilisateur/carteProTableResp";
// import FormAnims from "./formulaires/formAnims";
// import FormResp from "./formulaires/formResp";
// import { Button } from "grommet";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import S from "../../conf/serveur.json"

export default function QrCodeInscription({donneesClub, uuidCamping}){
    const [booModal, setBooModal] = useState(false);

    const qrCodeRef = useRef();

    const handleDownload = () => {
      html2canvas(qrCodeRef.current)
        .then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, `qrcode_inscription_${donneesClub.nom}.png`);
          });
        });
    };
    // const [alreadyLoad, setAlreadyLoad] = useState(false);
    // const [donneesEnfants, setDonneesEnfants] =useState([])
    // const [donneesEnfantsP, setDonneesEnfantsP] =useState([])
    // const [recEnfant, setRecEnfant] =useState("")
    // const [messageLoad, setMessageLoad] = useState("Chargement des données")
    // // const [rencontres, setRencontres] = useState([]);
    
    // const JETON = useSelector((state) => state.jeton.access)

    // useEffect(()=>{
    //   setDonneesEnfantsP(donneesEnfants)
    // },[donneesEnfants])
    
    


    return (
      // <p>{typeElement}
      // </p>
      <div className="col-12 p-0">
        <Button className="m-2" size="sm" onClick={()=>{setBooModal(!booModal)}} variant="ora text-white fw-bold"> QrCode Inscription</Button>
          <Modal
            size="lg"
            show={booModal}
            onHide={() => setBooModal(false)}
            fullscreen={true}
            // className="modal-max-h"
            //aria-labelledby="example-mo:dal-sizes-title-lg"
          >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body  style={{ maxHeight: "85vh",overflowY: "auto" }}>
            {/* <p>{donneesEnfants.length}</p> */}
            {/* <p>{JSON.stringify(donneesEnfantsP)}</p> */}
              <div ref={qrCodeRef} className="p-2">
                <p className="text-center fs-3">Inscription - {donneesClub.nom}</p>
                <QRCodeSVG 
                  value={`${S.serveurWeb.domaine}/fiche/${uuidCamping}/${donneesClub.uuid}`} 
                  size={260}
                  className="mx-4"
                  // bgColor="ora"
                  />
              </div>
              <p className="my-5">
                <a href={`${S.serveurWeb.domaine}/fiche/${uuidCamping}/${donneesClub.uuid}`}>{`${S.serveurWeb.domaine}/fiche/${uuidCamping}/${donneesClub.uuid}`}</a>
              </p>
              
          </Modal.Body>
          <Modal.Footer style={{ position: 'sticky', bottom: 0 }}>
            <Button variant="ble text-white" size="lg" onClick={handleDownload} > 
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

