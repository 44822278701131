import React from "react";
// import {
//   PageContent,
//   PageHeader,
//   Grid
// } from "grommet";
import { Link } from "react-router-dom";

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
// import CardTemplate from "../components/carteMenu";
// import { Outlet } from "react-router-dom";


const Mots = () => {
    return(
        <Container>
            <p className="p-4">Generation de listes sur des thèmes particuliers (possibilité de filtrer, de choisir des niveaux de difficulté,...) avec au clic sur un mot une mise en valeur du mot </p>
            <div className="row">
                <Link to="animaux" className="liennorm col-6 p-2">
                    <Card >
                        <Card.Img variant="top" src="/img/boxon/tigrou.jpg" />
                        <Card.Body>
                            <Card.Text className="textecartemot" >
                                Animaux
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
                <Link to="enfant_sans_lecture" className="liennorm col-6 p-2">
                    <Card>
                        <Card.Img variant="top" src="/img/boxon/tigrou.jpg" />
                        <Card.Body>
                            <Card.Text className="textecartemot">
                                Enfant ne lisant pas
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
                <Link to="films" className="liennorm col-6 p-2">
                    <Card>
                        <Card.Img variant="top" src="/img/boxon/cinema.jpg" />
                        <Card.Body>
                            <Card.Text className="textecartemot">
                                Films
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
                <Link to="pays" className="liennorm col-6 p-2">
                    <Card>
                        <Card.Img variant="top" src="/img/boxon/pays.png" />
                        <Card.Body>
                            <Card.Text className="textecartemot">
                                Pays
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
                <Link to="sportifs" className="liennorm col-6 p-2">
                    <Card>
                        <Card.Img variant="top" src="/img/boxon/sportif.png" />
                        <Card.Body>
                            <Card.Text className="textecartemot">
                                Sportifs/ Sportives
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
                <Link to="personnalite" className="liennorm col-6 p-2">
                    <Card>
                        <Card.Img variant="top" src="/img/boxon/perso.jpg" />
                        <Card.Body>
                            <Card.Text className="textecartemot">
                                Personnalités
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
                <Link to="pays-capitale" className="liennorm col-6 p-2">
                    <Card>
                        <Card.Img variant="top" src="/img/boxon/pays.png" />
                        <Card.Body>
                            <Card.Text className="textecartemot">
                                Pays/ Capitale
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
                
            </div>
            
        </Container>
        // <PageContent>
        //     <PageHeader title="Liste de mots" />
        //     <Grid columns="medium" gap="large" pad={{ bottom: "large" }}>

        //         <CardTemplate title="Animaux" lien="animaux" />
        //         <CardTemplate title="Films" lien="films" />
        //         <CardTemplate title="Card 3" />
        //     </Grid>  
        //     {/* <Outlet/> */}
        // </PageContent>
        )
    
  };
  
  export default Mots;
  