import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import Basic from "./layouts/basic.js"
import Accueil from "./pages/accueil.js"
import Erreur from "./pages/erreur.js"
import Mots from './pages/mots.js'
import Scores from './pages/scores.js'
import Tournoi from './pages/tournoi.js'
import Utilisateur from './pages/utilisateur.js'
import ListeMots, {loader as lm_loader} from './pages/listeMots.js'
import ListePro, {loader as camp_loader} from './pages/listePro';
import FicheInscription, {loaderCampFiche} from './pages/ficheInscription';
import ListeActivite, {loader as activite_loader} from "./pages/listeActivite"
import MdpNouveau, {loaderuuid } from "./pages/mdpNouveau"
import PageAppel, {appel_loader } from "./pages/pageAppel"
import PageListeAppel, {appel_liste_loader} from "./pages/pageListeAppel"
import MdpDemande  from "./pages/mdpDemande"
import DemandeAcces  from "./pages/demandeAcces"
// import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

import { Provider as StoreProvider } from 'react-redux'
import store from './store/store'

// import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 

const router = createBrowserRouter([
  {
    path: "/",
    element: <Basic />,
    // errorElement: <Erreur />,
    children: [
      {
        path: "",
        element: <Accueil />,
      },
      {
        path: "loulou",
        element: <Utilisateur />,
      },
      {
        path: "mots",
        element: <Mots />,
        children: [
          
        ],
      },
      {
        path: "mots/:id_lm",
        element: <ListeMots />,
        loader: lm_loader,
      },
      {
        path: "scores",
        element: <Scores />
      },
      {
        path: "tournoi",
        element: <Tournoi  />
      },
      {
        path: "motdepasse/demande",
        element: <MdpDemande  />
      },
      {
        path: "motdepasse/saisie/:uanim/:upantin",
        element: <MdpNouveau />,
        loader: loaderuuid
      },
      {
        path: "tournois/:id_camp",
        element: <ListeActivite typeAct="Tournois" />,
        loader: activite_loader
      },
      {
        path: "scores/:id_camp",
        element: <ListeActivite typeAct="Scores" />,
        loader: activite_loader
      },
      {
        path: "pro/responsables/:id_camp",
        element: <ListePro typePro="Responsables" />,
        loader: camp_loader
      },
      {
        path: "pro/clubs/:id_camp",
        element: <ListePro typePro="Clubs" />,
        loader: camp_loader
      },
      {
        path: "pro/anims/:id_camp",
        element: <ListePro typePro="Anims" />,
        loader: camp_loader
      },
      {
        path: "club/contacts/:id_camp",
        element: <ListePro  />,
        loader: camp_loader
      },
      {
        path: "fiche/:id_camp/:id_fiche",
        element: <FicheInscription />,
        loader: loaderCampFiche
      },
      {
        path: "appel/:id_camp/:id_club/liste",
        element: <PageListeAppel />,
        loader: appel_liste_loader
      },
      {
        path: "appel/:id_camp/:id_club/:id_presence",
        element: <PageAppel />,
        loader: appel_loader
      },
      {
        path: "demande_acces",
        element: <DemandeAcces />
      },
      {
        path:"*",
        element:<Navigate to="/" replace />
      }
    ],
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <RouterProvider router={router} />
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
