import React, {useState,useEffect} from "react";
import { useSelector } from 'react-redux'
// import {
//   Grid,
//   PageContent,
//   PageHeader,
// } from "grommet";
import { useLoaderData } from "react-router-dom";
import {  toast } from 'react-toastify';

// import CardTemplate from '../components/carteMenu'
// import Button from 'react-bootstrap/Button';
// import { setJetonAccess } from '../store/jetonSlice'
import ListeElements from "../components/listeElements";
import recuperer from '../jsTools/toolsFetch'

export async function loader({ params }) {
  //const contact = await getContact(params.contactId);
  const id_camp = params.id_camp
  return {id_camp};
}

const ListePro = ({typePro}) => {
  const { id_camp } = useLoaderData();
  const [donnees, setDonnees] = useState([])

  const JETON = useSelector((state) => state.jeton.access)

  const urls={
    "Responsables":`api/prunelle/${id_camp}/resp`,
    "Anims":`api/prout/${id_camp}/anims`,
    "Enfants":`api/peluche/${id_camp}/enfants`,
    "Clubs":`api/peluche/${id_camp}/clubs`,
  }
  const notif_gen = (message)=>toast.error(message,
    {position: "top-right",autoClose: 5000,hideProgressBar: false,
    closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })
  useEffect(()=>{
    console.log(JETON)

    recuperer(urls[typePro], JETON, 
        (rep)=>{
          console.log(rep);
          if(rep?.result)setDonnees(rep.result)
          else setDonnees(rep)
        },
        (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)}
        )       
  },[])

  return (
        // <PageContent>
        //   <PageHeader title={typePro} />
        <div>
          <h1 className="p-2">Liste {typePro}</h1>
          <ListeElements 
                typeElement={typePro} 
                donnees={donnees}
                uuidCont={id_camp}
                 />
        </div>
            
        // </PageContent>
  );
};

export default ListePro;