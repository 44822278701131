import React, { useState }  from "react";

// import ReactDOM from 'react-dom'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import C from "../conf/tournoi.json"


export default function Rencontre({equipe1, equipe2,gs, score1=0, score2=0,cs1=()=>{},cs2=()=>{}, terrain=false, joue=false, journee=false, numTour=null}){
    const [showModal, setShowModal] = useState(false);  
    const [s1, setS1] = useState(score1)
    const [s2, setS2] = useState(score2)
  // const size = useContext(ResponsiveContext);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const handleSS = () => {
      // cs1(s1)
      // cs2(s2)
      console.log("sendUpthere")
      gs([equipe1,s1],[equipe2,s2],numTour)
      setShowModal(false)
    };

    const getNotNobody = () =>{
      if (equipe1===C.nobody)return equipe2["nom"]
      else if (equipe2===C.nobody)return equipe1["nom"]
      else return false
    }
    // useEffect((e) => {
    //   console.log("s1s2")
    // }, [s1,s2]);
    const styleText = {
      textFit:"contain",
      fontSize: "min(0.3em, 5vw, 30px)"
    }
    const styleDefText = {fontSize:"min(1em, 7vw)",fontWeight: "bold"}
    return (  
            (getNotNobody()?
              <Card body color="secondary" className="text-grand mb-4">
                <div style={styleText}><i>Ajouter bouton pour ajouter une equipe à la place de NOBODY, passer le tempNobody à null</i>Nombre d'équipes impair, <b>{getNotNobody()}</b> jouera en premier au prochain tour</div>
              </Card>:
              <Card body className="mb-4">
                <Container fluid onClick={handleShow}> 
                  <Row>
                    <Col md={4} sm={5} xs={5} className="text-center">
                      <div style={styleText} className="text-grand" >{equipe1["nom"]}</div>
                    </Col>
                    <Col md={4} sm={2} xs={2} style={styleDefText} className="text-center">
                      {/* <Row className="text-center" md={{span: 4, offset: 4}}>-</Row> */}
                      -
                    </Col>
                    <Col md={4} sm={5} xs={5} className="text-center text-grand">
                      <div style={styleText} className="text-grand" >{equipe2["nom"]}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={5} xs={5} style={styleDefText} className="text-center text-grand">
                      {s1}
                    </Col>
                    <Col md={{span:4,offset:4}} style={styleDefText} sm={{span:5,offset:2}} xs={{span:5,offset:2}} className="text-center text-grand">
                      {s2}
                    </Col>
                  </Row>
                </Container>
                <Modal show={showModal} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modification du score</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">{equipe1["nom"]}</InputGroup.Text>
                        <Form.Control
                          defaultValue={s1}
                          type="number"
                          onChange = {(event)=>{setS1(event.target.value)}}
                          aria-label={equipe1["nom"]}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon2">{equipe2["nom"]}</InputGroup.Text>
                        <Form.Control
                          // placeholder={score2}
                          defaultValue={s2}
                          type="number"
                          onChange = {(event)=>{setS2(event.target.value)}}
                          aria-label={equipe2["nom"]}
                          aria-describedby="basic-addon2"
                        />
                      </InputGroup>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSS}> Valider modification</Button>
                  </Modal.Footer>
                </Modal>
              </Card>
            )
    );
  };

