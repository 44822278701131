import { createSlice } from '@reduxjs/toolkit'

export const jetonSlice = createSlice({
  name: 'jeton',
  initialState: {
    access: (sessionStorage.getItem('jetontuma')!=null?sessionStorage.getItem('jetontuma'):""),
    refresh: (sessionStorage.getItem('jetonfrais')!=null?sessionStorage.getItem('jetonfrais'):""),
    connecte: false
  },
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    setJetonAccess: (state, action) => {
      state.access = action.payload
      sessionStorage.setItem('jetontuma', action.payload);
    },
    setJetonRefresh: (state, action) => {
      state.refresh = action.payload
      sessionStorage.setItem('jetonfrais', action.payload);
    },
    setJetonConnecte: (state, action) => {
      state.connecte = action.payload
    },
    clearJetons: (state, action) => {
      state.connecte = ""
      state.refresh = ""
      state.connecte = false
      sessionStorage.removeItem('jetontuma');
      sessionStorage.removeItem('jetonfrais');
    },
  },
})

// Action creators are generated for each case reducer function
export const {  setJetonAccess, setJetonRefresh, setJetonConnecte, clearJetons } = jetonSlice.actions

export default jetonSlice.reducer