// import { Header } from "grommet";
import React, {useEffect,useState}  from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { toast } from 'react-toastify';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser,faDoorOpen } from '@fortawesome/free-solid-svg-icons'

import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
import S from "../../conf/serveur.json"



export default function Connexion({waitingScreen=()=>{}}){
    const [modalConnexion, setModalConnexion] = useState(false);
    const [form_u, setFormU] = useState("");
    const [form_p, setFormP] = useState("");
    const [changeJeton, setChangeJeton] = useState(true);


    const JETON = useSelector((state) => state.jeton.access)
    const STATUTUSER = useSelector((state) => state.statutUser.access)
    const dispatch = useDispatch()

    const notif_gen = (message)=>toast.error(message,
        {position: "top-right",autoClose: 5000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_connecte = ()=>{
        console.log("notif connecte")
        toast("Connexion réussie",
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}
    const effaceJeton = ()=>{

    }
    const handleForm = ()=>{
        console.log("fetch")
        waitingScreen(true)
        // console.log(JETON)
        fetch(
            `${S.serveurApi.domaine}/api/token/`,
            {
                method : "POST",
                // mode:"no-cors",
                body : JSON.stringify({
                    "username":form_u,
                    "password":form_p
                }),
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json'
                  },
            }
        )
            .then((response) => {
                console.log(response)
                
                if(response.status==204){
                    return {"statut":"succes"}
                }
                else{
                    return response.json()
                }
                // return response.json()
            })
            .then((actualData) => {
                if(actualData?.detail) throw actualData.detail
                try{
                    
                    if(actualData.access.length<60) throw "Erreur de connexion, verifiez vos identifiants"
                    else if (actualData.refresh<60) throw "Erreur de connexion, verifiez vos identifiants"
                    dispatch(setJetonAccess(actualData.access))
                    dispatch(setJetonRefresh(actualData.refresh))
                    
                    // dispatch(setJetonRefresh(actualData.refresh))
                    // dispatch(setJetonRefresh(actualData.refresh))
                    
                }catch(e){
                    throw `Une erreur s'est produite lors de la connexion.\n${e.message}`
                }
            })
            .then((d)=>{
                notif_connecte()
                // setModalConnexion(false)
                console.log("connexion réussie")
                
                // callback()

            })
            .catch((err) => {
                if(err?.message)notif_gen(`Une erreur s'est produite avec le serveur.\n${err.message}`)
                else notif_gen(`Une erreur s'est produite avec le serveur.\n${err}`)
                console.log(err.message);
                console.error(err)
                waitingScreen(false)
            });
    }
    // const [tournoi, setTournoi] = useState(null)
    // const [nbTour, setNbTour] = useState(3)
    
    
    // useEffect(()=>{
    // //   JETON = useSelector((state) => state.jeton.access)
    // console.log("m  rco")
    // // dispatch(clearJetons());
    // }, [changeJeton])
    // const size = useContext(ResponsiveContext);
    return (
      <div>

        {/* {
            JETON.length<60? */}
            <div>
                {/* <Button variant="outline-light" onClick={()=>{setModalConnexion(!modalConnexion)}}>
                    Connexion
                </Button> */}
                <Modal.Dialog style={{maxWidth:"600px", minWidth:"300px"}}/*show={modalConnexion} onHide={()=>{setModalConnexion(false)}}*/ >
                    <Modal.Header /*closeButton*/>
                        <Modal.Title className="ms-4" >Connexion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form>
                        <FloatingLabel label="Email de connexion" className="m-4 text-ora fw-bold" >
                            <Form.Control 
                                type="email" 
                                placeholder="Email"
                                defaultValue={form_u}
                                onChange = {(event)=>{setFormU(event.target.value)}}
                                />
                        </FloatingLabel>
                        <FloatingLabel label="Mot de passe" className="m-4 text-ora fw-bold">
                            <Form.Control 
                                type="password" 
                                placeholder="Mot de passe" 
                                defaultValue={form_p}
                                onChange = {(event)=>{setFormP(event.target.value)}}
                                />
                        </FloatingLabel>
                    </Form>
                    </Modal.Body>
                    <Modal.Footer className="row">
                        <Link className="col-5 text-white" to="/motdepasse/demande">
                            Mot de passe oublié ?
                        </Link>
                        <Button variant="ora" className="col-6 text-white fw-bolder me-4" onClick={handleForm}> Se connecter</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
            {/* // :
            //     <Link to="loulou">
            //         <Button variant="light">
            //             <FontAwesomeIcon icon={faUser} />
            //         </Button>
            //     </Link>
                
            
            // } */}
        {/* <ToastContainer className="text-norm notificationGrillee" /> */}
        <div>
            <Link to="/demande_acces" className="col-12 text-break text-white mt-5 pt-5 demandeacces">
                Demander un accès à la plateforme (gratuit pour toute la saison 2024)
            </Link>
        </div>
      </div>
    );
  };

