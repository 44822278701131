import React, {useEffect,useState}  from "react";

// import ReactDOM from 'react-dom'
import Table from 'react-bootstrap/Table';
import C from '../conf/tournoi.json'



export default function ClassementTournante({equipes,ts}){
    const [ranking, setRanking] = useState({})
    //ranking = [...[uuid,score, pointmarque,pointencaisse]]
    const calculsEquipe = (uuid)=>{
      let res = {
        "score":0,"matchJoue":0,
        "victoire":0,"defaite":0,"egalite":0,
        "pts_marques":0,"pts_encaisses":0,
        "nom":equipes[uuid]["nom"],"uuid":uuid
      }
      Object.values(ts[uuid]).forEach((adv) => {
        Object.values(adv).forEach((tour)=>{
          res["matchJoue"] += 1
          res["pts_marques"] += Number(tour[0])
          res["pts_encaisses"] += Number(tour[1])
          
          if(tour[0]<tour[1]){
            res["defaite"] += 1
            res["score"] += C.score.perdu
          }
          else if (tour[1]<tour[0]){
            res["victoire"] +=1
            res["score"] += C.score.gagne
          }
          else {
            res["egalite"] +=1
            res["score"] += C.score.egalite
          }
        })
      });
      return res
    }
    const calculScore = ()=>{
      let tempRank = {}
      Object.keys(ts).forEach((uuid) => {
        tempRank[uuid] = calculsEquipe(uuid);
      })
      setRanking(tempRank)
    }

    const fctTriTableau = (a,b)=>{
      let apts = a.pts_marques-a.pts_encaisses
      let bpts = b.pts_marques-b.pts_encaisses
      
      if(a.score<b.score)return 1
      else if (a.score>b.score) return -1
      else {
        if (apts<bpts)return 1
        else if (apts>bpts)return -1
        else return 0
      }
    }
    
    
    useEffect(()=>{
      calculScore()
    }, [ts])
    // const size = useContext(ResponsiveContext);
    return (
      <div>
        
      <Table className="text-norm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Position</th>
            <th>Équipe</th>
            <th>Score</th>
            <th>Quot. points</th>
            <th>Matchs joués</th>
          </tr>
        </thead>
        <tbody>
        {
            Object.values(ranking).sort(fctTriTableau).map((value, index) =>(
              <tr key={"lignescore"+value.uuid}>
                <td>{index+1}</td>
                <td>{value.nom}</td>
                <td>{value.score}</td>
                <td>{value.pts_marques-value.pts_encaisses}</td>
                <td>{value.matchJoue}</td>
              </tr>
              // <p key={value.uuid}> {JSON.stringify(value)}</p>
            ))
          }
        </tbody>
      </Table>
      </div>
      
    );
  };
