import React, {useState,useEffect,useRef } from "react";


import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTent, faCakeCandles, faAddressBook} from '@fortawesome/free-solid-svg-icons'

import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';



import FormEnfant from "../formulaires/formEnfant";
import ModalContact from "./modalContact";


export default function CarteProTableEnfant({donnees, uuidCont}){
    const [sejFormatDate, setSejFormatDate] = useState([]);
    const [ddnFormatDate, setDdnFormatDate] = useState("");
    const [age, setAge] = useState(0)

    const refCont = useRef(null);
  

    const JETON = useSelector((state) => state.jeton.access)
    const ROLE = useSelector((state) => state.statutUser.statutu)

    const MATRICECONTACT = {
        'P': 'Père',
        'M': 'Mère',
        'T': 'Tuteur/Tutrice',
        'GP': 'Grand-Père',
        'GM': 'Grand-Mère',
        'GF': 'Grand Frère',
        'GS': 'Grande Sœur',
        'RL': 'Responsable Légale',
        'A': 'Autre',
    }
    // const notif_gen = (message)=>toast.error(message,
    //     {position: "top-right",autoClose: 5000,hideProgressBar: false,
    //     closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    // })

    useEffect(()=>{
      // const date = new Date(donnees.dateDerModif);
      let ddnDate = new Date(donnees.ddn)
      let debDate = new Date(donnees.sejour_debut)
      let finDate = new Date(donnees.sejour_fin)
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      // setFormatDate(date.toLocaleString("fr-FR", options))
      setDdnFormatDate(ddnDate.toLocaleString("fr-FR", options))
      setAge(Math.floor((new Date() - ddnDate) / (365.25 * 24 * 60 * 60 * 1000)))
      setSejFormatDate([
        debDate.toLocaleString("fr-FR", options), 
        finDate.toLocaleString("fr-FR", options)
      ])
    },[])
    
    // useEffect(()=>{
    //       console.log("DONNNNNNEEES")
    //       console.log(donnees)
    //   },[donnees])
    

    return(
        <Card bg="dark" text="white" className="my-2">
          {Object.keys(donnees).length < 1 ? (
            <Card.Body>
              <ListGroup>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
              </ListGroup>
            </Card.Body>
          ) : (
            <Card.Body className="row">
                <div className="col-8 row d-flex justify-content-around">
                  <span className="col-12 col-xl-2 col-lg-2 col-md-6 fs-3"><strong>{donnees.prenom}</strong> <span>{donnees.nom}</span></span>
                  <div className="col-8 col-xl-2 col-lg-2 col-md-6"> <FontAwesomeIcon icon={faCakeCandles} /> {ddnFormatDate} <br/>(<strong>{age}</strong> ans)</div>
                  <div className="col-4 col-xl-2 col-lg-2 col-md-6"> <FontAwesomeIcon icon={faTent} /> {donnees.emplacement}</div>
                </div>
                <div className="col-4 row d-flex justify-content-around">
                  <div className="col-12 col-xl-2 col-lg-2 col-md-6" ref={refCont}>
                    <ModalContact donnees={donnees} />
                  </div>
                  {/* <div className="col-xl-2 col-lg-2 col-md-6"><FontAwesomeIcon icon={faEnvelope} /> {donnees.email}</div> */}
                  <div className="col-12 col-xl-2 col-lg-2 col-md-4 col">
                    <FormEnfant jeton={JETON} actionM="m" camping={uuidCont} element={donnees} /> 
                      {
                        ROLE>1
                        ?<FormEnfant jeton={JETON} actionM="d" camping={uuidCont} element={donnees} /> 
                        :false
                      }
                  </div>
                </div>
               
               
               <small text="gray" className="minicoindroit text-end">{sejFormatDate[0]} ⇒ {sejFormatDate[1]}</small>
               
            </Card.Body>
          )}
          {/* <ToastContainer className="text-norm notificationGrillee" /> */}
        </Card>
      )
  };
