// import { Header } from "grommet";
import React, {useState}  from "react";
// import { useSelector, useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { toast } from 'react-toastify';

import libelleTrad from "../trad/trad_formulaireContact.json"
// import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
// import S from "../../conf/serveur.json"
// import L from "../../jsTools/toolsString.js"
// import recuperer from '../../jsTools/toolsFetch'
// import Row from "react-bootstrap/esm/Row";




// action = m || d || c (modify, delete, create)
export default function FormContact({principal=false,emplacement="",confirmValues, gommer=false, titre="", trad="fr"}){
    // const [modalForm, setModalForm] = useState(false);
    
    // const [form_prenom, setFormPrenom] = useState("");
    // const [form_nom, setFormNom] = useState("");
    const [formDonnees, setFormDonnees] = useState({
        "nom": "",
        "prenom": "",
        "telephone": "",
        "relation": "M",
        "email": "",
        "emplacement": emplacement
        });
    
    const noire = false;
    const nomclasse = noire ? "bg-dark text-white" : ""

    const valeurFormDonnees = (clef, valeur)=>{
    setFormDonnees((prevValues) => ({
            ...prevValues,
            [clef]: valeur,
          }));
    }

    const confirmation = ()=>{
        console.log("Confimation")
        console.log(formDonnees)
        if(formDonnees.nom.length<1)notif_gen(libelleTrad["notif_nom"][trad])
        else if(formDonnees.prenom.length<1)notif_gen(libelleTrad["notif_prenom"][trad])
        else if(String(formDonnees.emplacement).length<1)notif_gen(libelleTrad["notif_emplacement"][trad])
        else if(formDonnees.telephone.length<10 || formDonnees.telephone.length>15)notif_gen(libelleTrad["notif_tel"][trad])
        else if((formDonnees.email.length<1 || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formDonnees.email)))&&principal)notif_gen(libelleTrad["notif_email"][trad])
        else {
            
            confirmValues({...formDonnees})
            if(gommer){
                valeurFormDonnees("telephone","")
                valeurFormDonnees("prenom","")
            }
        }
    }
    

    const notif_gen = (message)=>toast.error(message,
        {position: "top-right",autoClose: 8000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_norm = (message)=>{
        toast(message,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}
    
    
    // const size = useContext(ResponsiveContext);
    return (
      <form>
        {
            principal
            ?
            <Alert variant="dark" className="text-norm">
                {libelleTrad["message_certif"][trad]}
            </Alert>
            :false
        }
        <h3>{titre}</h3>
            <div className="row d-flex align-items-end">
                <div className="col-6 my-2">
                    <span id="fc_prenom_aide" className="text-sm">
                        {libelleTrad["prenom"][trad]}
                    </span>
                    <Form.Control
                        className={`${nomclasse} text-capitalize`}
                        type="text"
                        id="fc_prenom"
                        placeholder={libelleTrad["prenom"][trad]}
                        defaultValue={formDonnees.prenom}
                        onChange = {(event)=>{valeurFormDonnees("prenom",event.target.value)}}
                        aria-describedby="ffi_prenom_aide"
                    />
                </div>
                <div className="col-6 my-2">
                    <span id="fc_nom_aide" className="text-sm">
                        {libelleTrad["nom"][trad]}
                    </span>
                    <Form.Control
                        className={`${nomclasse} text-capitalize`}
                        type="text"
                        id="fc_nom"
                        placeholder={libelleTrad["nom"][trad]}
                        defaultValue={formDonnees.nom}
                        onChange = {(event)=>{valeurFormDonnees("nom",event.target.value)}}
                        aria-describedby="ffi_nom_aide"
                    />
                </div>
                <div className="col-6 my-2">
                    <span id="fc_tel_aide" className="text-sm">
                        {libelleTrad["telephone"][trad]}
                    </span>
                    <Form.Control
                        className={`${nomclasse} text-capitalize`}
                        type="tel"
                        id="fc_tel"
                        placeholder={libelleTrad["telephone"][trad]}
                        defaultValue={formDonnees.tel}
                        onChange = {(event)=>{valeurFormDonnees("telephone",event.target.value)}}
                        aria-describedby="ffi_tel_aide"
                    />
                </div>
                <div className="col-6 my-2">
                    <span id="fc_lien_aide" className="text-sm">
                        {libelleTrad["lien"][trad]}
                    </span>
                    <Form.Select 
                        aria-label="selection"
                        className={`${nomclasse} text-capitalize`}
                        id="fc_lien"
                        onChange = {(event)=>{valeurFormDonnees("relation",event.target.value)}}
                        aria-describedby="ffi_lien_aide"
                    >
                        <option value="M">{libelleTrad["mère"][trad]}</option>
                        <option value="P">{libelleTrad["père"][trad]}</option>
                        <option value="T">{libelleTrad["tuteur"][trad]}</option>
                        <option value="GP">{libelleTrad["grandpere"][trad]}</option>
                        <option value="GM">{libelleTrad["grandmere"][trad]}</option>
                        <option value="GF">{libelleTrad["grandfrere"][trad]}</option>
                        <option value="GS">{libelleTrad["grandesoeur"][trad]}</option>
                        <option value="RL">{libelleTrad["rep leg"][trad]}</option>
                        <option value="A">{libelleTrad["autre"][trad]}</option>
                    </Form.Select>            
                </div>
                {
                    principal
                    ?
                    <div className="col-6 my-2">
                        <span id="fc_email_aide" className="text-sm">
                            Email
                        </span>
                        <Form.Control
                            className={`${nomclasse}`}
                            type="text"
                            id="fc_email"
                            placeholder="Email"
                            defaultValue={formDonnees.email}
                            onChange = {(event)=>{valeurFormDonnees("email",event.target.value)}}
                            aria-describedby="ffi_email_aide"
                        />
                    </div>
                    : false
                }
                <div className="col-6 my-2">
                        <span id="fc_emp_aide" className="text-sm">
                            {libelleTrad["emplacement"][trad]}
                        </span>
                        <Form.Control
                            className={`${nomclasse}`}
                            type="text"
                            id="fc_emp"
                            placeholder={libelleTrad["emplacement"][trad]}
                            defaultValue={emplacement}
                            onChange = {(event)=>{valeurFormDonnees("emplacement",event.target.value)}}
                            aria-describedby="ffi_emp_aide"
                        />
                </div>
            </div>
            <Button variant="outline-ora" className="my-3" onClick={confirmation}>
                {libelleTrad["validerinfo"][trad]}
            </Button>
            {/* <Button variant="outline-dark" onClick={()=>{
                valeurFormDonnees("nom","Fouqué");
                valeurFormDonnees("prenom","Marc");
                valeurFormDonnees("telephone","0776767676");
                valeurFormDonnees("email","marc@polo.it");
                valeurFormDonnees("emplacement","42");
                confirmation()}}>
                Marco
            </Button> */}
            {/* <ToastContainer className="text-norm" /> */}
      </form>
    );
  };