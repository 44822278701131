import React, {useState,useEffect} from "react";


// import ReactDOM from 'react-dom'
// import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
// import { ToastContainer, toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope,faPhone} from '@fortawesome/free-solid-svg-icons'

import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';

import FormResp from "../formulaires/formResp";


export default function CarteProTableResp({donnees, uuidCont}){
    const [formatDate, setFormatDate] = useState("");

    const JETON = useSelector((state) => state.jeton.access)


    // const notif_gen = (message)=>toast.error(message,
    //     {position: "top-right",autoClose: 5000,hideProgressBar: false,
    //     closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    // })

    useEffect(()=>{
      const date = new Date(donnees.dateDerModif);

      const options = { day: "2-digit", month: "2-digit", year: "numeric",hour: "2-digit",
      minute: "2-digit",
      second: "2-digit", };
      setFormatDate(date.toLocaleString("fr-FR", options))     
    },[])
    
    // useEffect(()=>{
    //       console.log("DONNNNNNEEES")
    //       console.log(donnees)
    //   },[donnees])
    

    return true ? (
        <Card bg="dark" text="white" className="my-5">
          {Object.keys(donnees).length < 1 ? (
            <Card.Body>
              <ListGroup>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={9} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
              </ListGroup>
            </Card.Body>
          ) : (
            <Card.Body className="col d-flex justify-content-around">
                        
               <strong className="col-xl-2 col-lg-2 col-md-6">{donnees.prenom}</strong>
               <span className="col-xl-2 col-lg-2 col-md-6">{donnees.nom}</span>
               <div className="col-xl-2 col-lg-2 col-md-6"><FontAwesomeIcon icon={faPhone} /> {donnees.telephone}</div>
               <div className="col-xl-2 col-lg-2 col-md-6"><FontAwesomeIcon icon={faEnvelope} /> {donnees.email}</div>
               <div className="col-xl-2 col-lg-2 col-md-4 col">
                 <FormResp jeton={JETON} actionM="m" camping={uuidCont} element={donnees} /> 
                 <FormResp jeton={JETON} actionM="d" camping={uuidCont} element={donnees} /> 
                </div>
               <small text="gray" className="minicoindroit">Modifié le {formatDate}</small>
               
            </Card.Body>
          )}
          {/* <ToastContainer className="text-norm notificationGrillee" /> */}
        </Card>
      ) : (
        false
      );
  };
