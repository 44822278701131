import React, {useState,useEffect,useRef } from "react";

import ListGroup from 'react-bootstrap/ListGroup';




export default function BandeContact({donnees}){
    const MC = {
        'P': 'Père',
        'M': 'Mère',
        'T': 'Tuteur/Tutrice',
        'GP': 'Grand-Père',
        'GM': 'Grand-Mère',
        'GF': 'Grand Frère',
        'GS': 'Grande Sœur',
        'RL': 'Responsable Légale',
        'A': 'Autre',
    }

    return(
        <ListGroup.Item>
          <span className="fw-bolder">{MC[donnees.relation]}</span> {donnees.prenom} {donnees.nom}<br/>
          <a href={`tel:${donnees.telephone}`}>{donnees.telephone}</a>
        </ListGroup.Item>
      )
  };