import React from "react";



import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';



export default function CartePro({donnees}){
    // const [donnees, setDonnees] = useState({});

    // const JETON = useSelector((state) => state.jeton.access)

    // const notif_gen = (message)=>toast.error(message,
    //     {position: "top-right",autoClose: 5000,hideProgressBar: false,
    //     closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    // })

    // useEffect(()=>{
    //   console.log(chemin)
    //   console.log(JETON)
    //     recuperer(chemin, JETON, 
    //         (rep)=>{console.log("setlesdonnees");console.log(rep);setDonnees(rep)},
    //         (e)=>{notif_gen(`Une erreur s'est produite avec le serveur.\n${e.message}`)}
    //         )       
    // },[])
    
    // useEffect(()=>{
    //       console.log("DONNNNNNEEES")
    //       console.log(donnees)
    //   },[donnees])
    

    return true ? (
        <Card className="bg-dark col-12 row mt-3">
          {Object.keys(donnees).length < 1 ? (
            <Card.Body>
              <ListGroup>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={9} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
              </ListGroup>
            </Card.Body>
          ) : (
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item className="bg-dark text-white">
                  {donnees.prenom} {donnees.nom}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark text-white">{donnees.telephone}</ListGroup.Item>
                <ListGroup.Item className="bg-dark text-white">{donnees.email}</ListGroup.Item>
                <ListGroup.Item className="bg-dark text-white">
                  {donnees.role.includes("gestionnaire")
                    ? "Gestionnaire"
                    : donnees.role.includes("responsable")
                    ? "Responsable"
                    : "Anim"}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark text-white">{donnees.structure.nom}</ListGroup.Item>
                {Object.keys(donnees).includes("camping") && donnees.camping.length > 0 ? (
                  donnees.camping.map((x) => (
                    <ListGroup.Item key={x.uuid} className="bg-dark text-white">
                      <a href={"/mettreEnLink-" + x.uuid}>{x.nom}</a>
                    </ListGroup.Item>
                  ))
                ) : false}
              </ListGroup>
            </Card.Body>
          )}
          {/* <ToastContainer className="text-norm notificationGrillee" /> */}
        </Card>
      ) : (
        false
      );
  };
