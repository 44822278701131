import React, {useState,useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'

import { useLoaderData } from "react-router-dom";
import { toast } from 'react-toastify';

// import CardTemplate from '../components/carteMenu'
// import Button from 'react-bootstrap/Button';
// // import { setJetonAccess } from '../store/jetonSlice'
// import ListeElements from "../components/listeElements";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import recuperer from '../jsTools/toolsFetch'
import FormTournoi from "../components/formulaires/formTournoi";
import FormScore from "../components/formulaires/formScore";
import CarteActiviteTable from "../components/utilisateur/carteActiviteTable"

export async function loader({ params }) {
  //const contact = await getContact(params.contactId);
  const id_camp = params.id_camp
  return {id_camp};
}

const ListeActivite = ({typeAct}) => {
  const { id_camp } = useLoaderData();
  const [donnees, setDonnees] = useState([])
  const [modalAcces, setModalAcces] = useState(false)

  const JETON = useSelector((state) => state.jeton.access)
  const USERUUID = useSelector((state) => state.statutUser.uuid)

  // const dispatch = useDispatch()

  
  const urls={
    "Tournois":`api/peluche/${id_camp}/tournois`,
    "Scores":`api/peluche/${id_camp}/scores`,
    // "Enfants":`api/peluche/${id_camp}/enfants`,
  }
  const notif_gen = (message)=>toast.error(message,
    {position: "top-right",autoClose: 5000,hideProgressBar: false,
    closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
  })
  useEffect(()=>{
    console.log(JETON)

    recuperer(urls[typeAct], JETON, 
        (rep)=>{console.log(rep);setDonnees(rep)},
        (e)=>{console.log(e);notif_gen(`Une erreur s'est produite avec le serveur.\n${e}`)}
        )       
  },[])

  return (
        <div className="p-2">
          <h2>{typeAct}</h2>
            <div>
              {/* {typeAct=="Tournois"} */}
              {
                typeAct=="Tournois"?
                <FormTournoi jeton={JETON} actionM="c" camping={id_camp} className="my-4" /> 
                :<FormScore jeton={JETON} actionM="c" camping={id_camp} className="my-4" /> 
              }
              {/* <Button onClick={()=>{console.log("couoooo");setModalAcces(modalAcces)}}>
                gert
              </Button> */}
            </div>
            <div>
              {
                donnees==null
                ? false
                :donnees.map(x=><CarteActiviteTable key={x.uuid} typeAct={typeAct} donnees={x} uuidCont={id_camp} uuidAnim={USERUUID} />)
              }
            </div>
        </div>
  );
};

export default ListeActivite;