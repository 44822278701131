import React from "react";
// import {
//     // Card,
//     // CardHeader,
//     // CardBody,
//     // CardFooter,
//     // Heading,
//     // Paragraph,
//     Button,
//   } from "grommet";
// import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";


import recuperer from '../../jsTools/toolsFetch'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCertificate } from '@fortawesome/free-solid-svg-icons'

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import CarteClub from "./carteClub";


export default function CarteCamping({donnees}){
    // const [donnees, setDonnees] = useState({});
    
    

    return true > 0 ? (
        <Card className="col-lg-4 col-sm-6 mb-3 col-xxl-3 text-white">
          {Object.keys(donnees).length < 1 ? (
            <Card.Body>
              <ListGroup>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={9} />
                </Placeholder>
              </ListGroup>
            </Card.Body>
          ) : (
            <Card.Body >
              <div className="row">
                <div className="col-6 fw-bolder text-uppercase text-ora fs-4">{donnees.nom}</div>
                {/* <small className="col-3">{donnees.uuid}</small> */}
                <DropdownButton id="dropdown-basic-button" className="col-4 fw-bold" variant="outline-ble fw-bold" title="Liste anims">
                  {Object.keys(donnees).includes("animateurs") && donnees.animateurs.length > 0 ? (
                      donnees.animateurs.map((x) => (
                        <Dropdown.Item key={x.uuid}>
                          {x.uuid==donnees.responsable.uuid?<FontAwesomeIcon icon={faCertificate} />:false}
                          {x.prenom} - {x.nom}
                        </Dropdown.Item>
                      ))
                    ) : false}
                </DropdownButton>
                {/* <ListGroup className="col-6">
                  {Object.keys(donnees).includes("animateurs") && donnees.animateurs.length > 0 ? (
                    donnees.animateurs.map((x) => (
                      <ListGroup.Item key={x.uuid}>
                        {x.prenom} - {x.nom}
                      </ListGroup.Item>
                    ))
                  ) : false}
                </ListGroup> */}
                <div className="col-12 border-2 fw-bold mt-3 p-3 border-bottom border-ble">
                  <span className="text-ble fw-bold fs-5">Activités</span><br/>
                  {/* <Link to={`/tournois/${donnees.uuid}`}>
                    <Button variant="ble fw-bold text-white" className="m-1" jusitfy="center">Tournois</Button> 
                  </Link> */}
                  {/* <Link to={`/scores/${donnees.uuid}`}> */}
                    {/* <Button variant="ble fw-bold text-white" className="m-1" jusitfy="center" >Scores</Button> */}
                    {/* <Button variant="dark" disabled={true} className="m-1" jusitfy="center">À venir, tenue de score</Button> */}
                  {/* </Link> */}
                  <Button variant="dark" disabled={true} className="m-1" jusitfy="center">À venir, gestion tournoi sportif</Button>
                  {/* <Button variant="light" disabled={true} className="m-1" jusitfy="center">À venir, tenir score rencontre</Button> */}
                  <Link to={`/scores`}>
                    <Button variant="ble fw-bold text-white" className="m-1" jusitfy="center" >Scores</Button>
                  </Link>
                  <Link to={`/mots`}>
                    <Button variant="ble fw-bold text-white" className="m-1" jusitfy="center" >Listes de mots</Button>
                  </Link>
                </div>
                <div className="col-12 mt-3">
                  {
                    donnees.clubs.map(x=>
                      <CarteClub 
                        key={x.uuid}
                        donnees={x}
                        uuidCamping={donnees.uuid}
                        // role={donneesU.role.includes("responsable")?"responsable":"anim"}
                        />)
                  }
                </div>
                
              </div>
            </Card.Body>
          )}
          {/* <ToastContainer className="text-norm notificationGrillee" /> */}
        </Card>
      ) : (
        false
      );
  };
