import React  from "react";
// import { useSelector } from 'react-redux';


// import "swiper/css";
// import "swiper/css/effect-creative";

import CarteProTableEnfantPresence from "./carteProTableEnfantPresence"


export default function ListeEnfantAppel({enfants, objPresences, etat, trait_arr}){
    // const [booModal, setBooModal] = useState(false);
    // const [alreadyLoad, setAlreadyLoad] = useState(false);
    // const [donneesEnfants, setDonneesEnfants] =useState([])
    // const [donneesEnfantsP, setDonneesEnfantsP] =useState([])
    // const [recEnfant, setRecEnfant] =useState("")
    // const [messageLoad, setMessageLoad] = useState("Chargement des données")
    // const [rencontres, setRencontres] = useState([]);
    


   
    
    


    const tinder = (goto, cat, u)=>{
      // "absent",cat, donnees.uuid
      // console.log(e)
      // let uuid_enf = e.el.children[0].children[e.realIndex].children[0].id.split("_")[2]
      // let realIndex = e.realIndex
      // console.log(`${goto} ${cat} ${u}`)
      if(goto=="absent") trait_arr("popPresence", u)
      else if (goto=="parti") trait_arr("addSortie",u)
      else if (goto=="present"){
        if(cat=="absent") trait_arr("addPresence", u)
        else if (cat=="parti") trait_arr("popSortie", u)
      }
      else console.warn("listenfantappel fct tinder(), dans le vide")
    }
 

    return (
      <div > 
        {
          enfants.map((x,i)=>{return(
            <CarteProTableEnfantPresence className="cptep" cat={etat} fctAction={tinder} key={`${x.uuid}_${etat}`} donnees={x} uuidCont={objPresences.club} /> 
          )})
        }
      </div>
      
    );
  };

