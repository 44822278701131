import React from "react";

import { Link } from "react-router-dom";

import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

import ListeEnfant from "../club/listeEnfant";
import LAppel from "../club/lappel";
import QrCodeInscription from "../club/qrCodeInscription";


export default function CarteClub({donnees, uuidCamping}){
    // const [donnees, setDonnees] = useState({});
    
    

    return (
        <Card className="bg-ble mb-2">
          {Object.keys(donnees).length < 1 ? (
            <Card.Body>
              <ListGroup>
                <Placeholder as={ListGroup.Item} animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
              </ListGroup>
            </Card.Body>
          ) : (
            <Card.Body className="row justify-content-center pb-0 pt-2">
              <p className="col-12 mb-1 fw-bold">
                {donnees.nom}
              </p>
              <ListeEnfant donneesClub={donnees} uuidCamping={uuidCamping} />
              <QrCodeInscription donneesClub={donnees} uuidCamping={uuidCamping}/>
              <div className="col-12 p-0 pb-2">
                <Link className="" to={`/appel/${uuidCamping}/${donnees.uuid}/liste?c=${donnees.nom}`} >
                  <Button className='m-2' size="sm" variant="ora text-white fw-bold" >
                    Feuilles de présences
                  </Button>
                </Link>
              </div>
              {/* <LAppel donneesClub={donnees} uuidCamping={uuidCamping} /> */}
              {/* <Button variant="outline-dark">Appel</Button> */}
            </Card.Body>
          )}
          {/* <ToastContainer className="text-norm notificationGrillee" /> */}
        </Card>
      ) 
  };
