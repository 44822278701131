// import { Header } from "grommet";
import React, {useEffect,useState}  from "react";
// import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Toast from 'react-bootstrap/Toast';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { DateRange, Calendar } from 'react-date-range';
import {fr, enGB} from 'react-date-range/dist/locale/index'

import { toast } from 'react-toastify';
import { Vortex } from  'react-loader-spinner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera,faDoorOpen,faFileSignature,faCircleCheck, faComment, faHandDots, faStethoscope, faBandage } from '@fortawesome/free-solid-svg-icons'

// import { setJetonAccess, setJetonRefresh,clearJetons } from '../../store/jetonSlice'
// import { setIsAnim, setIsResp, setIsGest } from '../../store/statutUserSlice'
import capitalise from '../../jsTools/toolsString'
// import recuperer from '../../jsTools/toolsFetch'
import recuperer from "../../jsTools/toolsFetch";
import FormContact from "./formContact"
import libelleTrad from "../trad/trad_formulaireEnfant.json"

// action = m || d || c (modify, delete, create)
export default function FormFicheInfo({donneesPrim, campuuid, clubuuid, gererForm=false, donneesPrev=false,trad="fr", successCallback}){
    // const [modalForm, setModalForm] = useState(false);
    const [cleftab, setClefTab] = useState()
    const [tabBloque, setTabBloque] = useState(0)
    const [etatVue, setEtatVue] = useState("F") // ["F","A","S"]
    // const [form_prenom, setFormPrenom] = useState("");
    // const [form_nom, setFormNom] = useState("");
    const [formDonnees, setFormDonnees] = useState(donneesPrev?donneesPrev:{
        "nom": "",
        "prenom": "",
        "ddn": null,
        "sejour_debut": null,
        "sejour_fin": null,
        "emplacement": "",
        "acceptePhoto": false,
        "sortie": "A",
        "allergie":false,
        "commentaireAllergie": "",
        "traitementMedical": false,
        "commentaireMedical": "",
        "autorisationInterMed": false,
        "autorisationRepresentantLegal": false,
        "commentaire": "",
        "representantLegal": null,
        "club": null,
        "autresContacts": []
    })
    const [rangeSejour, setRangeSejour] = useState([
        {
          startDate: new Date(),
          endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
          key: 'selection'
        }
      ])
    const [sejourModal, setSejourModal] = useState(false)
    const [dateSejourVal, setDateSejourVal] = useState(false)
    const [labelRangeSejour, setLabelRangeSejour] = useState("")

    const [ddn, setDdn] = useState(null)
    const [ddnModal, setDdnModal] = useState(false)
    const [ddnVal, setDdnVal] = useState(false)
    const [labelDdn, setLabelDdn] = useState("")
    
    const [repModal, setRepModal] = useState(false)
    const [repVal, setRepVal] = useState(false)
    const [labelRep, setLabelRep] = useState("")
    
    const [contactModal, setContactModal] = useState(false)
    const [ado, setAdo] = useState(false)
    // const [repVal, setRepVal] = useState(false)
    const [labelContact, setLabelContact] = useState("")

    const noire = false;
    const nomclasse = noire ? "bg-dark text-white" : ""

    const valeurFormDonnees = (clef, valeur)=>{
    setFormDonnees((prevValues) => ({
            ...prevValues,
            [clef]: valeur,
          }));
    }
    const addArrayFormDonnees = (clef, valeur)=>{
        console.log(clef)
        console.log(formDonnees)
        let tempd = [...formDonnees[clef]]
        tempd.push(valeur)
        setFormDonnees((prevValues) => ({
                ...prevValues,
                [clef]: tempd,
              }));
        }
    const popArrayFormDonnees = (clef, ind)=>{
        let tempd = [...formDonnees[clef]]
        tempd.splice(ind,1)
        setFormDonnees((prevValues) => ({
                ...prevValues,
                [clef]: tempd,
              }));
        }
    const validerDatesSejour = ()=>{
        try{
            // console.log(rangeSejour)
            // console.log(typeof(rangeSejour[0].startDate))
            // console.log(rangeSejour[0].startDate)
            let sdeb = rangeSejour[0].startDate.toLocaleDateString("fr-FR");
            let sfin = rangeSejour[0].endDate.toLocaleDateString("fr-FR");
            setLabelRangeSejour(`${sdeb} ⇒ ${sfin}`)
            // let datedeb = new Date(rangeSejour[0].startDate);
            // let datefin = new Date(rangeSejour[0].startDate);
            setDateSejourVal(true)
            valeurFormDonnees("sejour_debut",sdeb.split("/").reverse().join("-"))
            valeurFormDonnees("sejour_fin",sfin.split("/").reverse().join("-"))
            setSejourModal(false)
        }catch(e){
            setDateSejourVal(false)
            setSejourModal(false)
            console.log(e)
        }        
    }
    const validerDdn = ()=>{
        try{
            // let sddn = ddn.startDate.toLocaleDateString("fr-FR");
            // let sfin = rangeSejour[0].endDate.toLocaleDateString("fr-FR");
            let sddn = ddn.toLocaleDateString("fr-FR")
            setLabelDdn(sddn)
            // let datedeb = new Date(rangeSejour[0].startDate);
            // let datefin = new Date(rangeSejour[0].startDate);
            setDdnVal(true)
            valeurFormDonnees("ddn",sddn.split("/").reverse().join("-"))
            // valeurFormDonnees("sejour_fin",sfin.split("/").reverse().join("-"))
            setDdnModal(false)
        }catch(e){
            setDdnVal(false)
            setDdnModal(false)
            console.log(e)
        }        
    }


    const confirmerRepLeg = (dt)=>{
        console.log(dt);
        valeurFormDonnees("representantLegal",dt);
        setLabelRep(`${capitalise(String(dt.prenom))} ${capitalise(String(dt.nom))}`);
        setRepVal(true);
        setRepModal(false)
    }

    const validationEtape = (etape)=>{
        if (etape==0){
            let dateauj = new Date()
            console.log(dateauj)
            console.log(formDonnees)
            if(formDonnees.nom.trim()=="")notif_gen(libelleTrad["notif_nom"][trad])
            else if(formDonnees.prenom.trim()=="")notif_gen(libelleTrad["notif_prenom"][trad])
            else if(["",null,undefined].includes(formDonnees.ddn) || ddn>=dateauj)notif_gen(libelleTrad["notif_ddn"][trad])
            else if(["",null,undefined].includes(formDonnees.sejour_debut) || ["",null,undefined].includes(formDonnees.sejour_fin))notif_gen(libelleTrad["notif_dds"][trad])
            else if(formDonnees.emplacement.trim()=="")notif_gen(libelleTrad["notif_emplacement"][trad])
            else {
                setTabBloque(tabBloque>etape ?tabBloque: etape+1)
                setClefTab(etape+1)
            }
        }
        else if (etape==1){
            if(formDonnees.representantLegal==null)notif_gen(libelleTrad["notif_repleg"][trad])
            else {
                setTabBloque(tabBloque>etape ?tabBloque: etape+1)
                setClefTab(etape+1)
            }
        }
        else if (etape==2){
            if(formDonnees.allergie && formDonnees.commentaireAllergie.trim()=="")notif_gen(libelleTrad["notif_allergie"][trad])
            else if(formDonnees.traitementMedical && formDonnees.commentaireMedical.trim()=="")notif_gen(libelleTrad["notif_med"][trad])
            else {
                setTabBloque(tabBloque>etape ?tabBloque: etape+1)
                setClefTab(etape+1)
            }
        }
        else if (etape==3){
            if (!formDonnees.autorisationRepresentantLegal)notif_gen(libelleTrad["notif_leg"][trad])
            else handleForm()
            // else handleForm()
            
        }
    }
    // const formData = 
    // const [form_prenom, setFormPrenom] = useState("");
    // const [f_prenom, setFPrenom] = useState(element["prenom"]);
    // const [f_tel, setFTel] = useState(element["telephone"]);
    // const [f_email, setFEmail] = useState(element["email"]);
    // const [f_nom, setFNom] = useState("");
    // const [f_prenom, setFPrenom] = useState("");
    // const [f_tel, setFTel] = useState("");
    // const [f_email, setFEmail] = useState("");

    useEffect(()=>{
        if(gererForm || donneesPrev.nom.length!=0){
            //pas fiche info mais form de l'anim
            //ou donneesPrev fill
            setRangeSejour([{
                startDate: new Date(donneesPrev.sejour_debut),
                endDate: new Date(donneesPrev.sejour_fin),
                key: 'selection'
            }])
            confirmerRepLeg(donneesPrev.representantLegal)
            validerDatesSejour()
        }
        let temp = donneesPrim.data.camping.clubs.filter(x=>x.uuid==clubuuid)
        setAdo(temp[0].ado)
    }, [])

    // const JETON = useSelector((state) => state.jeton.access)
    // const STATUTUSER = useSelector((state) => state.statutUser.access)
    // const dispatch = useDispatch()
    // const act = (m,d,c)=>{
    //     // console.log(`actionM ${actionM}`)
    //     // console.log(`structure ${structure}`)
    //     // console.log(`jeton ${jeton}`)
    //     // console.log(actionM==="m")
    //     if(actionM=="m")return m
    //     else if (actionM=="d") return d
    //     else return c
    // }
    const notif_gen = (message, temps=5000)=>toast.error(message,
        {position: "top-right",autoClose: temps,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })
    const notif_norm = (message)=>{
        toast(message,
        {position: "top-right",autoClose: 2000,hideProgressBar: false,
        closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",
    })}
    
    // const formatageForm = (obj)=>{
    //     obj
    // }
    const handleForm = ()=>{
        var corps = {...formDonnees}
        if(ado)corps["sortie"]= "S"

        corps["club"] = clubuuid
        if (gererForm){
            gererForm(corps)
        }
        else{
            // corps = formatageForm(corps)
            setEtatVue("A")
            recuperer(
                `api/pipou/${campuuid}/${clubuuid}`,
                "",
                (rep)=>{
                    console.log(rep)
                    if(rep.statut=="succes"){
                        setEtatVue("S")
                        notif_norm(rep.message)
                        
                    }
                    else{
                        setEtatVue("F")
                        notif_gen(libelleTrad["Erreur"][trad], false)
                        notif_gen(JSON.stringify(rep), false)   
                    }
                },
                (e)=>{
                    console.log(e)
                    setEtatVue("F")
                    notif_gen(e.message, false)
                    notif_gen(libelleTrad["notif_probleme"][trad], false)
                    
                },
                "POST",
                corps
            )
        }
        
    }
    
    // const size = useContext(ResponsiveContext);
    return (
        <div>
      {etatVue=="F"?<form>
            <Tabs
                id="tabs-fiche"
                activeKey={cleftab}
                onSelect={(k) => setClefTab(k)}
                className="mb-3"
                justify={true}
                // variant="pills"
            >
                <Tab eventKey={0} title={libelleTrad["Enfant"][trad]}>
                    <div className="row d-flex align-items-end">
                        <div className="col-10 m-1">
                            <FloatingLabel className="" label={libelleTrad["prenom"][trad]}>
                                <Form.Control
                                    className={`${nomclasse} text-capitalize`}
                                    type="text"
                                    id="ffi_prenomEnfant"
                                    placeholder=""
                                    defaultValue={formDonnees.prenom}
                                    onChange = {(event)=>{valeurFormDonnees("prenom",event.target.value)}}
                                    aria-describedby="ffi_prenomEnfant_aide"
                                />
                            </FloatingLabel>
                        </div>
                        <div className="col-10 m-1">
                            <FloatingLabel className="" label={libelleTrad["nom"][trad]}>
                                <Form.Control
                                    className={`${nomclasse} text-capitalize`}
                                    type="text"
                                    id="ffi_nomEnfant"
                                    placeholder=""
                                    defaultValue={formDonnees.nom}
                                    onChange = {(event)=>{valeurFormDonnees("nom",event.target.value)}}
                                />
                            </FloatingLabel>
                        </div>
                        <div className="col-10 m-1">
                            <FloatingLabel className="" label={libelleTrad["emplacement"][trad]}>
                                <Form.Control
                                    className={`${nomclasse} text-capitalize`}
                                    type="text"
                                    id="ffi_emplacement"
                                    placeholder=""
                                    defaultValue={formDonnees.emplacement}
                                    onChange = {(event)=>{valeurFormDonnees("emplacement",event.target.value)}}
                                />
                            </FloatingLabel>
                        </div>
                        <div className="col-10 m-2 ">
                            <div id="ffi_ddn_aide" className="text-sm m-1">
                                {libelleTrad["ddn"][trad]}
                            </div>
                            <Button className="p-2" onClick={()=>setDdnModal(!ddnModal)} variant={ddnVal?"ble text-white":"outline-ble"}>
                                {ddnVal?labelDdn:libelleTrad["jjmmaaaa"][trad]}
                            </Button>
                                <Modal show={ddnModal} onHide={()=>{setDdnVal(false);setDdnModal(false)}}>
                                    <Modal.Body>
                                        <Calendar 
                                            onChange={item => setDdn(item)}
                                            className="wmax100"
                                            locale={trad=="fr"?fr:enGB}
                                            maxDate={new Date()} 
                                            date={ddn} />                                        
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="dark" onClick={()=>setDdnModal(false)}>
                                        {libelleTrad["fermer"][trad]}
                                    </Button>
                                    <Button variant="outline-dark" onClick={()=>validerDdn()}>
                                        {libelleTrad["valider ddn"][trad]}
                                    </Button>
                                    </Modal.Footer>
                                </Modal>
                        </div>
                        <div className="col-10 m-2">
                            <div id="ffi_dds_aide" className="text-sm m-1">
                                {libelleTrad["dds"][trad]}
                            </div>
                            <Button className="p-2" onClick={()=>setSejourModal(!sejourModal)} variant={dateSejourVal?"ble text-white":"outline-ble"}>
                                {dateSejourVal?labelRangeSejour:libelleTrad["dds"][trad]}
                            </Button>
                                <Modal show={sejourModal} onHide={()=>{setDateSejourVal(false);setSejourModal(false)}}>
                                    <Modal.Body>
                                            <DateRange
                                                className="wmax100"
                                                editableDateInputs={true}
                                                locale={trad=="fr"?fr:enGB}
                                                // weekdayDisplayFormat="EEEEE"
                                                onChange={item => setRangeSejour([item.selection])}
                                                moveRangeOnFirstSelection={false}
                                                ranges={rangeSejour}
                                                dateDisplayFormat="dd MMM yyyy"
                                                rangeColors={["#212529"]}
                                            />
                                        
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="dark" onClick={()=>setSejourModal(false)}>
                                        {libelleTrad["fermer"][trad]}
                                    </Button>
                                    <Button variant="outline-dark" onClick={()=>validerDatesSejour()}>
                                        {libelleTrad["valider dds"][trad]}
                                    </Button>
                                    </Modal.Footer>
                                </Modal>
                        </div>
                    </div>
                    <Button className="offset-8 col-4" variant="ora text-white" onClick={()=>validationEtape(0)}>
                        {libelleTrad["suivant"][trad]}
                    </Button>
                </Tab>
                <Tab eventKey={1} title={libelleTrad["Contacts"][trad]} disabled={1>tabBloque} >
                    <div className="row d-flex align-items-end">
                        <div className="col-10 m-2 ">
                            <div id="ffi_dds_aide" className="text-sm m-1">
                                {libelleTrad["rep leg"][trad]}
                            </div>
                            
                            <Button className="p-2" onClick={()=>setRepModal(!repModal)} variant={repVal?"ble text-white fw-bold":"outline-ble"}>
                                {repVal?labelRep:libelleTrad["rep leg"][trad]}
                            </Button>
                                <Modal show={repModal} onHide={()=>{setRepVal(false);setRepModal(false)}}>
                                    <Modal.Body>
                                        <FormContact 
                                            titre={libelleTrad["rep leg"][trad]}
                                            principal={true} 
                                            emplacement={formDonnees.emplacement}
                                            confirmValues={confirmerRepLeg}
                                            trad={trad}
                                            />
                                    </Modal.Body>
                                </Modal>
                        </div>
                        <div className="col-10 m-2">
                            <div className="text-sm m-1">
                                {libelleTrad["autres contacts"][trad]}
                            </div>
                            <Button className="p-2" onClick={()=>setContactModal(!contactModal)} variant={formDonnees.autresContacts.length>0?"ble text-white":"outline-ble"}>
                                {libelleTrad["add autres contacts"][trad]}
                            </Button>
                                <Modal show={contactModal} onHide={()=>{setContactModal(false)}}>
                                    <Modal.Body>
                                        <FormContact 
                                            titre={libelleTrad["contact supplementaire"][trad]}
                                            principal={false} 
                                            emplacement={formDonnees.emplacement}
                                            gommer={true}
                                            trad={trad}
                                            confirmValues={
                                                (dt)=>{
                                                    addArrayFormDonnees("autresContacts",dt)
                                                    setContactModal(false)   
                                                    }
                                                }
                                            />
                                    </Modal.Body>
                                </Modal>
                                <div className="row">
                                {formDonnees.autresContacts.map((x,i)=><Toast
                                    className="m-1"
                                    bg="ble"
                                    onClose={()=>popArrayFormDonnees("autresContacts",i)}
                                    key={i}
                                    >
                                        <Toast.Header>
                                            <strong className="me-auto">
                                                {x.prenom} {x.nom}
                                            </strong>
                                        </Toast.Header>
                                    </Toast>
                                    )}
                                </div>
                                
                        </div>
                    </div>
                    <Button className="offset-8 col-4" variant="ora text-white" onClick={()=>validationEtape(1)}>
                        {libelleTrad["suivant"][trad]}
                    </Button>
                </Tab>
                <Tab eventKey={2} title={libelleTrad["Santé"][trad]} disabled={2>tabBloque} >
                    <div className="row">
                        <FontAwesomeIcon className="col-2 my-auto px-0" icon={faHandDots} />
                        <div className="col-9 px-2 border p-3 mx-1 mt-3 mb-1">
                            <Form.Check // prettier-ignore
                                // className="text-sm"
                                type="checkbox"
                                id="ffi_allergie"
                                onChange = {(event)=>{valeurFormDonnees("allergie",event.target.checked)}}
                                defaultChecked={formDonnees.allergie}
                                label={libelleTrad["label_allergie"][trad]}
                            />
                        </div>
                        {
                            formDonnees.allergie
                            ?<div className="col-12 px-3 mt-1 ">
                                <FloatingLabel className="text-2" label={libelleTrad["preciser_allergie"][trad]}>
                                    <Form.Control
                                        as="textarea"
                                        className={`${nomclasse}`}
                                        placeholder=""
                                        id="ffi_allergiecom"
                                        onChange = {(event)=>{valeurFormDonnees("commentaireAllergie",event.target.value)}}
                                        defaultValue={formDonnees.commentaireAllergie}
                                        style={{ height: '200px' }}
                                    />
                                </FloatingLabel>
                            </div>
                            :false
                        }
                        <FontAwesomeIcon className="col-2 my-auto px-0" icon={faStethoscope} />
                        <div className="col-9 px-2 border p-3 mx-1 mt-3 mb-1">
                            <Form.Check // prettier-ignore
                                className=""
                                type="checkbox"
                                id="ffi_trtmed"
                                onChange = {(event)=>{valeurFormDonnees("traitementMedical",event.target.checked)}}
                                defaultChecked={formDonnees.traitementMedical}
                                label={libelleTrad["label_med"][trad]}
                            />
                        </div>
                        {/* {formDonnees.traitementMedical} */}
                        {
                            formDonnees.traitementMedical
                            ?<div className="col-12 px-3 mt-1 ">
                                <FloatingLabel className="text-sm" label={libelleTrad["preciser_med"][trad]}>
                                    <Form.Control
                                    as="textarea"
                                    className={`${nomclasse} text-sm`}
                                    placeholder="(diabetique, asthme, ...)"
                                    id="ffi_commed"
                                    onChange = {(event)=>{valeurFormDonnees("commentaireMedical",event.target.value)}}
                                    defaultValue={formDonnees.commentaireMedical}
                                    style={{ height: '200px' }}
                                    />
                                </FloatingLabel>
                            </div>
                            :false
                        }
                        <FontAwesomeIcon className="col-2 my-auto px-0" icon={faBandage} />
                        <div className="col-9 px-2 border p-3 mx-1 mt-3 mb-1">
                            <Form.Check // prettier-ignore
                                className=""
                                type="checkbox"
                                id="ffi_intermed"
                                onChange = {(event)=>{valeurFormDonnees("autorisationInterMed",event.target.checked)}}
                                defaultValue={formDonnees.autorisationInterMed}
                                label={libelleTrad["inter_med"][trad]}
                            />
                        </div>
                        <p className="text-muted mt-4">
                            {libelleTrad["legal_vaccin"][trad]}
                        </p>
                    </div>
                    <Button className="offset-8 col-4 my-4" variant="ora text-white" onClick={()=>validationEtape(2)}>
                        {libelleTrad["suivant"][trad]}
                    </Button>
                </Tab>
                <Tab eventKey={3} title={libelleTrad["Légal"][trad]} disabled={3>tabBloque} >
                    <div className="row">
                        <div className="col-12 row">
                            <FontAwesomeIcon className="col-2 my-auto px-1" icon={faCamera} />
                            <div className="col-9 border py-3 px-2 my-3">
                                <Form.Check // prettier-ignore
                                    className=""
                                    type="checkbox"
                                    id="ffi_photo"
                                    onChange = {(event)=>{valeurFormDonnees("acceptePhoto",event.target.checked)}}
                                    defaultValue={formDonnees.acceptePhoto}
                                    // label={donneesPrim?.data.structure.description_legale_droit_image??"Droit diffusion photo"}
                                    label={libelleTrad["legal_droit_image"][trad]}
                                />
                            </div>
                        </div>  
                        {
                            ado ? false :
                            <div className="col-12 row">
                                <FontAwesomeIcon className="col-2 my-auto px-1" icon={faDoorOpen} />
                                <FloatingLabel className="text-2 col-9 px-1" label={gererForm?"Sortie":libelleTrad["quest_sortie"][trad]}>
                                    <Form.Select 
                                        className={`${nomclasse}`}
                                        id="ffi_sortie"
                                        onChange={(event)=>{console.log(event);console.log(event.target.value);valeurFormDonnees("sortie",event.target.value)}}
                                        defaultValue={formDonnees.sortie}
                                        aria-label={gererForm?"Sort seul(e)":libelleTrad["quest_sortie"][trad]}
                                        >
                                        {/* <option>Open this select menu</option> */}
                                        <option value="A">{libelleTrad["accompagne"][trad]}</option>
                                        <option value="S">{libelleTrad["seul"][trad]}</option>
                                        {/* <option value="3">Three</option> */}
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                        }                      
                        
                        {gererForm?false:<div className="col-12 row">
                            <FontAwesomeIcon className="col-2 my-auto px-1" icon={faFileSignature} />
                            <div className="col-9 border py-3 px-2 my-3">
                                <Form.Check // prettier-ignore
                                    className=""
                                    type="checkbox"
                                    id="ffi_autorisation"
                                    onChange = {(event)=>{valeurFormDonnees("autorisationRepresentantLegal",event.target.checked)}}
                                    defaultValue={formDonnees.autorisationRepresentantLegal}
                                    label={`${libelleTrad["legal_sign_JE"][trad]} ${labelRep} ${libelleTrad["legal_sign_autorise"][trad]} ${formDonnees.prenom} ${formDonnees.nom}${libelleTrad["legal_sign_participe"][trad]}`}
                                />
                            </div></div>}
                        <div className="col-12 row">
                            {/* <div id="ffi_photo_aide" className="text-sm">
                                Représentant(e) légal(e) [Vous]
                            </div> */}
                            <FontAwesomeIcon icon={faComment} className="col-2 my-auto px-1" />
                            <FloatingLabel className="col-9 px-1" label={libelleTrad["commentaire"][trad]}>
                                <Form.Control
                                as="textarea"
                                className={`${nomclasse}`}
                                placeholder=""
                                id="ffi_commentaire"
                                onChange = {(event)=>{valeurFormDonnees("commentaire",event.target.value)}}
                                defaultValue={formDonnees.commentaire}
                                style={{ height: '200px' }}
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    <Button className="offset-4 col-8 mt-4" variant="ora text-white" onClick={()=>validationEtape(3)}>
                        {libelleTrad["valider_info"][trad]}
                    </Button>
                </Tab>
            </Tabs>
            
            
            {/* <button onClick={()=>{console.log({formDonnees})}}>Marco</button>
            {JSON.stringify(formDonnees)} */}
      </form>:false}
      {etatVue=="A"?
      <div>
        <p>{libelleTrad["enregistrement"][trad]}</p>
        <Vortex
            visible={true}
            height="80"
            width="80"
            ariaLabel="vortex-loading"
            wrapperStyle={{}}
            wrapperClass="vortex-wrapper"
            colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
        />
        </div>
      :false}
      {etatVue=="S"?
      <div className="row">
        <FontAwesomeIcon className="col-4 text-ora h-50"  icon={faCircleCheck} />
        <h3 className="col-6 my-auto"> {libelleTrad["inscription reussie"][trad]} {formDonnees.prenom} {formDonnees.nom}</h3>

        <div className="col-12 row border-4 pt-4 border-ora">
            {
                donneesPrim.data.camping.clubs.map(x=>{
                    let corps = {...formDonnees}
                    corps["prenom"]=""
                    corps["ddn"]=null
                    corps["autorisationRepresentantLegal"]=false

                    let b = btoa(JSON.stringify(corps))
                    return <a href={`/fiche/${campuuid}/${x.uuid}?dd=${b}`} key={`lb_${x.uuid}`} className="col-12 m-2">
                                <Button variant="ora text-white">
                                    {libelleTrad["inscrire_autre"][trad]} {x.nom}
                                </Button>
                            </a>
                })
            }
        </div>
        <p className="offset-1 col-10 mt-5">
            <small>{libelleTrad["legal_donnees"][trad]}</small>
        </p>
        </div>
      :false}
      </div>
    );
  };

